import ReactFlow, { MarkerType, Position } from "reactflow";
import "reactflow/dist/style.css";
import "./RuleEngine.css";
import {
  EndTableNode,
  FooterTextNode,
  HeaderTextNode,
  MultipleOffersTableNode,
  StartTextNode,
  TableNode,
  TextNode,
  WidgetNode,
} from "./RuleNodes";
import {
  Edges,
  FieldType,
  Nodes,
  NodeType,
  Node,
  Edge,
} from "../models/GlobalPricingRuleEngineData";
import { useEffect, useMemo, useState } from "react";
import { Rule } from "./GlobalPricingSettingsScreen";
import {
  addPercentAtEnd,
} from "../../../../../lib/utils";
import { MultiSelectOption } from "./MultiSelectDropdown";

// Register the custom node types
const nodeTypes = {
  [NodeType.START]: StartTextNode,
  [NodeType.TABLE]: TableNode,
  [NodeType.TEXT]: TextNode,
  [NodeType.HEADER]: HeaderTextNode,
  [NodeType.FOOTER]: FooterTextNode,
  [NodeType.LAST_TABLE]: EndTableNode,
  [NodeType.WIDGET]: WidgetNode,
  [NodeType.MULTIPLE_OFFER_TABLE]: MultipleOffersTableNode,
};

function generateHeader(): Node[] {
  return [
    {
      id: "1",
      type: NodeType.HEADER,
      data: {
        label: "Asset Classes",
      },
      position: { x: 110, y: -150 },
      targetPosition: Position.Left,
    },
    {
      id: "2",
      type: NodeType.HEADER,
      data: {
        label: "LTV Threshold",
      },
      position: { x: 300, y: -150 },
      targetPosition: Position.Left,
    },
    {
      id: "3",
      type: NodeType.HEADER,
      data: {
        label: "MAOB",
      },
      position: { x: 550, y: -150 },
      targetPosition: Position.Left,
    },
    {
      id: "4",
      type: NodeType.HEADER,
      data: {
        label: "Offers to Sellers",
      },
      position: { x: 825, y: -150 },
      targetPosition: Position.Left,
    },
    {
      id: "5",
      type: NodeType.HEADER,
      data: {
        label: "MAOS",
      },
      position: { x: 1120, y: -150 },
      targetPosition: Position.Left,
    },
    // {
    //   id: "6",
    //   type: NodeType.HEADER,
    //   data: {
    //     label: "Validate",
    //   },
    //   position: { x: 1345, y: -150 },
    //   targetPosition: Position.Left,
    // },
  ];
}

function generateFooter(header: Node[], length: number): Node[] {
  return header.map((headerNode) => ({
    id: (parseInt(headerNode.id) + header.length).toString(),
    type: NodeType.FOOTER,
    data: {
      label: headerNode.data.label,
    },
    position: {
      x: headerNode.position.x,
      y: 100 + 500 * length, // Change the Y position for footer
    },
    targetPosition: Position.Left,
  }));
}

// Function to generate header-footer edges
const generateHeaderFooterEdges = (
  headers: Node[],
  footers: Node[]
): Edge[] => {
  return headers.map((header, index) => ({
    id: `${header.id}-${footers[index].id}`,
    source: header.id,
    target: footers[index].id,
    style: {
      stroke: 'lightgray',
      strokeWidth: 2,
      strokeDasharray: '5 5',
    },
    // markerEnd: { type: undefined }, 
  }));
};

const generateRuleEdges = (rule: Rule): any[] => {
  const newRuleEdges = [
    {
      id: `e1-2-${rule.id}`,
      source: `1-${rule.id}`,
      target: `2-${rule.id}`,
      markerEnd: { type: MarkerType.ArrowClosed },
    },
    {
      id: `e2-3-${rule.id}`,
      source: `2-${rule.id}`,
      target: `3-${rule.id}`,
      label: `LTV < ${rule.data.ltvThreshold}`,
      markerEnd: { type: MarkerType.ArrowClosed },
    },
    {
      id: `e2-4-${rule.id}`,
      source: `2-${rule.id}`,
      target: `4-${rule.id}`,
      label: `LTV >= ${rule.data.ltvThreshold}`,
      markerEnd: { type: MarkerType.ArrowClosed },
    },
    {
      id: `e3-5-${rule.id}`,
      source: `3-${rule.id}`,
      target: `5-${rule.id}`,
      markerEnd: { type: MarkerType.ArrowClosed },
    },
    {
      id: `e4-5-${rule.id}`,
      source: `4-${rule.id}`,
      target: `5-${rule.id}`,
      markerEnd: { type: MarkerType.ArrowClosed },
    },
    {
      id: `e5-6-${rule.id}`,
      source: `5-${rule.id}`,
      target: `6-${rule.id}`,
      markerEnd: { type: MarkerType.ArrowClosed },
    },
    {
      id: `e6-7-${rule.id}`,
      source: `6-${rule.id}`,
      target: `7-${rule.id}`,
      markerEnd: { type: MarkerType.ArrowClosed },
    },
    {
      id: `e7-8-${rule.id}`,
      source: `7-${rule.id}`,
      target: `8-${rule.id}`,
      markerEnd: { type: MarkerType.ArrowClosed },
    },
  ];
  return newRuleEdges;
};




interface RuleEngineProps {
  rules: Rule[];
  inPlaceEditing: (id: string, field: FieldType, newValue: any) => void;
  handleDelete: (id: string) => void;
  handleCopy: (id: string) => void;
  handleEdit: (id: string) => void;
  handlePlay: (id: string) => void;
  // inPlaceInputEditing: (id: string, newValue: any) => void;
}

const RuleEngine: React.FC<RuleEngineProps> = ({
  rules,
  inPlaceEditing,
  handleDelete,
  handleCopy,
  handleEdit,
  handlePlay,
  // inPlaceInputEditing
}) => {
  const [tempRule, setTempRule] = useState<Rule[]>(rules);

  // Memoize headers and footers to avoid redundant recalculations
  const headers = useMemo(() => generateHeader(), []);
  const footers = useMemo(
    () => generateFooter(headers, rules.length),
    [headers, rules.length]
  );

  const [nodes, setNodes] = useState<Nodes>({
    header: headers,
    footer: footers,
    ruleNode: [],
  });

  const [edges, setEdges] = useState<Edges>({
    headerFooter: generateHeaderFooterEdges(headers, footers),
    ruleNode: [],
  });

  const options: MultiSelectOption[] = [
    { label: "Residential", value: "Residential" },
    { label: "Multi-family", value: "Multi-family" },
    { label: "Commercial", value: "Commercial" },
    { label: "Industrial", value: "Industrial" },
  ];
  //setting tempRule
  useEffect(() => {
    setTempRule(rules);
    const emptyEdge = {
      ...edges,
      ruleNode: [],
    };
    setEdges(emptyEdge);
    const emptyNode = {
      ...nodes,
      ruleNode: [],
    };
    setNodes(emptyNode);
  }, [rules]);

  //calling functions to make edges and nodes
  useEffect(() => {
    const newNodes: Nodes["ruleNode"] = [];
    const newEdges: Edges["ruleNode"] = [];
    tempRule.forEach((rule, index) => {
      const newNodesFromUpdateNodes = generateRuleNodes(rule, index);
      const tempNewEdges = generateRuleEdges(rule);
      // if (rule.input) {
      //   const tempInputNodes = generateInputNode(rule, index);
      //   newNodes.push(tempInputNodes);
      // }

      if (Array.isArray(newNodesFromUpdateNodes)) {
        newNodes.push(...newNodesFromUpdateNodes);
      }

      if (Array.isArray(tempNewEdges)) {
        newEdges.push(...tempNewEdges);
      }
    });

    setNodes({
      ...nodes,
      footer: UpdateFooterNodes(),
      ruleNode: newNodes,
    });
    setEdges({
      ...edges,
      ruleNode: newEdges,
    });
  }, [tempRule]);

  //generating the basic rule nodes
  const generateRuleNodes = (rule: Rule, index: number): any[] => {
    const newRuleNodes = [
      {
        id: `1-${rule.id}`,
        type: NodeType.START,
        data: {
          label: rule.data.assetClass,
          onValueChange: (newValue: any) => {
            inPlaceEditing(rule.id, FieldType.ASSET_CLASS, newValue);
          },
          options: options,
        },
        position: { x: 70, y: 156.5 + 500 * index },
        sourcePosition: Position.Right,
      },
      {
        id: `2-${rule.id}`,
        type: NodeType.TEXT,
        data: {
          label: "LTV Threshold",
          value: rule.data.ltvThreshold,
          onValueChange: (newValue: any) => {
            inPlaceEditing(rule.id, FieldType.LTV_THRESHOLD, newValue);
          },
        },
        position: { x: 300, y: 192 + 500 * index },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: `3-${rule.id}`,
        type: NodeType.TABLE, // Custom table node
        data: {
          heading: "Low Risk Properties",
          subheader: true,
          tableData: [
            {
              label: "AAO",
              key: "AAO",
              value: rule.data.lowRiskPropertyTable.tableData.AAO,
              formatter: addPercentAtEnd,
            },
            {
              label: "AIV",
              key: "AIV",
              value: rule.data.lowRiskPropertyTable.tableData.AIV,
              formatter: addPercentAtEnd,
            },
            {
              label: "UPB",
              key: "UPB",
              value: rule.data.lowRiskPropertyTable.tableData.UPB,
              formatter: addPercentAtEnd,
            },
          ],
          onValueChange: (newValue: any) => {
            const tableData = newValue.reduce(
              (
                acc: { [x: string]: any },
                item: { key: string; value: any }
              ) => {
                acc[item.key] = item.value;
                return acc;
              },
              {}
            );
            inPlaceEditing(
              rule.id,
              FieldType.LOW_RISK_PROPERTY_TABLE_DATA,
              tableData
            );
          },
          onOptionChange: (newValue: any) => {
            inPlaceEditing(
              rule.id,
              FieldType.LOW_RISK_PROPERTY_TABLE_OPTION,
              newValue
            );
          },
          className: "bg-teal-100 border border-teal-500",
          option: rule.data.lowRiskPropertyTable.option,
        },
        position: { x: 500, y: 28 + 500 * index },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
      },
      {
        id: `4-${rule.id}`,
        type: NodeType.TABLE,
        data: {
          heading: "High Risk Properties",
          subheader: "subheading",
          tableData: [
            {
              key: "AAO",
              label: "AAO",
              value: rule.data.highRiskPropertyTable.tableData.AAO,
              formatter: addPercentAtEnd,
            },
            {
              key: "AIV",
              label: "AIV",
              value: rule.data.highRiskPropertyTable.tableData.AIV,
              formatter: addPercentAtEnd,
            },
            {
              key: "UPB",
              label: "UPB",
              value: rule.data.highRiskPropertyTable.tableData.UPB,
              formatter: addPercentAtEnd,
            },
          ],
          onValueChange: (newValue: any) => {
            const tableData = newValue.reduce(
              (
                acc: { [x: string]: any },
                item: { key: string; value: any }
              ) => {
                acc[item.key] = item.value;
                return acc;
              },
              {}
            );
            inPlaceEditing(
              rule.id,
              FieldType.HIGH_RISK_PROPERTY_TABLE_DATA,
              tableData
            );
          },
          className: "bg-red-100 border border-red-500",
          option: rule.data.highRiskPropertyTable.option,
          onOptionChange: (newValue: any) => {
            inPlaceEditing(
              rule.id,
              FieldType.HIGH_RISK_PROPERTY_TABLE_OPTION,
              newValue
            );
          },
        },
        position: { x: 500, y: 245 + 500 * index },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
      },
      {
        id: `5-${rule.id}`,
        type: NodeType.MULTIPLE_OFFER_TABLE,
        data: {
          heading: "",
          tableData: [
            {
              key: "daysToClosing",
              label: "Days to Closing",
              value1: rule.data.offers.daysToClosing[0],
              value2: rule.data.offers.daysToClosing[1],
              value3: rule.data.offers.daysToClosing[2],
              isWholeNumber: true,
            },
            {
              key: "discount",
              label: "Discount",
              value1: rule.data.offers.discount[0],
              value2: rule.data.offers.discount[1],
              value3: rule.data.offers.discount[2],
              isWholeNumber: true,
              formatter: addPercentAtEnd,
            },
            {
              key: "duegilligence",
              label: "Due Diligence Days",
              value1: rule.data.offers.duegilligence[0],
              value2: rule.data.offers.duegilligence[1],
              value3: rule.data.offers.duegilligence[2],
              isWholeNumber: true,
            }
          ],
          onValueChange: (newValue: any) => {
            const tableData = newValue.reduce(
              (
                acc: { [x: string]: any },
                item: { key: string; value1: any; value2: any; value3: any },
              ) => {
                console.log(acc)
                acc[item.key] = [item.value1, item.value2, item.value3];
                return acc;
              },
              {}
            );
            console.log(tableData)
            inPlaceEditing(rule.id, FieldType.MULTIPLE_OFFER_TABLE, tableData);
          },
          className: "bg-purple-200 border border-purple-500",
        },
        position: { x: 720, y: 155 + 500 * index },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
      },
      {
        id: `6-${rule.id}`,
        type: NodeType.TEXT,
        data: {
          label: "Profit Margin",
          value: rule.data.profitMarginValue,
          onValueChange: (newValue: any) => {
            inPlaceEditing(rule.id, FieldType.PROFIT_MARGIN_VALUE, newValue);
          },
        },
        position: { x: 1100, y: 190 + 500 * index },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
      },
      {
        id: `9-${rule.id}`,
        type: NodeType.WIDGET,
        data: {
          handleDeleteRule: () => {
            handleDelete(rule.id);
          },
          handleCopyRule: () => {
            handleCopy(rule.id);
          },
          handleEditRule: () => {
            handleEdit(rule.id);
          },
          handlePlayRule: () => {
            handlePlay(rule.id);
          },
        },
        position: { x: 0, y: 100 + 500 * index },
      },
    ];

    return newRuleNodes;
  };
  //generating the input and output rule nodes
  // const generateInputNode = (rule: Rule, index: number): any[] => {
  //   const ruleOutput = getRuleOutput(rule);
  //   const newNodes = [
  //     {
  //       id: `7-${rule.id}`,
  //       type: NodeType.TABLE,
  //       data: {
  //         heading: "Enter Sample Values",
  //         tableData: [
  //           {
  //             key: "AAO",
  //             label: "AAO",
  //             value: rule.input ? rule.input.AAO : 0,
  //             formatter: formatToUSDCurrency,
  //           },
  //           {
  //             key: "AIV",
  //             label: "AIV",
  //             value: rule.input ? rule.input.AIV : 0,
  //             formatter: formatToUSDCurrency,
  //           },
  //           {
  //             key: "UPB",
  //             label: "UPB",
  //             value: rule.input ? rule.input.UPB : 0,
  //             formatter: formatToUSDCurrency,
  //           },
  //           {
  //             key: "loanAmount",
  //             label: "Loan Amount",
  //             value: rule.input ? rule.input.loanAmount : 0,
  //             formatter: formatToUSDCurrency,
  //           },
  //         ],
  //         onValueChange: (newValue: any) => {
  //           const tableData = newValue.reduce(
  //             (
  //               acc: { [x: string]: any },
  //               item: { key: string; value: any }
  //             ) => {
  //               acc[item.key] = item.value;
  //               return acc;
  //             },
  //             {}
  //           );
  //           inPlaceInputEditing(rule.id, tableData);
  //         },
  //         className: "bg-purple-200 border border-purple-500",
  //       },
  //       position: { x: 1300, y: 144 + 500 * index },
  //       targetPosition: Position.Left,
  //       sourcePosition: Position.Right,
  //     },
  //     {
  //       id: `8-${rule.id}`,
  //       type: NodeType.LAST_TABLE,
  //       data: {
  //         heading: "Property is at High Risk",
  //         tableData: ruleOutput,
  //         className: "bg-red-100 border border-red-500",
  //       },
  //       position: { x: 1600, y: 142 + 500 * index },
  //       targetPosition: Position.Left,
  //     },
  //   ];
  //   return newNodes;
  // };

  const UpdateFooterNodes = (): any[] => {
    const updatedFooterNodes = nodes.footer.map((node) => {
      return {
        ...node,
        position: { x: node.position.x, y: 100 + 500 * rules.length },
      };
    });
    return updatedFooterNodes;
  };

  const flattenNodes = (nodes: Nodes): any[] => {
    const flattenedHeader = nodes.header;
    const flattenedFooter = nodes.footer;
    const flattenedRules = nodes.ruleNode.flat();

    return [...flattenedHeader, ...flattenedFooter, ...flattenedRules];
  };

  const flattenEdges = (edges: Edges): any[] => {
    const flattenedHeaderFooter = edges.headerFooter;
    const flattenedRules = edges.ruleNode.flat();

    return [...flattenedHeaderFooter, ...flattenedRules];
  };

  return (
    <div
      className="w-full"
      style={{ height: `${80 + 50 * (rules.length - 1)}vh` }}
    >
      <ReactFlow
        nodes={flattenNodes(nodes)}
        edges={flattenEdges(edges)}
        nodeTypes={nodeTypes}
        nodesFocusable={false} // Else it will assign [role="button"] to all nodes for TAB based selection
        zoomOnScroll={false} // Disable zooming with scroll wheel
        zoomOnPinch={false} // Disable pinch-to-zoom gestures
        panOnScroll={false} // Disable panning with the scroll wheel
        panOnDrag={false} // Disable panning by dragging
        zoomOnDoubleClick={false} // Disable zooming on double click
        fitView={true} // Prevent ReactFlow from automatically fitting the view
        preventScrolling={false} // Allow the user to scroll the view
        proOptions={{
          hideAttribution: true, // Hide the attribution
        }}
        style={{
          overflow: "auto", // Ensure the container scrolls
          height: "100vh", // Example: Full page height
          width: "100%", // Example: Full width
        }}
      />
    </div>
  );
};

export default RuleEngine;
