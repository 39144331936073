import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { formatCurrency } from "@/lib/utils";
import { useEffect, useState } from "react";

export interface AIVCalculation {
    source: string;
    min?: number;
    max?: number;
    exact?: number;
}
interface AIVChangeDialogProps {
    isOpen: boolean;
    toggleModal: () => void;
    onChange: (aivCalculation: AIVCalculation) => void;
}

const AIVChangeDialog: React.FC<AIVChangeDialogProps> = ({ isOpen, toggleModal, onChange }) => {
    const [type, setType] = useState<string>("exact");
    const [isNew, setIsNew] = useState<boolean>(false);
    const [aivList, setAivList] = useState<AIVCalculation[]>([]);
    const [formData, setFormData] = useState<AIVCalculation>({ source: "" });
    const [title, setTitle] = useState<string>("Select AIV");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [error, setError] = useState<string>("");
    const [selectedOption, setSelectedOption] = useState<string | null>("ICON");

    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
        setError(""); // Clear error when a selection is made
    };

    const validateSelection = () => {
        if (!selectedOption) {
            setError("Please select one of the options.");
            return false;
        }
        return true;
    };
    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};
        if (!formData.source) newErrors.source = "Source is required.";
        if (type === "minMax") {
            if (formData.min === undefined || formData.min === null) newErrors.min = "Min value is required.";
            if (formData.max === undefined || formData.max === null) newErrors.max = "Max value is required.";
            if (formData.min !== undefined && formData.max !== undefined && formData.min > formData.max) {
                newErrors.min = "Min value cannot be greater than Max value.";
                newErrors.max = "Max value cannot be less than Min value.";
            }
        } else if (type === "exact") {
            if (formData.exact === undefined || formData.exact === null) newErrors.exact = "Exact value is required.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = () => {
        if (validateForm()) {
            setAivList((prevList) => [...prevList, formData]);
            setIsNew(false);
            setFormData({ source: "" });
            setErrors({});
        }
    };
    const handleSubmit = () => {
        if (validateSelection()) {
            const filteredData = aivList.filter(item => item.source === selectedOption);
            onChange(filteredData[0]);
        }
    };
    const handleTypeChange = (value: string) => {
        setType(value);
        setFormData({ source: "" });
        setErrors({});
    };
    const handleCancel = () => {
        setErrors({});
        setIsNew(false);
        setTitle("Select AIV");
        setFormData({ source: "" });
    }
    const handleInputChange = (key: keyof AIVCalculation, value: string | number) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };
    const modalToggle = () => {
        setIsNew(false);
        toggleModal();
        setSelectedOption("ICON");
        setType("exact");
    }
    const onCreateNew = () => {
        setIsNew(true);
        setTitle("New AIV");
    }

    useEffect(() => {
        setAivList([
            {
                source: "ICON",
                min: 0,
                max: 0,
                exact: 123124,
            }, {
                source: "House Canary",
                min: 0,
                max: 0,
                exact: 1123124,
            }, {
                source: "Redfin",
                min: 0,
                max: 0,
                exact: 123124,
            }
        ]);
    }, []);

    return (
        <Dialog open={isOpen} onOpenChange={modalToggle}>
            <DialogContent className="max-w-screen w-[50%] overflow-x-hidden border-0 p-0 outline-none">
                <DialogHeader>
                    <div className="flex w-full bg-[#3B4F72] text-white min-h-20 text-xl items-center">
                        <div className="w-1/3 ps-7">
                            <DialogTitle className="font-bold text-xl space-y-14">{title}</DialogTitle>
                        </div>
                    </div>
                </DialogHeader>
                <>
                    {!isNew && (
                        <div className="space-y-4 p-6">
                            <div className="h-full overflow-y-auto p-2">
                                {aivList.map((option) => (
                                    <label key={option.source} className="flex items-center space-x-3 cursor-pointer mt-2">
                                        <input
                                            type="radio"
                                            name="priceOption"
                                            value={option.source}
                                            onChange={() => handleOptionChange(option.source)}
                                            checked={selectedOption === option.source}
                                            className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                        />
                                        <div className="text-sm">
                                            <div className="font-medium text-gray-900">{option.source}</div>
                                            <div className="text-gray-500">{formatCurrency(option.exact ? option.exact : (option.max == undefined ? 0 : option.max))}</div>
                                        </div>
                                    </label>
                                ))}
                            </div>
                            {error && <div className="text-red-500 text-sm">{error}</div>}
                            <div className="flex-shrink-0">
                                <button className={`apply-new-query-button`} onClick={() => handleSubmit()}>Save</button>
                                <button className="apply-new-query-button ml-5" onClick={() => onCreateNew()}>
                                    Create New
                                </button>
                            </div>
                        </div>
                    )}
                    {isNew && (
                        <div className="p-6">
                            <div className="h-full">
                                <div className="mb-4">
                                    <div className="flex items-center space-x-4">
                                        <label className="flex items-center space-x-2 cursor-pointer">
                                            <input
                                                type="radio"
                                                name="type"
                                                value="minMax"
                                                checked={type === "minMax"}
                                                onChange={() => handleTypeChange("minMax")}
                                                className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <span className="text-gray-700">Min/Max</span>
                                        </label>
                                        <label className="flex items-center space-x-2 cursor-pointer">
                                            <input
                                                type="radio"
                                                name="type"
                                                value="exact"
                                                checked={type === "exact"}
                                                onChange={() => handleTypeChange("exact")}
                                                className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <span className="text-gray-700">Exact</span>
                                        </label>
                                    </div>
                                </div>
                                {type === "minMax" ? (
                                    <div>
                                        <label className="block mb-2">
                                            <span className="text-gray-700">Min Value</span>
                                            <input
                                                type="number"
                                                placeholder="Enter Min Value"
                                                value={formData.min || ""}
                                                className="icon-input"
                                                onChange={(e) => handleInputChange("min", Number(e.target.value))}
                                            />
                                            {errors.min && <div className="text-red-600 text-sm">{errors.min}</div>}
                                        </label>
                                        <label className="block mb-2">
                                            <span className="text-gray-700">Max Value</span>
                                            <input
                                                type="number"
                                                placeholder="Enter Max Value"
                                                value={formData.max || ""}
                                                className="icon-input"
                                                onChange={(e) => handleInputChange("max", Number(e.target.value))}
                                            />
                                            {errors.max && <div className="text-red-600 text-sm">{errors.max}</div>}
                                        </label>
                                        <label className="block mb-4">
                                            <span className="text-gray-700">Source</span>
                                            <input
                                                type="text"
                                                placeholder="Enter Source"
                                                value={formData.source || ""}
                                                className="icon-input"
                                                onChange={(e) => handleInputChange("source", e.target.value)}
                                            />
                                            {errors.source && <div className="text-red-600 text-sm">{errors.source}</div>}
                                        </label>
                                    </div>
                                ) : (
                                    <div>
                                        <label className="block mb-2">
                                            <span className="text-gray-700">Value:</span>
                                            <input
                                                type="number"
                                                placeholder="Enter Value"
                                                value={formData.exact || ""}
                                                className="icon-input"
                                                onChange={(e) => handleInputChange("exact", Number(e.target.value))}
                                            />
                                            {errors.exact && <div className="text-red-600 text-sm">{errors.exact}</div>}
                                        </label>
                                        <label className="block mb-4">
                                            <span className="text-gray-700">Source:</span>
                                            <input
                                                type="text"
                                                placeholder="Enter Source"
                                                value={formData.source || ""}
                                                className="icon-input"
                                                onChange={(e) => handleInputChange("source", e.target.value)}
                                            />
                                            {errors.source && <div className="text-red-600 text-sm">{errors.source}</div>}
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="flex-shrink-0 mt-[16px]">
                                <button className={`apply-new-query-button`} onClick={handleSave}>
                                    Save
                                </button>
                                <button className="apply-new-query-button ml-5" onClick={() => handleCancel()}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                </>
            </DialogContent>
        </Dialog>
    );
};

export default AIVChangeDialog;