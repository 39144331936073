import { ArrowLeft, ArrowRight, Save, Undo, X } from "lucide-react";
import React from "react";

interface WizardBoxProps {
  children: React.ReactNode;
  onPrevious?: () => void;
  onNext?: () => void;
  onCancel: () => void;
  onRevert: () => void;
  heading: string;
  steps: number,
  currentStep: number
}

const WizardBox: React.FC<WizardBoxProps> = ({
  children,
  onPrevious,
  onNext,
  onCancel,
  onRevert,
  heading,
  steps,
  currentStep
}) => {
  return (

    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-xl p-6 max-h-[90vh] overflow-y-auto relative">
        {/* Top-right Cancel Icon */}
        <button
          className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={onCancel}
        >
          <X className="w-6 h-6" />
        </button>

        {/* Modal Content */}
        <h2 className="text-xl font-semibold mb-4">{heading}</h2>
        <form
          className="space-y-4"
          onSubmit={(e) => {
            e.preventDefault();
            // handleFormSubmit();
          }}
        >
          {children}
        </form>



        {/* Action Buttons */}
        <div className="mt-6 flex justify-end space-x-2">
          {onPrevious && (
            <button
              className="flex items-center gap-2 px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={onPrevious}
            >
              <ArrowLeft className="w-4 h-4" />
              Previous
            </button>
          )}
          <button
            className="flex items-center gap-2 px-4 py-2 text-black bg-white rounded-md shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onRevert}
          >
            <Undo className="w-4 h-4" />
            Revert
          </button>
          {onNext && (
            <button
              className="flex items-center gap-2 px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={onNext}
            >
              {(steps === currentStep) && <Save className="w-4 h-4" />}
              {steps === currentStep ? 'Save' : 'Next'}
              {(steps > currentStep) && <ArrowRight className="w-4 h-4" />}
            </button>
          )}
        </div>
        {/* Step Progress Bar */}
        <div>
          {/* Progress Bar with Text */}
          <div className="flex items-center justify-between mb-2">
            {/* Progress Text */}
            <span className="text-sm text-gray-600">
              Step ({currentStep}/{steps})
            </span>
          </div>

          {/* Progress Bar */}
          <div className="relative h-2 bg-gray-200 rounded-full">
            <div
              className="absolute top-0 left-0 h-2 bg-blue-600 rounded-full"
              style={{ width: `${(currentStep / steps) * 100}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WizardBox;
