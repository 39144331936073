// create new third party
export const permissionsArr: any = [
  { value: 'admin', label: 'Admin' },
  { value: 'buying', label: 'Buying' },
  { value: 'selling', label: 'Selling' },
];

export const contanctType: any = [
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone' },
];

export const entryType: any = [
  { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
  { value: 'General Partnership', label: 'General Partnership' },
  { value: 'Limited Partnership', label: 'Limited Partnership' },
  { value: 'Limited Liability Partnership', label: 'Limited Liability Partnership' },
  { value: 'C Corporation', label: 'C Corporation' },
  { value: 'S Corporation', label: 'S Corporation' },
  { value: 'Limited Liability Company', label: 'Limited Liability Company' },
  { value: 'Nonprofit Corporation', label: 'Nonprofit Corporation' },
  { value: 'Cooperative', label: 'Cooperative' },
  { value: 'Professional Corporation', label: 'Professional Corporation' },
  { value: 'Professional Limited Liability Company', label: 'Professional Limited Liability Company' },
  { value: 'Benefit Corporation', label: 'Benefit Corporation' },
  { value: 'Series LLC', label: 'Series LLC' },
  { value: 'Joint Venture', label: 'Joint Venture' },
  { value: 'Business Trust', label: 'Business Trust' },
  { value: 'Charitable Trust', label: 'Charitable Trust' },
  { value: 'Unincorporated Association', label: 'Unincorporated Association' },
];

export const partyType: any = [
  { value: 'Lender', label: 'Lender' },
  { value: 'Private Lender', label: 'Private Lender' },
  { value: 'Borrower', label: 'Borrower' },
  { value: 'Co-Borrower', label: 'Co-Borrower' },
  { value: 'Guarantor', label: 'Guarantor' },
  { value: 'Mortgage Broker', label: 'Mortgage Broker' },
  { value: 'Loan Officer', label: 'Loan Officer' },
  { value: 'Real Estate Agent', label: 'Real Estate Agent' },
  { value: 'Underwriter', label: 'Underwriter' },
  { value: 'Appraiser', label: 'Appraiser' },
  { value: 'Title Company', label: 'Title Company' },
  { value: 'Escrow Agent', label: 'Escrow Agent' },
  { value: 'Closing Attorney', label: 'Closing Attorney' },
  { value: 'Notary Public', label: 'Notary Public' },
  { value: 'Mortgage Servicer', label: 'Mortgage Servicer' },
  { value: 'Property Inspector', label: 'Property Inspector' },
  { value: 'Insurance Agent', label: 'Insurance Agent' },
  { value: 'Homeowner’s Association', label: 'Homeowner’s Association' },
  { value: 'Trustee', label: 'Trustee' },
  { value: 'Investor', label: 'Investor' },
  { value: 'Loan Processor', label: 'Loan Processor' },
  { value: 'Real Estate Attorney', label: 'Real Estate Attorney' },
  { value: 'Tax Assessor', label: 'Tax Assessor' },
  { value: 'Surveyor', label: 'Surveyor' },
  { value: 'Government Agency', label: 'Government Agency' },
];
