import Logo from '@/components/branding/Logo';
import PricingModalComponent from '@/components/dashboard/common/PricingModalComponent';
import QueryBuilderModalComponent from '@/components/dashboard/common/QueryBuilderModalComponent';
import useProgressBar from '@/hooks/useProgressBar';
import { formatDateString } from '@/lib/utils';
import useStore from '@/store';
import { SearchIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import '../Deals.css';
import DealsMobile from '../DealsMobile';
import { OpportunityQueryKeys, PropertyDealDetailsQueryKeys, useBannerDetails, useDealDetails, useDeals } from '../hooks';
import PropertyDetailsModal from '../PropertyDetailsModal';
import { DealStatusType } from '../types';
import { OpportunityData } from '../view-models/CarouselCardViewModel';
import { OpportunityListViewModel, OpportunityPageToken } from '../view-models/OpportunityListViewModel';
import { OpportunityViewModel } from '../view-models/OpportunityViewModel';
import DealsSellerDesktop from './DealsSellerDesktop';
import PropertyDealsSellerTable from './PropertyDealsSellerTable';

interface DealsSellerProps {
  status: DealStatusType;
  useDynamicPaging?: boolean;
}

const BuyersPage = ({ status }: DealsSellerProps) => {
  const [activeDealIndex, setActiveDealIndex] = useState(0);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>();
  const [page, setPage] = useState(1);
  const [token, setToken] = useState<OpportunityPageToken>();
  const setRenderMiddleHeaderContent = useStore((state) => state.setRenderMiddleHeaderContent);
  const queryClient = useQueryClient();
  const cachedOpportunities = queryClient.getQueryData([OpportunityQueryKeys, page]);
  const { data: opportunityData, error: opportunityError, isLoading: isLoadingOpportunities } = useDeals(page, token, !cachedOpportunities, status);
  const { data: propertyDetails, error: propertyDetailsError } = useDealDetails(selectedPropertyId);
  const { data: bannerDetails } = useBannerDetails();

  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [opportunities, setOpportunities] = useState<OpportunityViewModel[]>([]);
  const [isOpenQueryModal, setIsOpenQueryModal] = useState(false);
  const [isOpenPricingModal, setIsOpenPricingModal] = useState(false);
  const [renderProgressBarUI, showProgressBar, hideProgressBar] = useProgressBar();

  useEffect(() => {
    if (!isLoadingOpportunities) {
      hideProgressBar();
    }
  }, [isLoadingOpportunities]);

  useEffect(() => {
    // Define the middle content
    const middleContent = (): JSX.Element => (
      <>
        <div className="flex w-full items-center md:hidden">
          <div>
            <button className="btn">
              <SearchIcon />
            </button>
          </div>
          <div className="flex w-full flex-col items-center justify-center">
            <div className="items-center justify-center">
              <Logo className="mb-3 ml-0 mr-auto mt-2 h-auto w-[82px] lg:ml-auto lg:w-[109px] lg:-translate-x-3" />
            </div>
            <div className="flex w-[90%] flex-col items-center justify-center whitespace-nowrap text-sm font-medium">07/17/24-07/31/24</div>
          </div>
        </div>
      </>
    );

    // Set the middle content in the store
    setRenderMiddleHeaderContent(middleContent);
  }, [setRenderMiddleHeaderContent]);

  useEffect(() => {
    if (propertyDetails) {
      hideProgressBar();
      setIsOpenDetailsModal(true);
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (opportunityData) {
      hideProgressBar();

      const opportunityList = opportunityData.opportunities || [];

      const isValidPageToken = !!opportunityData.pageToken && !!opportunityData.pageToken.opportunityID;
      setHasMoreItems(isValidPageToken);

      if ((page === 1 && opportunityList.length === 0) || opportunityList.some((o) => opportunities.some((ol) => ol.opportunityId === o.opportunityId))) {
        setOpportunities([...opportunityList]);
        setActiveDealIndex(0);
      } else if (opportunityList.length > 0) {
        setOpportunities([...opportunities, ...opportunityList]);
      }
    }
  }, [opportunityData]);

  useEffect(() => {
    const error = opportunityError || propertyDetailsError;

    if (error) {
      hideProgressBar();

      console.error(error);
      toast.error(error?.message || 'An error occurred');
    }
  }, [opportunityError, propertyDetailsError]);

  const onViewPropertyDetailsHandler = (propertyId: string) => {
    console.log('onViewPropertyDetailsHandler v2: ', propertyId);
    setSelectedPropertyId(propertyId);
    const cachedPropertyDetails = queryClient.getQueryData([PropertyDealDetailsQueryKeys, propertyId]);
    if (cachedPropertyDetails === undefined) {
      showProgressBar();
    } else {
      setIsOpenDetailsModal(true);
    }
  };

  const onPageChangeHandler = (page: number) => {
    const cachedOpportunities = queryClient.getQueryData<OpportunityListViewModel>([OpportunityQueryKeys, status, page]);

    // If data is already cached, do not fetch again
    if (cachedOpportunities !== undefined) {
      setToken(cachedOpportunities.pageToken);
      setPage(page);
      return;
    }

    if (opportunityData && opportunityData.pageToken && hasMoreItems) {
      setToken(opportunityData.pageToken);
    }

    hideProgressBar(); // Hide progress bar when next set of data is loaded
    setPage(page);
  };

  const handleSlideChange = (index: number) => {
    setActiveDealIndex(index);
    console.log('activeDealIndex ...', activeDealIndex);

    // Load more data if the last item is reached
    if (hasMoreItems && index === opportunities.length - 4) {
      onPageChangeHandler(page + 1);
    }
  };

  const onUpdateDealsDesktopValue = (opportunityId: string, newValues: Record<string, number>) => {
    console.log('onUpdateDealsDesktopValue: ', newValues);

    const clonedOpportunities = [...opportunities];
    const updatedOpportunity = clonedOpportunities.find((o) => o.opportunityId === opportunityId);

    if (!updatedOpportunity) {
      console.error('Opportunity not found');
      return;
    }

    if (newValues.adjustedOpportunityMAOB) {
      updatedOpportunity.carouselCard!.opportunityData.adjustedOpportunityMAOB = newValues.adjustedOpportunityMAOB;
    }

    if (newValues.discountPercentMAOB) {
      updatedOpportunity.carouselCard!.opportunityData.discountPercentMAOB = newValues.discountPercentMAOB;
    }

    setOpportunities(clonedOpportunities);
  };

  const handleUpdateTableValue = (opportunityId: string, newValue: OpportunityData) => {
    const clonedOpportunities = [...opportunities];
    const updatedOpportunity = clonedOpportunities.find((o) => o.opportunityId === opportunityId);

    if (!updatedOpportunity) {
      console.error('Opportunity not found');
      return;
    }

    updatedOpportunity.carouselCard!.opportunityData = newValue;
    setOpportunities(clonedOpportunities);
  };

  return (
    <React.Fragment>
      {renderProgressBarUI()}
      <div className="deals-container hidden w-full flex-col md:flex">
        {/* {bannerDetails && <DealsBanner details={bannerDetails} />} */}

        {opportunities.length === 0 && !isLoadingOpportunities && (
          <div className="flex h-[300px] items-center justify-center">
            <p className="text-xl">No opportunities found</p>
          </div>
        )}
        <div className="text-center mb-3">{bannerDetails?.createdAt && <p>{formatDateString(bannerDetails?.createdAt)}</p>}</div>
        {isOpenPricingModal && (
          <PricingModalComponent
            isOpen={isOpenPricingModal}
            toggleModal={() => setIsOpenPricingModal(!isOpenPricingModal)}
            showProgressBar={showProgressBar}
            hideProgressBar={hideProgressBar}
          />
        )}

        {isOpenQueryModal && (
          <QueryBuilderModalComponent
            isOpen={isOpenQueryModal}
            toggleModal={() => setIsOpenQueryModal(!isOpenQueryModal)}
            showProgressBar={showProgressBar}
            hideProgressBar={hideProgressBar}
          />
        )}
        <div className="flex w-full items-center justify-center ">
          <div className="flex w-full items-center justify-between">
            <DealsSellerDesktop
              opportunities={undefined}
              lenderName={opportunities[activeDealIndex]?.carouselCard?.lenderName || 'No company name listed'}
              initialOpportunities={opportunities}
              onSlideChange={handleSlideChange}
              hideProgressBar={hideProgressBar}
              showProgressBar={showProgressBar}
              onUpdateValue={onUpdateDealsDesktopValue}
            />
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <h3 className="mb-3 mt-10 text-xl font-semibold text-black dark:text-white">Property Deals</h3>
          </div>
          {opportunities[activeDealIndex] && (
            <PropertyDealsSellerTable
              onDetailsClick={onViewPropertyDetailsHandler}
              properties={opportunities[activeDealIndex].properties!}
              opportunity={opportunities[activeDealIndex].carouselCard!.opportunityData}
              onUpdateValue={handleUpdateTableValue}
              showProgressBar={showProgressBar}
              hideProgressBar={hideProgressBar}
            />
          )}
        </div>
        {Array.isArray(opportunities[activeDealIndex]?.properties) && opportunities[activeDealIndex]!.properties!.length && (
          <PropertyDetailsModal
            isOpen={isOpenDetailsModal}
            toggleModal={() => setIsOpenDetailsModal(!isOpenDetailsModal)}
            property={opportunities[activeDealIndex]!.properties!.find((p) => p.id === selectedPropertyId)}
            details={propertyDetails}
          />
        )}
      </div>
      <div className="deals-container flex w-full flex-col gap-5 md:hidden">
        <DealsMobile />
      </div>
    </React.Fragment>
  );
};

export default BuyersPage;
