import { useAuth } from '@/components/AuthProvider';
import { formatCurrency, formatCurrencyShort } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CircleChart from '../../../charts/CircleChart';
import CarouselComponent from '../../../common/CarouselComponent';
import '../DealsDesktop.css';
import { DEAL_STATUS } from '../enum';
import { useOpportunityStatusUpdate, useUpdateOpportunityPricing } from '../hooks';
import { UpdateOpportunityStatusInput, UpdateOpportunityStatusResponse } from '../types'; //DealStatusType,
import { OpportunityViewModel } from '../view-models/OpportunityViewModel';
// import { updateOpportunityStatus } from '../services/dealService';

export interface OpportunityCalculations {
  discountPercentMAOB: number;
  adjustOpportunityMAOB: number;
  opportunityProperties: Object;
  profit: number;
  opportunityNetProfitMargin: number;
  percentage: number;
}

interface DealsSellerDesktopProps {
  opportunities: any;
  lenderName: string;
  initialOpportunities: OpportunityViewModel[];
  onSlideChange?: (index: number) => void;
  hideProgressBar?: () => void;
  showProgressBar?: () => void;
  onUpdateValue?: (opportunityId: string, values: Record<string, number>) => void;
}

const DealsSellerDesktop: React.FC<DealsSellerDesktopProps> = ({ initialOpportunities, showProgressBar, hideProgressBar }) => {
  const colorList = [
    '#ceecf9',
    '#1d2634',
    '#08a0e2',
    '#d2d4d6',
    '#84d0f1',
    '#dddddd',
    '#0000FF', // Blue
    '#FFFF00', // Yellow
    '#FFA500', // Orange
    '#FF0000', // Red
    '#00FF00', // Green
    '#800080', // Purple
    '#00FFFF', // Cyan
    '#FF00FF', // Magenta
    '#00FF00', // Lime
    '#FFC0CB', // Pink
    '#A52A2A', // Brown
    '#808080', // Gray
    '#808000', // Olive
  ];
  const MAX_DISCOUNT = 0.3; // 30%
  const auth = useAuth();

  const [opportunityStatuses, setOpportunityStatuses] = useState<UpdateOpportunityStatusResponse[]>([]);
  const [oppDiscountPercentMAOBMap, setOppDiscountPercentMAOBMap] = useState<Record<string, number>>({});
  const [opportunities, setOpportunities] = useState<OpportunityViewModel[]>([]);
  const { mutate: updateOpportunityStatus, error: updatedStatusError, isLoading: isUpdatingOpportunityStatus } = useOpportunityStatusUpdate();
  const { error: updateOpportunityPricingError, isLoading: isUpdatingOpportunityPricing } = useUpdateOpportunityPricing();
  
  // console.log(updateOpportunityPricing, updateOpportunityStatus)
  // const auth = useAuth();

  useEffect(() => {
    if (initialOpportunities) {
      setOpportunities([...initialOpportunities]);
      const initialOpportunityStatuses = initialOpportunities.map((opportunity) => ({
        status: opportunity.status,
        opportunityId: opportunity.opportunityId,
      }));

      setOpportunityStatuses(initialOpportunityStatuses);

      const initialDicountPercentMAOBMap: Record<string, number> = {};
      initialOpportunities.forEach((opportunity) => {
        if (oppDiscountPercentMAOBMap[opportunity.opportunityId!] === undefined) {
          initialDicountPercentMAOBMap[opportunity.opportunityId!] = calculateDiscountPercentForSlider(opportunity.carouselCard!.opportunityData.discountPercentMAOB);
        } else {
          initialDicountPercentMAOBMap[opportunity.opportunityId!] = oppDiscountPercentMAOBMap[opportunity.opportunityId!];
        }
      });

      setOppDiscountPercentMAOBMap(initialDicountPercentMAOBMap);
      // console.log("initialDicountPercentMAOBMap " + JSON.stringify(initialDicountPercentMAOBMap))
    }
  }, [initialOpportunities]);

  useEffect(() => {
    const error = updatedStatusError || updateOpportunityPricingError;
    if (!error) return;

    if (updatedStatusError) {
      // remove the last added opportunity status
      const updatedOpportunityStatuses = opportunityStatuses.slice(0, opportunityStatuses.length - 1);
      setOpportunityStatuses(updatedOpportunityStatuses);
    }

    toast.error('An error occurred while updating the opportunity status. Please try again later.');
    hideProgressBar && hideProgressBar();
  }, [updatedStatusError, updateOpportunityPricingError]);

  useEffect(() => {
    if (opportunities.length > 0 && !isUpdatingOpportunityStatus && !isUpdatingOpportunityPricing) {
      hideProgressBar && hideProgressBar();
    } else {
      showProgressBar && showProgressBar();
    }
  }, [isUpdatingOpportunityStatus, isUpdatingOpportunityPricing]);

  const calculateDiscountPercentForSlider = (discountPercentMAOB: number): number => {
    // console.log("discountPercent here ... " + (1 - (discountPercentMAOB / MAX_DISCOUNT)) * 100)
    return (1 - discountPercentMAOB / MAX_DISCOUNT) * 100;
  };

  const calculateOpportunityMetrics = (opportunityMAOB: number, opportunityMAOS: number, sliderDiscountPercent: number): OpportunityCalculations => {
    const sliderValue = Number(sliderDiscountPercent / 100);
    let discountPercentMAOB;
    if (sliderValue > 1) {
      discountPercentMAOB = (1 - sliderValue) * MAX_DISCOUNT;
    } else {
      discountPercentMAOB = Math.abs((1 - sliderValue) * MAX_DISCOUNT);
    }
    // console.log("opportunityMAOB here ... " + opportunityMAOB)
    const adjustedMAOB = opportunityMAOB * (1 - discountPercentMAOB); // Bluebutton $
    if (discountPercentMAOB < 0) {
    }
    const profit = opportunityMAOS - adjustedMAOB;
    const opportunityNetProfitMargin = Number((profit / adjustedMAOB).toFixed(2)); // saved value
    const percentage = Number(((profit / (adjustedMAOB * 0.2)) * 100).toFixed(2)); // 3260000 profit / 1670000*0.2 = 33400
    const calcMOAB = opportunityMAOB * (1 - MAX_DISCOUNT + sliderValue * MAX_DISCOUNT); //

    // console.log("profit " + profit)
    // console.log("percentage " + percentage)
    // console.log("calcMOAB " + calcMOAB)
    // console.log("calculateOpportunityMetrics " + organizationId + " " + " " + " sliderDiscountPercent =" + sliderDiscountPercent + " discountPercentMAOB " + discountPercentMAOB + " adjustOpportunityMAOB =" + calcMOAB)
    // console.log("discountPercentMAOB " + discountPercentMAOB)
    // console.log("adjustedMAOB "+ adjustedMAOB)
    // console.log("opportunityMAOS "+ opportunityMAOS)
    // console.log("opportunityMAOB " + opportunityMAOB)
    // console.log("profit "+ profit)
    // console.log("adjustOpportunityMAOB "+ calcMOAB)
    // console.log("percentage "+ percentage)

    return {
      discountPercentMAOB: Number(discountPercentMAOB),
      opportunityProperties: {},
      adjustOpportunityMAOB: calcMOAB,
      profit,
      opportunityNetProfitMargin,
      percentage,
    };
  };

  const opportunityStatusChangeHandler = (opportunity: OpportunityViewModel, newStatus: DEAL_STATUS): void => {
    if (!auth || !opportunity) return;

    const latestStatus = opportunity.status === newStatus ? DEAL_STATUS.Open : newStatus;
    const isConfirmed = window.confirm(`Are you sure you want to add this to ${latestStatus} items?`);

    if (!isConfirmed) return;

    const cachedOpportunityStatus = { status: latestStatus, opportunityId: opportunity.opportunityId };
    const updatedOpportunityStatuses = [...opportunityStatuses];

    // Find the index of the existing opportunity status
    const index = updatedOpportunityStatuses.findIndex((status) => status.opportunityId === cachedOpportunityStatus.opportunityId);

    if (index !== -1) {
      // Update the existing status
      updatedOpportunityStatuses[index].status = cachedOpportunityStatus.status;
    } else {
      // Add the new status
      updatedOpportunityStatuses.push(cachedOpportunityStatus);
    }

    setOpportunityStatuses(updatedOpportunityStatuses);
    const updatedOpportunity: UpdateOpportunityStatusInput = {
      opportunityId: opportunity.opportunityId,
      userId: auth.sub,
      totalProperties: opportunity.properties?.length || 0,
      newStatus: latestStatus,
    };

    updateOpportunityStatus(updatedOpportunity);
  };

  return (
    <div className="w-full ">
      <CarouselComponent>
        {opportunities.map((opportunity) => {
          const { totalUPB, countOfAssetClassMix, countOfBorrower, TotalDealFirstLienPositionOriginationAmount, opportunityData } = opportunity.carouselCard!;

          const { opportunityMAOS, opportunityMAOB, opportunityAIV, opportunityUPB, opportunityAAO } = opportunityData;
          const assetClassMixKeys = Object.keys(countOfAssetClassMix);
          const colorMapAssetClassMix = assetClassMixKeys.map((key, index) => ({
            color: colorList[index % colorList.length],
            description: key,
          }));
          const borrowerArray = Object.entries(countOfBorrower).map(([name, count]) => ({
            name,
            count,
          }));
          const borrowerNames = borrowerArray.map((borrower) => borrower.name);
          const colorMapBorrower = borrowerNames.map((key, index) => ({
            color: colorList[index % colorList.length],
            description: key,
          }));
          const reformattedCountOfBorrower = borrowerArray.map((borrower) => borrower.count);

          const { adjustOpportunityMAOB } = calculateOpportunityMetrics(opportunityMAOB, opportunityMAOS, oppDiscountPercentMAOBMap[opportunity.opportunityId!] || 0);
          const countOfAssetClassMixValues = Object.values(countOfAssetClassMix);
          const blueButtonValue = adjustOpportunityMAOB;
          // const editedMAOB = editedMAOBList.find(item => item.id === opportunity.opportunityId);

          return (
            <div className="buyers-page seller-slide-outer flex-col ">
              <div className="seller-slide px-5 py-3 2xl:px-8">
                <div className="buyers-page-header flex w-full items-center justify-center">
                  <div className="buyers-page-header-left text-center">
                    <div className="thin-title mb-1 items-center justify-center text-xs uppercase ">COUNT OF ASSET CLASS MIX</div>
                    <div className="w-full">
                      <CircleChart
                        className="h-[127px] font-bold"
                        series={countOfAssetClassMixValues}
                        fillColors={colorMapAssetClassMix.map((item) => item.color)}
                        title={countOfAssetClassMixValues.length.toString()}
                        donutSize="60%"
                        labels={assetClassMixKeys}
                      />
                    </div>
                    <div className="xl:mt-1 2xl:mt-3 2xl:h-[60px]">
                      {colorMapAssetClassMix.map((item, index) => (
                        <div key={index} className="color-item flex items-center justify-center  text-xs lg:ml-0">
                          <div className="me-3" style={{ backgroundColor: item.color, width: '10px', height: '10px', borderRadius: '5px' }}></div>
                          <span className=" font-normal text-[#929dad] ">{item.description}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="buyers-page-header-center min-w-8 text-center text-stone-600">
                    <div className="buyers-page-header-title items-center justify-center text-xl text-black dark:text-white">
                      Asking Price: {formatCurrency(adjustOpportunityMAOB)}
                    </div>
                    <div className="">
                      <div className="my-0 flex justify-evenly p-2">
                        <div className="thin-title text-xs uppercase">
                          <div className="  text-sm">AIV</div>
                          <div className="estimated-value-row-wrapper mb-3 text-center text-sm font-bold 2xl:text-base">
                            {((blueButtonValue / opportunityAIV) * 100).toFixed(0)}%
                          </div>
                          <div className="estimated-value-row-wrapper text-center text-sm font-bold 2xl:text-base">
                            {formatCurrencyShort({ amount: opportunityAIV, decimals: 2 }) || 0}
                          </div>
                        </div>

                        <div className="thin-title text-xs uppercase">
                          <div className=" ">UPB</div>
                          <div className="estimated-value-row-wrapper mb-3 text-center text-sm font-bold 2xl:text-base">
                            {((blueButtonValue / opportunityUPB) * 100).toFixed(0)}%
                          </div>
                          <div className="estimated-value-row-wrapper text-center text-sm font-bold 2xl:text-base">
                            {formatCurrencyShort({ amount: TotalDealFirstLienPositionOriginationAmount, decimals: 2 }) || 0}
                          </div>
                        </div>

                        <div className="thin-title text-xs uppercase">
                          <div className=" ">AAO</div>
                          <div className="estimated-value-row-wrapper mb-3 text-center text-sm font-bold 2xl:text-base">
                            {((blueButtonValue / opportunityAAO) * 100).toFixed(0)}%
                          </div>
                          <div className="estimated-value-row-wrapper text-center text-sm font-bold 2xl:text-base">
                            {formatCurrencyShort({ amount: totalUPB, decimals: 2 }) || 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="buyers-page-header-right text-center">
                    <div className="thin-title mb-1 items-center justify-center text-xs uppercase ">COUNT OF BORROWER</div>
                    <CircleChart
                      className="h-[127px] font-bold lg:w-[100%]"
                      series={Object.values(reformattedCountOfBorrower)}
                      fillColors={colorMapBorrower.map((item) => item.color)}
                      title={reformattedCountOfBorrower.length.toString()}
                      donutSize="60%"
                      labels={borrowerNames}
                    />
                    <div className="ms-10 xl:mt-1 2xl:mt-3 2xl:h-[60px]">
                      <div className="color-item flex items-center justify-center  text-xs lg:ml-0">
                        <div className="me-3"></div>
                        <span className=" font-normal text-[#929dad] "></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-auto my-5 flex w-[80%] items-center justify-center">
                  <div className="w-[20%]">
                    <div className=" ">Status</div>
                    <div className="">
                      <strong>Expired</strong>
                    </div>
                  </div>
                  <div className="w-[20%]">
                    <div className=" ">Seller</div>
                    <div className="">
                      <strong>ICON</strong>
                    </div>
                  </div>
                  <div className="w-[20%]">
                    <div className=" ">Asset Type</div>
                    <div className="">
                      <strong>NPL</strong>
                    </div>
                  </div>
                  <div className="w-[20%]">
                    <div className=" ">Expires</div>
                    <div className="">
                      <strong>01/23/25</strong>
                    </div>
                  </div>
                  <div className="w-[20%]">
                    <div className=" ">Created</div>
                    <div className="">
                      <strong>08/15/24</strong>
                    </div>
                  </div>
                  <div className="w-[20%]">
                    <div className=" ">Total Prop</div>
                    <div className="">
                      <strong>2</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-center">
                <div className="flex w-[70%] justify-evenly">
                  <div>
                    <button
                      className="pill-wrapper flex h-8 items-center text-white xl:w-[200px]"
                      onClick={() => opportunityStatusChangeHandler(opportunity, DEAL_STATUS.BuyerOfferAccepted)}
                    >
                      Approve
                    </button>
                  </div>
                  <div>
                    <button className="pill-wrapper flex h-8 items-center text-white xl:w-[200px]">Counter</button>
                  </div>
                  <div>
                    <button
                      className="pill-wrapper-red flex h-8 items-center text-white xl:w-[200px]"
                      onClick={() => opportunityStatusChangeHandler(opportunity, DEAL_STATUS.BuyerOfferRejected)}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </CarouselComponent>
    </div>
  );
};

export default DealsSellerDesktop;
