import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { getPricingRules, getPricingRuleBaseVersions, PricingRule, PricingRuleBaseVersion } from './models/PricingRules';
import GlobalPricingSettingsScreen from '../dashboard/pages/global-pricing-rule/components/GlobalPricingSettingsScreen';
import { AlertCircle, ChevronsUpDownIcon, Plus, Save, WandSparkles } from 'lucide-react';
import { Button } from '../ui/button';

export interface PricingRuleSettingsProps {
  onLoadingStart?: () => void;
  onLoadingComplete?: () => void;
}

const PricingRuleSettings = ({ onLoadingStart, onLoadingComplete }: PricingRuleSettingsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoading) onLoadingStart?.();
    else onLoadingComplete?.();
  }, [isLoading]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [criticalError, setCriticalError] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (criticalError) {
      toast.error(criticalError);
    }
  }, [criticalError]);

  const [rules, setRules] = useState<PricingRule[] | undefined>(undefined);
  const [versions, setVersions] = useState<PricingRuleBaseVersion[] | undefined>(undefined);
  const childRef = useRef<{ handleAddNewRule: () => void; handleAddWithWizard: () => void }>(null);

  const triggerActionOne = () => {
    if (childRef.current) {
      childRef.current.handleAddNewRule();
    }
  };

  const triggerActionTwo = () => {
    if (childRef.current) {
      childRef.current.handleAddWithWizard();
    }
  };

  // Fetch predefined roles set on component mount
  useEffect(() => {
    const fetchAll = async () => {
      setIsLoading(true);

      try {
        setVersions(await getPricingRuleBaseVersions());
        setRules(await getPricingRules());
        // throw new Error('Not implemented');
      } catch (error) {
        setCriticalError(`Error fetching Pricing Rule Versions: ${error}`);
      }

      // const timeout = setTimeout(() => {
      //   setIsLoading(false);
      // }, 5000);
      // return () => clearTimeout(timeout);
      setIsLoading(false);
    };

    fetchAll();
  }, []);

  // TODO temp to avoid build error
  console.log(rules);

  return (
    <div className={`relative overflow-hidden h-full`}>
      <div className="h-full flex flex-col">
        {/* Main Content Area */}
        <div className={`h-full ${criticalError ? 'overflow-hidden' : 'overflow-auto'}`}>
          {/* <PricingRuleFlowGrid rules={rules} /> */}
          <GlobalPricingSettingsScreen ref={childRef}/>
        </div>

        {/* Footer */}
        <div className="flex flex-col-reverse pt-5 sm:flex-row sm:justify-end sm:space-x-2">

          {/* Dropdown */}
          <div className="relative sm:absolute sm:left-0 sm:bottom-0">
            <Button
              variant="ghost"
              className="text-accent-foreground bg-transparent rounded-md font-medium text-sm h-9 shrink-0 min-w-0 !px-2 relative"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              Versions
              <ChevronsUpDownIcon className="w-5 h-5 ml-2" />
            </Button>
            {dropdownOpen && (
              <div className="absolute bottom-full left-0 mb-2 w-48 bg-card rounded-lg shadow-lg border border-border overflow-hidden z-10">
                <ul className="py-2">
                  {versions?.map((version, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 text-sm text-accent-foreground hover:bg-hover cursor-pointer"
                      onClick={() => console.log(`Switch to version: ${version.name}`)}
                    >
                      {version.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>


          <button
            className="flex items-center gap-2 px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={triggerActionOne}
          >
            <Plus className="w-4 h-4" />
            Add New Rule
          </button>
          <button
            className="flex items-center gap-2 px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={triggerActionTwo}
          >
            <WandSparkles className="w-4 h-4" />
            Add Rule With Wizard
          </button>
          {/* <button
            className="flex items-center gap-2 px-4 py-2 text-black bg-white rounded-md shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Undo className="w-4 h-4" />
            Revert
          </button> */}
          <button
            className="flex items-center gap-2 px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Save className="w-4 h-4" />
            Save
          </button>
        </div>
      </div>

      {/* Critical Error Overlay */}
      {criticalError && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-20">
          <div className="bg-black bg-opacity-50 p-5 rounded-lg flex flex-col items-center">
            <AlertCircle className="w-10 h-10 mb-3 text-red-500" />
            <p className="text-white text-center text-lg">{criticalError}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingRuleSettings;
