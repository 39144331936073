import CarouselComponent from '../../common/CarouselComponent';
import './DealsDesktop.css';
// import LocationDotsMap from "../../deals/LocationDotsMap";
// import ProgressComponent from "../../common/ProgressComponent";
// import BlueCircleCheckIcon from "@/components/icons/BlueCircleCheckIcon";
// import BlueCircleXIcon from "@/components/icons/BlueCircleXIcon";
// import SemiCircleChart from "../../charts/SemiCircleChart";
// import CircleChart from "../../charts/CircleChart";
// import NumberDisplay from "../../common/NumberDisplay";
import { formatCurrency } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DEAL_STATUS } from './enum';
import { useOpportunityStatusUpdate, useUpdateOpportunityPricing } from './hooks';
import { UpdateOpportunityStatusInput, UpdateOpportunityStatusResponse } from './types'; //DealStatusType,
import { OpportunityViewModel } from './view-models/OpportunityViewModel';
import { useAuth } from '@/components/AuthProvider';
// import Properties from "../properties/Properties";

// interface DealsDesktopProps {
//   initialOpportunities: OpportunityViewModel[];
//   onSlideChange?: (index: number) => void;
//   hideProgressBar?: () => void;
//   showProgressBar?: () => void;
//   onUpdateValue?: (opportunityId: string, values: Record<string, number>) => void;
// }

export interface OpportunityCalculations {
  discountPercentMAOB: number;
  adjustOpportunityMAOB: number;
  opportunityProperties: Object;
  profit: number;
  opportunityNetProfitMargin: number;
  percentage: number;
}

interface DealsSellerDesktopProps {
  properties: any;
  opportunities: any;
  lenderName: string;
  initialOpportunities: OpportunityViewModel[];
  onSlideChange?: (index: number) => void;
  hideProgressBar?: () => void;
  showProgressBar?: () => void;
  onUpdateValue?: (opportunityId: string, values: Record<string, number>) => void;
}

const DealsSellerDesktop: React.FC<DealsSellerDesktopProps> = ({
  // lenderName,
  initialOpportunities,
  showProgressBar,
  hideProgressBar,
  // onUpdateValue
}) => {
  const MAX_DISCOUNT = 0.3; // 30%

  const [opportunityStatuses, setOpportunityStatuses] = useState<UpdateOpportunityStatusResponse[]>([]);
  const [oppDiscountPercentMAOBMap, setOppDiscountPercentMAOBMap] = useState<Record<string, number>>({});
  const [opportunities, setOpportunities] = useState<OpportunityViewModel[]>([]);
  const { mutate: updateOpportunityStatus, error: updatedStatusError, isLoading: isUpdatingOpportunityStatus } = useOpportunityStatusUpdate();
  const { error: updateOpportunityPricingError, isLoading: isUpdatingOpportunityPricing } = useUpdateOpportunityPricing();
  // console.log(updateOpportunityPricing, updateOpportunityStatus)
  const auth = useAuth();

  useEffect(() => {
    if (initialOpportunities) {
      setOpportunities([...initialOpportunities]);
      const initialOpportunityStatuses = initialOpportunities.map((opportunity) => ({
        status: opportunity.status,
        opportunityId: opportunity.opportunityId,
      }));

      setOpportunityStatuses(initialOpportunityStatuses);

      const initialDicountPercentMAOBMap: Record<string, number> = {};
      initialOpportunities.forEach((opportunity: any) => {
        if (oppDiscountPercentMAOBMap[opportunity.opportunityId] === undefined) {
          initialDicountPercentMAOBMap[opportunity.opportunityId] = calculateDiscountPercentForSlider(opportunity.carouselCard.opportunityData.discountPercentMAOB);
        } else {
          initialDicountPercentMAOBMap[opportunity.opportunityId] = oppDiscountPercentMAOBMap[opportunity.opportunityId];
        }
      });

      setOppDiscountPercentMAOBMap(initialDicountPercentMAOBMap);
      // console.log("initialDicountPercentMAOBMap " + JSON.stringify(initialDicountPercentMAOBMap))
    }
  }, [initialOpportunities]);

  useEffect(() => {
    const error = updatedStatusError || updateOpportunityPricingError;
    if (!error) return;

    if (updatedStatusError) {
      // remove the last added opportunity status
      const updatedOpportunityStatuses = opportunityStatuses.slice(0, opportunityStatuses.length - 1);
      setOpportunityStatuses(updatedOpportunityStatuses);
    }

    toast.error('An error occurred while updating the opportunity status. Please try again later.');
    hideProgressBar && hideProgressBar();
  }, [updatedStatusError, updateOpportunityPricingError]);

  useEffect(() => {
    if (opportunities.length > 0 && !isUpdatingOpportunityStatus && !isUpdatingOpportunityPricing) {
      hideProgressBar && hideProgressBar();
    } else {
      showProgressBar && showProgressBar();
    }
  }, [isUpdatingOpportunityStatus, isUpdatingOpportunityPricing]);

  const calculateDiscountPercentForSlider = (discountPercentMAOB: number): number => {
    // console.log("discountPercent here ... " + (1 - (discountPercentMAOB / MAX_DISCOUNT)) * 100)
    return (1 - discountPercentMAOB / MAX_DISCOUNT) * 100;
  };

  const calculateOpportunityMetrics = (opportunityMAOB: number, opportunityMAOS: number, sliderDiscountPercent: number): OpportunityCalculations => {
    const sliderValue = Number(sliderDiscountPercent / 100);
    let discountPercentMAOB;
    if (sliderValue > 1) {
      discountPercentMAOB = (1 - sliderValue) * MAX_DISCOUNT;
    } else {
      discountPercentMAOB = Math.abs((1 - sliderValue) * MAX_DISCOUNT);
    }
    // console.log("opportunityMAOB here ... " + opportunityMAOB)
    const adjustedMAOB = opportunityMAOB * (1 - discountPercentMAOB); // Bluebutton $
    if (discountPercentMAOB < 0) {
    }
    const profit = opportunityMAOS - adjustedMAOB;
    const opportunityNetProfitMargin = Number((profit / adjustedMAOB).toFixed(2)); // saved value
    const percentage = Number(((profit / (adjustedMAOB * 0.2)) * 100).toFixed(2)); // 3260000 profit / 1670000*0.2 = 33400
    const calcMOAB = opportunityMAOB * (1 - MAX_DISCOUNT + sliderValue * MAX_DISCOUNT); //

    // console.log("profit " + profit)
    // console.log("percentage " + percentage)
    // console.log("calcMOAB " + calcMOAB)
    // console.log("calculateOpportunityMetrics " + organizationId + " " + " " + " sliderDiscountPercent =" + sliderDiscountPercent + " discountPercentMAOB " + discountPercentMAOB + " adjustOpportunityMAOB =" + calcMOAB)
    // console.log("discountPercentMAOB " + discountPercentMAOB)
    // console.log("adjustedMAOB "+ adjustedMAOB)
    // console.log("opportunityMAOS "+ opportunityMAOS)
    // console.log("opportunityMAOB " + opportunityMAOB)
    // console.log("profit "+ profit)
    // console.log("adjustOpportunityMAOB "+ calcMOAB)
    // console.log("percentage "+ percentage)

    return {
      discountPercentMAOB: Number(discountPercentMAOB),
      opportunityProperties: {},
      adjustOpportunityMAOB: calcMOAB,
      profit,
      opportunityNetProfitMargin,
      percentage,
    };
  };

  const opportunityStatusChangeHandler = (opportunity: OpportunityViewModel | any, newStatus: DEAL_STATUS): void => {
    if (!auth || !opportunity) return;

    const latestStatus = opportunity.status === newStatus ? DEAL_STATUS.Open : newStatus;
    const isConfirmed = window.confirm(`Are you sure you want to add this to ${latestStatus} items?`);

    if (!isConfirmed) return;

    const cachedOpportunityStatus = { status: latestStatus, opportunityId: opportunity.opportunityId };
    const updatedOpportunityStatuses = [...opportunityStatuses];

    // Find the index of the existing opportunity status
    const index = updatedOpportunityStatuses.findIndex((status) => status.opportunityId === cachedOpportunityStatus.opportunityId);

    if (index !== -1) {
      // Update the existing status
      updatedOpportunityStatuses[index].status = cachedOpportunityStatus.status;
    } else {
      // Add the new status
      updatedOpportunityStatuses.push(cachedOpportunityStatus);
    }

    setOpportunityStatuses(updatedOpportunityStatuses);
    const updatedOpportunity: UpdateOpportunityStatusInput = {
      opportunityId: opportunity.opportunityId,
      userId: auth.sub,
      totalProperties: opportunity.properties.length,
      newStatus: latestStatus,
    };

    updateOpportunityStatus(updatedOpportunity);
  };

  return (
    <div className="w-full xl:h-[400px] 2xl:h-[400px]">
      <CarouselComponent>
        {opportunities.map((opportunity: any) => {
          const {
            // totalUPB,
            // totalmarketvalue,
            // locations,
            lenderName,
            // totalTaxLiens,
            // totalVolume,
            // totalAmount,
            // countOfAssetClassMix,
            // countOfBorrower,
            // TotalDealFirstLienPositionOriginationAmount,
            totalProperties,
            opportunityData,
          } = opportunity.carouselCard;
          // const assetClassMixKeys = Object.keys(countOfAssetClassMix);
          // console.log("locations "+ JSON.stringify(locations))
          // console.log(" " + JSON.stringify(opportunities))
          // const countOfAssetClassMixValues = Object.values(countOfAssetClassMix);

          const {
            opportunityMAOS,
            opportunityMAOB,
            // opportunityAIV,
            // opportunityUPB,
            // opportunityAAO,
            // adjustedOpportunityMAOB,
          } = opportunityData;

          const { adjustOpportunityMAOB } = calculateOpportunityMetrics(opportunityMAOB, opportunityMAOS, oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0);

          // const sliderValue = (oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0) / 100;
          // const profit = opportunityMAOS - adjustedMAOB;
          // const percentage = ((profit / (adjustedMAOB * 0.2)) * 100).toFixed(0);
          // console.log("opportunity.opportunityId " + opportunity.opportunityId)
          // const blueButtonValue = adjustOpportunityMAOB;

          return (
            <div className="seller-slide-outer flex-col xl:h-[400px] 2xl:h-[400px]">
              <div className="flex w-full items-center justify-center">
                <h3 className="my-2 text-xl font-semibold text-black dark:text-white">{lenderName}</h3>
              </div>
              <div className="seller-slide px-5 pb-0 pt-3 2xl:px-8">
                <div className="flex w-full items-center justify-center">
                  <div className="my-5 flex min-w-8 flex-col items-center justify-center text-stone-600">
                    <div className=" items-center justify-center text-[1.5rem] text-black dark:text-white">Offer Price</div>
                    <div className="items-center justify-center text-[2rem] font-semibold text-black dark:text-white">{formatCurrency(adjustOpportunityMAOB)}</div>
                    {/* formatCurrencyShort({ amount: adjustedOpportunityMAOB, decimals: 2 }) */}
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <div className="h-[30px] w-[25%]">
                    <div className="mb-8">
                      <div className=" font-headings text-sm ">Status</div>
                      <div className="text-base font-bold text-[#1d2634]">Expired</div>
                    </div>
                    <div className="">
                      <div className="font-headings text-sm">Asset Type</div>
                      <div className="text-base font-bold text-[#1d2634]">Residential</div>
                    </div>
                  </div>
                  <div className="h-[30px] w-[25%]">
                    <div className="mb-8">
                      <div className=" font-headings text-sm ">Total Origination Amt</div>
                      <div className="text-base font-bold text-[#1d2634]">$226,806.66</div>
                    </div>
                    <div className="">
                      <div className="font-headings text-sm">Total Prop</div>
                      <div className="text-base font-bold text-[#1d2634]">{totalProperties}</div>
                    </div>
                  </div>
                  <div className="h-[30px] w-[25%]">
                    <div className="mb-8">
                      <div className="font-headings text-sm">Position</div>
                      <div className="text-base font-bold text-[#1d2634]">First</div>
                    </div>
                    <div className="">
                      <div className="font-headings text-sm">Expires</div>
                      <div className="text-base font-bold text-[#1d2634]">01/23/25</div>
                    </div>
                  </div>
                  <div className="h-[30px] w-[15%]">
                    <div className="mb-8">
                      <div className="font-headings text-sm">Created</div>
                      <div className="text-base font-bold text-[#1d2634]">08/15/24</div>
                    </div>
                  </div>
                  {/* <div className="w-[10%] h-[30px]">
                              <div className="mb-8">                          
                                <div className="text-sm font-headings">Total Prop</div>
                                <div className="text-[#1d2634] text-base font-bold">{totalProperties}</div>
                              </div>
                              <div className="">                          
                                <div className="text-sm font-headings"></div>
                                <div className="text-[#1d2634] text-base font-bold"></div>
                              </div>
                            </div> */}
                </div>
              </div>
              <div className="mb-2 flex w-full items-center justify-center">
                <div className="flex w-[70%] justify-evenly">
                  <div>
                    <button
                      className="pill-wrapper mt-3 flex h-8 items-center text-white xl:w-[200px]"
                      onClick={() => opportunityStatusChangeHandler(opportunity, DEAL_STATUS.OfferAccepted)}
                    >
                      Accept
                    </button>
                  </div>
                  <div>
                    <button className="pill-wrapper mt-3 flex h-8 items-center text-white xl:w-[200px]">Counter</button>
                  </div>
                  <div>
                    <button
                      className="pill-wrapper-red mt-3 flex h-8 items-center text-white xl:w-[200px]"
                      onClick={() => opportunityStatusChangeHandler(opportunity, DEAL_STATUS.OfferRejected)}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </CarouselComponent>
    </div>
  );
};

export default DealsSellerDesktop;
