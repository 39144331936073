import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchDeals, fetchOffers, fetchPropertyDealDetails, updateOpportunityStatus, fetchBannerDetails, fetchFilterDetails, updateFilter, fetchFilterPreview, fetchSavedQueries, updateOpportunityPricing, updatePropertyDealAttributes } from '../services/dealService'; //fetchBannerDetails
import { OpportunityListViewModel, OpportunityPageToken } from '../view-models/OpportunityListViewModel';
import { PropertyDetailsProps } from '../PropertyDetails.interface';
import { BannerDetailsViewModel } from '../view-models/BannerDetailsViewModel';
import { GetFilterPreviewInput, GetFilterPreviewResponse, SavedQueryResponse, UpdateFilterInput, UpdateFilterResponse, UpdateOpportunityPricingInput, UpdateOpportunityPricingResponse, UpdateOpportunityStatusInput, UpdateOpportunityStatusResponse, UpdatePropertyDealAttributesInput, UpdatePropertyDealAttributesResponse } from '../types';
import { DEAL_STATUS } from '../enum';
import { Field } from 'react-querybuilder';
import { toast } from 'react-toastify';
// import { BannerDetailsProps } from '../BannerDetails.interface';

export const useDeals = (pageIndex: string | number | undefined, pageToken?: OpportunityPageToken, enabled: boolean = true, status: string = DEAL_STATUS.Open, cognitoID?: string, order_by?: string,order_dir?: string) => {
  if (pageToken) {
    // ignore - done to fix vercel errors.
    // TODO: [Shyam] Check API definition and use or remove token!
  }
  return useQuery<OpportunityListViewModel, Error>(
    [OpportunityQueryKeys, status, pageIndex, cognitoID], // queryKey includes pagination params
    () => fetchDeals(status, cognitoID, order_by, order_dir, pageIndex),
    {
      enabled,
      keepPreviousData: true, // Keeps previous data while loading new data
      staleTime: 3600 * 1000, // Data is considered fresh for 1 hour
      cacheTime: 3600 * 1000, // Data remains in cache for 1 hour after it's no longer in use
    }
  );
};

export const useOffers = (pageIndex: number, pageToken?: OpportunityPageToken, enabled: boolean = true, status: string = DEAL_STATUS.OfferMade) => {
  return useQuery<OpportunityListViewModel, Error>(
    [OpportunityQueryKeys, status, pageIndex], // queryKey includes pagination params
    () => fetchOffers(pageToken, status),
    {
      enabled,
      keepPreviousData: true, // Keeps previous data while loading new data
      staleTime: 3600 * 1000, // Data is considered fresh for 1 hour
      cacheTime: 3600 * 1000, // Data remains in cache for 1 hour after it's no longer in use
    }
  );
};

export const OpportunityQueryKeys = "opportunities";
export const PropertyDealDetailsQueryKeys = "propertyDealDetails";
export const BannerQueryKeys = "bannerDetails";
export const FilterQueryKeys = "filterDetails";
export const FilterUpdateQueryKeys = "filterUpdate";
export const DealStatusUpdateQueryKeys = "dealStatusUpdate";
export const FilterPropertyPreviewQueryKey = "filterPropertyPreviewQueryKey";
export const SavedQueriesQueryKey = "savedQueries";

export const useDealDetails = (propertyId?: string, enabled: boolean = true) => {
  return useQuery<PropertyDetailsProps, Error>(
    [PropertyDealDetailsQueryKeys, propertyId],
    () => fetchPropertyDealDetails(propertyId),
    {
      enabled: enabled && propertyId !== undefined,
      keepPreviousData: true,
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
    }
  );
}

export const useOpportunityStatusUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<UpdateOpportunityStatusResponse, Error, UpdateOpportunityStatusInput>(
    (input: UpdateOpportunityStatusInput) => updateOpportunityStatus(input),
    {
      onSuccess: () => {
        toast.success("Opportunity status updated successfully.");
        // Invalidate and refetch all pages of the opportunities query
        queryClient.invalidateQueries([OpportunityQueryKeys]);
      },
      onError: (error) => {
        toast.error("Failed to update opportunity status. Please try again later.");
        // Handle error here
        console.error('Error updating opportunity status:', error);
      },
    }
  );
}

export const useBannerDetails = (enabled: boolean = true) => {
  return useQuery<BannerDetailsViewModel, Error>(
    [BannerQueryKeys],
    () => fetchBannerDetails(),
    {
      enabled,
      keepPreviousData: true,
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
    }
  );
}

export const useFilterDetails = (enabled: boolean = true) => {
  return useQuery<Field[], Error>(
    [FilterQueryKeys],
    async () => {
      const data = await fetchFilterDetails();
      // Sort the data here
      return data.sort((a, b) => {
        // Replace 'fieldName' with the actual field you want to sort by
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
    },
    {
      enabled,
      keepPreviousData: true,
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
    }
  );
}

export const useFilterUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<UpdateFilterResponse, Error, UpdateFilterInput>(
    (input: UpdateFilterInput) => updateFilter(input!),
    {
      onSuccess: (_data, input) => {
        // Invalidate and refetch all pages of the opportunities query
        if (!input.getCount) {
          queryClient.invalidateQueries([OpportunityQueryKeys]);
          queryClient.invalidateQueries([SavedQueriesQueryKey]);
          queryClient.invalidateQueries([BannerQueryKeys]);
        }
      },
      onError: (error) => {
        toast.error("Failed to update filter. Please try again later.");
        console.error('Error updating filter:', error);
      }
    }
  );
}

export const useFilterPreviewFetch = () => {
  const queryClient = useQueryClient();
  return useMutation<GetFilterPreviewResponse[], Error, GetFilterPreviewInput>(
    async (input: GetFilterPreviewInput) => {
      // Create a composite key using FilterPropertyPreviewQueryKey and input.fromIndex
      const cacheKey = [FilterPropertyPreviewQueryKey, input.filter, input.fromIndex];

      // Retrieve data from cache
      const cachedData = queryClient.getQueryData<GetFilterPreviewResponse[]>(cacheKey);
      console.log('cachedData:', cachedData);
      if (cachedData) {
        // Return cached data if available
        return cachedData;
      } else {
        // Call fetchFilterPreview if data is not in cache
        console.log("input " + input)
        return await fetchFilterPreview(input);
      }
    },
    {
      onSuccess: (data, input) => {
        // Create a composite key using FilterPropertyPreviewQueryKey and input.fromIndex
        const cacheKey = [FilterPropertyPreviewQueryKey, input.filter, input.fromIndex];

        // Update the cache with the new data
        queryClient.setQueryData(cacheKey, data);
      },
      onError: (error) => {
        toast.error("Failed to fetch preview filter. Please try again later.");
        console.error('Error updating filter:', error);
      }
    }
  );
}

export const useFetchSavedQueries = (userId?: string, enabled: boolean = true) => {
  return useQuery<SavedQueryResponse[], Error>(
    [SavedQueriesQueryKey, userId],
    () => fetchSavedQueries(userId!),
    {
      enabled: !!userId && enabled,
      keepPreviousData: true,
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
    }
  );
}

export const useUpdateOpportunityPricing = () => {

  return useMutation<UpdateOpportunityPricingResponse, Error,
    {
      input: UpdateOpportunityPricingInput,
      opportunityID: string,
      totalProperties: number,
      cognitoID: string
    }>(
      ({ input, opportunityID, totalProperties, cognitoID }) => updateOpportunityPricing(input, opportunityID, totalProperties, cognitoID),
      {
        onSuccess: () => {
          toast.success("Opportunity pricing updated successfully.");
        },
        onError: (error) => {
          toast.error("Failed to update opportunity pricing. Please try again later.");
          console.error('Error updating opportunity pricing:', error);
        },
      }
    );
};

export const useUpdatePropertyDealAttributes = () => {
  return useMutation<UpdatePropertyDealAttributesResponse, Error,
    {
      input: UpdatePropertyDealAttributesInput,
      opportunityID: string,
      totalProperties: number,
      cognitoID: string
    }>(
      ({ input, opportunityID, totalProperties, cognitoID }) => updatePropertyDealAttributes(input, opportunityID, totalProperties, cognitoID),
      {
        onSuccess: () => {
          toast.success("Property Deal attributes updated successfully.");
        },
        onError: (error) => {
          toast.error("Failed to update property Deal attributes. Please try again later.");
          console.error('Error updating property Deal attributes:', error);
        },
      }
    );
};