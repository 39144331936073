import { SVGProps } from 'react';
const CircleCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} fill="none" {...props}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M7.5.877a6.623 6.623 0 1 0 0 13.246A6.623 6.623 0 0 0 7.5.877ZM1.827 7.5a5.673 5.673 0 1 1 11.346 0 5.673 5.673 0 0 1-11.346 0Zm8.332-1.962a.5.5 0 0 0-.818-.576L6.52 8.972 5.357 7.787a.5.5 0 0 0-.714.7L6.227 10.1a.5.5 0 0 0 .765-.062l3.167-4.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CircleCheckIcon;
