import SearchIcon from '@/components/icons/SearchIcon';
import useProgressBar from '@/hooks/useProgressBar';
import useStore from '@/store';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import DealsDesktop from '../deals/DealsDesktop';
import DealsMobile from '../deals/DealsMobile';
import './Deals.css';
import DealsBanner from './DealsBanner';
import { OpportunityQueryKeys, PropertyDealDetailsQueryKeys, useBannerDetails, useDealDetails, useDeals } from './hooks/'; //useUpdatePropertyDealAttributes
import PropertyDealsTable from './PropertyDealsTable';
import PropertyDetailsModal from './PropertyDetailsModal';
import { OpportunityPageToken } from './view-models/OpportunityListViewModel';
// import PaginationComponent from "@/components/pagination-component/PaginationComponent";
import { useAuth } from '@/components/AuthProvider';
import PricingIcon from '@/components/icons/PricingIcon';
import { FilterIcon } from 'lucide-react';
import { toast } from 'react-toastify';
import Logo from '../../../branding/Logo';
import QueryBuilderModalComponent from '../../common/QueryBuilderModalComponent';
import { DealStatusType } from './types';
import { OpportunityData } from './view-models/CarouselCardViewModel';
import { OpportunityViewModel } from './view-models/OpportunityViewModel';
import PricingRuleSettingsDialog from '../../../pricing-rule/PricingRuleSettingsDialog';
// import { set } from "date-fns";
// import { useAuth } from "@/components/AuthProvider";

interface DealsV2Props {
  status: DealStatusType;
  useDynamicPaging?: boolean;
}

export const LIMIT_FILTER_PREVIEW = 5;

const DealsV2 = ({ status }: DealsV2Props) => {
  const auth = useAuth();

  const [activeDealIndex, setActiveDealIndex] = useState(0);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>();
  const [page, setPage] = useState(0);
  const [token, ] = useState<OpportunityPageToken>();
  const setRenderMiddleHeaderContent = useStore((state) => state.setRenderMiddleHeaderContent);
  const queryClient = useQueryClient();
  const cachedOpportunities = queryClient.getQueryData([OpportunityQueryKeys, page]);
  const { data: opportunityData, error: opportunityError, isLoading: isLoadingOpportunities } = useDeals(page, token, !cachedOpportunities, status, auth?.sub);
  const { data: propertyDetails, error: propertyDetailsError } = useDealDetails(selectedPropertyId);
  const { data: bannerDetails, error: bannerDetailsError, isLoading: isLoadingBannerDetails } = useBannerDetails();

  const [, setHasMoreItems] = useState(true);
  const [opportunities, setOpportunities] = useState<OpportunityViewModel[]>([]);
  const [isOpenQueryModal, setIsOpenQueryModal] = useState(false);
  const [isOpenPricingModal, setIsOpenPricingModal] = useState(false);
  const [renderProgressBarUI, showProgressBar, hideProgressBar] = useProgressBar();

  useEffect(() => {
    if (!isLoadingOpportunities && !isLoadingBannerDetails) {
      hideProgressBar();
    }
  }, [isLoadingOpportunities, isLoadingBannerDetails]);

  useEffect(() => {
    // Define the middle content
    const middleContent = (): JSX.Element => (
      <>
        <div className="hidden w-full items-center md:flex">
          <div className="flex w-1/2 items-center">
            <h1 className="title mr-5 text-4xl">Opportunities</h1>
            {/* {(opportunityError || propertyDetailsError)
                            && <div className="text-red-500">Error: {opportunityError?.message || propertyDetailsError?.message}</div>} 
                        <p className="oppurtunity-count mr-5">25</p> */}
            {/* <p className="new-update !text-[15px]"><span>New: 6</span><span className="ml-3">Updated: 8</span></p> */}
            {/* <p className="new-update !text-[15px] !text-[#929DAD] font-semibold">
                            <span className="ml-3">
                                {DEAL_STATUS.InReview} Bookmarked: {countByStatus(opportunityStatuses, DEAL_STATUS.InReview)}
                            </span>
                            <span className="ml-3">
                                {DEAL_STATUS.Rejected} Bookmarked: {countByStatus(opportunityStatuses, DEAL_STATUS.Rejected)}
                            </span>
                        </p> */}
          </div>
          <div className="w-1/2">
            <div className="flex w-full items-center justify-end">
              <div className="">
                <button className="btn" onClick={() => setIsOpenPricingModal(true)}>
                  <PricingIcon />
                </button>
              </div>
              <div className="pl-5">
                <button className="btn" onClick={() => setIsOpenQueryModal(true)}>
                  <FilterIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center md:hidden">
          <div>
            <button className="btn">
              <SearchIcon />
            </button>
          </div>
          <div className="flex w-full flex-col items-center justify-center">
            <div className="items-center justify-center">
              <Logo className="mb-3 ml-0 mr-auto mt-2 h-auto w-[82px] lg:ml-auto lg:w-[109px] lg:-translate-x-3" />
            </div>
            <div className="flex w-[90%] flex-col items-center justify-center whitespace-nowrap text-sm font-medium">07/17/24-07/31/24</div>
          </div>
        </div>
      </>
    );

    // Set the middle content in the store
    setRenderMiddleHeaderContent(middleContent);
  }, [setRenderMiddleHeaderContent]);

  useEffect(() => {
    if (propertyDetails) {
      hideProgressBar();
      setIsOpenDetailsModal(true);
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (opportunityData) {
      hideProgressBar();
      const opportunityList = (opportunityData as OpportunityViewModel)?.opportunities || [];
      const isValidPageToken = !!(opportunityData as OpportunityViewModel).pageToken && !!(opportunityData as OpportunityViewModel).pageToken.opportunityID;
      setHasMoreItems(isValidPageToken);
      if ((page === 1 && opportunityList.length === 0) || opportunityList.some((o: any) => opportunities.some((ol) => ol.opportunityId === o.opportunityId))) {
        setOpportunities([...opportunityList]);
        setActiveDealIndex(0);
      } else if (opportunityList.length > 0) {
        setOpportunities([...opportunities, ...opportunityList]);
      }
    }
  }, [opportunityData, page]);

  useEffect(() => {
    const error = opportunityError || propertyDetailsError || bannerDetailsError;

    if (error) {
      hideProgressBar();

      console.error(error);
      toast.error(error?.message || 'An error occurred');
    }
  }, [opportunityError, propertyDetailsError, bannerDetailsError]);

  const onViewPropertyDetailsHandler = (propertyId: string) => {
    console.log('onViewPropertyDetailsHandler v2: ', propertyId);
    setSelectedPropertyId(propertyId);
    const cachedPropertyDetails = queryClient.getQueryData([PropertyDealDetailsQueryKeys, propertyId]);
    if (cachedPropertyDetails === undefined) {
      showProgressBar();
    } else {
      setIsOpenDetailsModal(true);
    }
  };

  const onPageChangeHandler = (page: number) => {
    // const cachedOpportunities = queryClient.getQueryData<OpportunityListViewModel>([OpportunityQueryKeys, status, page, auth!.sub]);

    // If data is already cached, do not fetch again
    // if (cachedOpportunities !== undefined) {
    //   setToken(cachedOpportunities.pageToken);
    //   setPage(page);
    //   return;
    // }
    
    // console.log('>>>>>> opportunities ', opportunityData, page)
    // if (opportunityData && opportunityData.pageToken && hasMoreItems) {
    //   setToken(opportunityData.pageToken);
    // }
    hideProgressBar(); // Hide progress bar when next set of data is loaded
    setPage(page);
  };

  const handleSlideChange = (index: number) => {
    setActiveDealIndex(index);
    // console.log('>>>>>> opportunities activeDealIndex ...',activeDealIndex, opportunities);

    // Load more data if the last item is reached
    if (index === opportunities.length - 4) {
      onPageChangeHandler(page + 1);
    }
  };

  const onUpdateDealsDesktopValue = (opportunityId: string, newValues: Record<string, number>) => {
    console.log('onUpdateDealsDesktopValue: ', newValues);

    const clonedOpportunities = [...opportunities];
    const updatedOpportunity = clonedOpportunities.find((o) => o.opportunityId === opportunityId);

    if (!updatedOpportunity) {
      console.error('Opportunity not found');
      return;
    }

    // if (newValues.adjustedOpportunityMAOB) {
    //   updatedOpportunity.carouselCard.opportunityData.adjustedOpportunityMAOB = newValues.adjustedOpportunityMAOB;
    // }

    // if (newValues.discountPercentMAOB) {
    //   updatedOpportunity.carouselCard.opportunityData.discountPercentMAOB = newValues.discountPercentMAOB;
    // }
    if (updatedOpportunity.carouselCard) {
      if (newValues.adjustedOpportunityMAOB) {
        updatedOpportunity.carouselCard.opportunityData.adjustedOpportunityMAOB = newValues.adjustedOpportunityMAOB;
      }

      if (newValues.discountPercentMAOB) {
        updatedOpportunity.carouselCard.opportunityData.discountPercentMAOB = newValues.discountPercentMAOB;
      }
    } else {
      console.error('carouselCard is undefined');
    }

    setOpportunities(clonedOpportunities);
  };

  const handleUpdateTableValue = (opportunityId: string, newValue: OpportunityData) => {
    const clonedOpportunities = [...opportunities];
    const updatedOpportunity = clonedOpportunities.find((o) => o.opportunityId === opportunityId);

    if (!updatedOpportunity) {
      console.error('Opportunity not found');
      return;
    }
    if(updatedOpportunity.carouselCard) {
      updatedOpportunity.carouselCard.opportunityData = newValue;
    }
    setOpportunities(clonedOpportunities);
  };

  return (
    <>
      {renderProgressBarUI()}
      <div className="deals-container hidden w-full flex-col gap-5 md:flex">
        {/* {
                    totalPages > 0 && (
                        <PaginationComponent
                            totalPages={useDynamicPaging ? totalPages : maxPages}
                            setPage={onPageChangeHandler}
                            page={page}
                            isDisabledNext={useDynamicPaging ? disabledNextButton : page === maxPages}
                            numVisibleLinks={LIMIT_OPPORTUNITIES_PER_PAGE} />
                    )
                } */}
        {bannerDetails && <DealsBanner details={bannerDetails} />}
        {/* {bannerData && <DealsBanner
                    details={{
                        totalOpportunities: bannerData.numberOfOpportunities,
                        totalProperties: bannerData.numberOfProperties,
                        totalAIV: bannerData.totalMarketValue,
                        totalUPB: bannerData.totalUPB,
                        averageAIV: bannerData.averageMarketValue,
                        profitPercentage: bannerDetails?.profitPercentage || 0,
                        createdAt: bannerDetails?.createdAt || '',
                        totalPropertiesAsIsValue: bannerDetails?.totalPropertiesAsIsValue || 0,
                        metrics_uuid: bannerDetails?.metrics_uuid || '',
                        averagePropertyAsIsValue: bannerDetails?.averagePropertyAsIsValue || 0,
                    }} />} */}
        {opportunities.length === 0 && !isLoadingOpportunities && (
          <div className="flex h-[300px] items-center justify-center">
            <p className="text-xl">No opportunities found</p>
          </div>
        )}

        {isOpenPricingModal && (
          <PricingRuleSettingsDialog
            isOpen={isOpenPricingModal}
            onOpenChange={() => setIsOpenPricingModal(!isOpenPricingModal)}
            onLoadingStart={showProgressBar}
            onLoadingComplete={hideProgressBar}
          />
        )}

        {isOpenQueryModal && (
          <QueryBuilderModalComponent
            isOpen={isOpenQueryModal}
            toggleModal={() => setIsOpenQueryModal(!isOpenQueryModal)}
            showProgressBar={showProgressBar}
            hideProgressBar={hideProgressBar}
          />
        )}

        <DealsDesktop
          initialOpportunities={opportunities}
          onSlideChange={handleSlideChange}
          hideProgressBar={hideProgressBar}
          showProgressBar={showProgressBar}
          onUpdateValue={onUpdateDealsDesktopValue}
        />
        <div>
          <div className="flex items-center justify-between">
            <h3 className="my-5 text-xl font-semibold text-black dark:text-white">
              {opportunities[activeDealIndex] && opportunities[activeDealIndex].carouselCard?.lenderName} - Property Deals
            </h3>
          </div>
          {/* {console.log('>>>>>> opportunities ', opportunities, activeDealIndex)} */}
          {activeDealIndex !== undefined && opportunities?.[activeDealIndex] && opportunities?.[activeDealIndex]?.carouselCard && opportunities?.[activeDealIndex]?.carouselCard?.opportunityData && (
            <PropertyDealsTable
              onDetailsClick={onViewPropertyDetailsHandler}
              properties={opportunities[activeDealIndex].properties as any}
              opportunity={opportunities[activeDealIndex]?.carouselCard?.opportunityData}
              onUpdateValue={handleUpdateTableValue}
              showProgressBar={showProgressBar}
              hideProgressBar={hideProgressBar}
            />
          )}
        </div>
        {activeDealIndex !== undefined && opportunities?.[activeDealIndex] && opportunities?.[activeDealIndex]?.properties && (
          <PropertyDetailsModal
            isOpen={isOpenDetailsModal}
            toggleModal={() => setIsOpenDetailsModal(!isOpenDetailsModal)}
            property={opportunities[activeDealIndex].properties!.find((p) => p.id === selectedPropertyId)}
            details={propertyDetails}
          />
        )}
      </div>
      <div className="deals-container flex w-full flex-col gap-5 md:hidden">
        <DealsMobile />
      </div>
    </>
  );
};

export default DealsV2;
