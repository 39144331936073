/**
 * Global Pricing Rule
 * 
 * Each rule caters to one or more asset classes, and every asset class is catered to by only one rule.
 * 
 * The rule is used to calculate:
 * 1. The MAOB (Maximum Allowable Outstanding Balance) as lesser or greater of:
 *      - AAO (Available Asset Value)
 *      - AIV (Asset’s Indexed Value)
 *      - UPB (Unpaid Principal Balance)
 *    based on a threshold of the LTV (Loan-to-Value) ratio
 * 2. Offers to the Lenders at various discounts on the MAOB, and timeframes
 * 3. MAOS (Maximum Allowable Outstanding Share) based on a predefined rate of Profit Margin
 */
export interface PricingRule {
  id: string;
  assetClasses: string[]; // Unique set of asset classes (Empty array implies "Default Rule")
  maobCalculation: {
    ltvThreshold: number; // Loan to Value Threshold Percentage
    whenLess: MAOBCalculation; // When LTV is less than the threshold
    whenGreaterOrEqual: MAOBCalculation; // When LTV is greater than or equal to the threshold
  };
  offers: SellerOffer[];
  maosCalculation: MAOSCalculation;
}

export interface MAOBCalculation {
  selectLesserOf: boolean; // Select the lesser (when `true`) or greater (when `false`) of the AAO, AIV, and UPB values
  aaoPerc: number; // AAO Percentage
  aivPerc: number; // AIV Percentage
  upbPerc: number; // UPB Percentage
}

export interface SellerOffer {
  daysToClose: number; // Days to Close
  discountPerc: number; // Discount Percentage
  daysForDueDiligence: number; // Days for Due Diligence
}

export interface MAOSCalculation {
  profitMargin: number; // Profit Margin Percentage
}

export interface PricingRuleBaseVersion {
  id: string;
  name: string;
}

export const getPricingRuleBaseVersions = async (): Promise<PricingRuleBaseVersion[]> => {
  // the following is a dummy implementation
  return [
    { id: '1', name: 'Version 1' },
    { id: '2', name: 'Version 2' },
    { id: '3', name: 'Version 3' },
  ];
};

export const getPricingRules = async (version?: string): Promise<PricingRule[]> => {
  console.log(version);
  // the following is a dummy implementation
  return [
    {
      id: "rule-1",
      assetClasses: ["Residential", "Commercial"],
      maobCalculation: {
        ltvThreshold: 0.45,
        whenLess: {
          selectLesserOf: true,
          aaoPerc: 0.8,
          aivPerc: 0.85,
          upbPerc: 0.9,
        },
        whenGreaterOrEqual: {
          selectLesserOf: false,
          aaoPerc: 0.75,
          aivPerc: 0.7,
          upbPerc: 0.65,
        },
      },
      offers: [
        {
          daysToClose: 14,
          discountPerc: 0.15,
          daysForDueDiligence: 7,
        },
        {
          daysToClose: 21,
          discountPerc: 0.1,
          daysForDueDiligence: 10,
        },
      ],
      maosCalculation: {
        profitMargin: 0.2,
      },
    },
    {
      id: "rule-2",
      // Empty array => "Default Rule"
      assetClasses: [],
      maobCalculation: {
        ltvThreshold: 0.55,
        whenLess: {
          selectLesserOf: false,
          aaoPerc: 0.78,
          aivPerc: 0.82,
          upbPerc: 0.8,
        },
        whenGreaterOrEqual: {
          selectLesserOf: true,
          aaoPerc: 0.7,
          aivPerc: 0.65,
          upbPerc: 0.75,
        },
      },
      offers: [
        {
          daysToClose: 30,
          discountPerc: 0.2,
          daysForDueDiligence: 15,
        },
        {
          daysToClose: 45,
          discountPerc: 0.25,
          daysForDueDiligence: 20,
        },
      ],
      maosCalculation: {
        profitMargin: 0.25,
      },
    },
  ];
};

export const updatePricingRules = async (rules: PricingRule[]): Promise<PricingRule[]> => {
  // the following is a dummy implementation

  // Rule ID is required
  if (rules.find(rule => !rule.id)) {
    throw new Error('Rule ID is required'); // 400 Bad Request
  }

  const existingRules = await getPricingRules();

  // Asset Classes should be unique
  const assetClassesToIdMap = new Map<string, string>();
  existingRules.forEach(({ id, assetClasses }) => {
    assetClasses.forEach(assetClass => {
      if (assetClassesToIdMap.has(assetClass)) {
        throw new Error(`Asset Classes should be unique! Rules with IDs '${id}' and '${assetClassesToIdMap.get(assetClass)}' share the same '${assetClass}' Asset Class`); // 400 Bad Request
      }
      assetClassesToIdMap.set(assetClass, id);
    });
  });
  // check with other existing rules
  existingRules.filter(existingRule => !rules.find(updatingRule => updatingRule.id === existingRule.id))
    .forEach(({ id, assetClasses }) => {
      assetClasses.forEach(assetClass => {
        if (assetClassesToIdMap.has(assetClass)) {
          throw new Error(`Asset Classes should be unique! Rules with ID '${assetClassesToIdMap.get(assetClass)}' uses '${assetClass}' Asset Class already used by existing rule with ID '${id}'`); // 400 Bad Request
        }
        assetClassesToIdMap.set(assetClass, id);
      });
    });

  // Rule ID should exist
  const nonExistentRule = rules.find(updatingRule => !existingRules.find(existingRule => existingRule.id === updatingRule.id));
  if (nonExistentRule) {
    throw new Error(`Rule with id ${nonExistentRule.id} not found`); // 400 Bad Request
  }
  return rules;
};

export const addPricingRules = async (rules: PricingRule[]): Promise<PricingRule[]> => {
  // the following is a dummy implementation
  if (rules.find(rule => !!rule.id)) {
    throw new Error('Rule ID should be empty for new rules'); // 400 Bad Request
  }
  return rules;
};

export const deletePricingRule = async (id: string): Promise<PricingRule> => {
  // the following is a dummy implementation
  const deletedRule = (await getPricingRules()).find(rule => rule.id === id);
  if (!deletedRule) {
    throw new Error(`Rule with id ${id} not found`); // 400 Bad Request
  }
  return deletedRule;
};
