import { SVGProps } from 'react';

interface UnCircleCheckIconProps extends SVGProps<SVGSVGElement> {
  width?: number | string;  // Optional width, can be number or string
  height?: number | string; // Optional height, can be number or string
  color?: string;           // Optional color prop
}

const UnCircleCheckIcon = ({ width = 15, height = 15, color = 'black', ...props }: UnCircleCheckIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15 15"  // Add the viewBox to control scaling
    fill="none"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M.877 7.5a6.623 6.623 0 1 1 13.246 0 6.623 6.623 0 0 1-13.246 0ZM7.5 1.827a5.673 5.673 0 1 0 0 11.346 5.673 5.673 0 0 0 0-11.346Zm2.354 3.32a.5.5 0 0 1 0 .707L8.207 7.5l1.647 1.646a.5.5 0 0 1-.708.708L7.5 8.207 5.854 9.854a.5.5 0 0 1-.708-.708L6.793 7.5 5.146 5.854a.5.5 0 0 1 .708-.708L7.5 6.793l1.646-1.647a.5.5 0 0 1 .708 0Z"
      clipRule="evenodd"
    />
  </svg>
);

export default UnCircleCheckIcon;
