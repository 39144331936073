import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  RuleState,
  defaultData,
  FieldType,
  RuleInput,
  emptyRuleInput,
} from "../models/GlobalPricingRuleEngineData";
import RuleEngine from "./RuleEngine";
import { inputWizardBox, steps } from "./DialogSteps";
import WizardBox from "./WizardBox";
import FinalTableBox from "./FinalTableBox";
import ConfirmDialog from "./ConfirmDialog";

function getDefaultData(): RuleState {
  return defaultData;
}

export interface Rule {
  id: string;
  data: RuleState;
}

const GlobalPricingSettingsScreen = forwardRef((_props, ref) => {
  const [rule, setRule] = useState<Rule[]>([
    {
      id: '1',
      data: defaultData
    }
  ]);
  const [tempRule, setTempRule] = useState<Rule | null>(null);
  const [tempRuleInput, setTempRuleInput] = useState<RuleInput>(emptyRuleInput)

  //states for dialog box
  const [editRuleId, setEditRuleId] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [openWizard, setOpenWizard] = useState<boolean>(false);
  const [openInputWizard, setOpenInputWizard] = useState<boolean>(false);
  const [openFinalTable, setOpenFinalTable] = useState<boolean>(false);
  const [showConfirmBox, setShowConfirmBox] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<string>("");

  useEffect(() => {
    console.log("Rule Engine State:", rule);
  }, [rule]);



  const handleAddNewRule = () => {
    const newRule: Rule = {
      id: Date.now().toString(),
      data: getDefaultData(),
    };
    addRuleToEngine(newRule);
  };

  const handleAddWithWizard = () => {
    const newTempRule: Rule = {
      id: `temp-${Date.now()}`,
      data: getDefaultData(),
    };
    setTempRule(newTempRule);
    setCurrentStep(0);
    setOpenWizard(true);
  };

  useImperativeHandle(ref, () => ({
    handleAddNewRule: () => {handleAddNewRule()},
    handleAddWithWizard: () => {handleAddWithWizard()},
  }));

  const addRuleToEngine = (rule: Rule) => {
    setRule((prev) => [...prev, rule]);
  };

  const inPlaceEditing = (id: string, field: FieldType, newValue: any) => {
    setRule((prev) =>
      prev.map((rule) =>
        rule.id === id
          ? {
            ...rule,
            data: {
              ...rule.data,
              ...(field === FieldType.ASSET_CLASS && {
                assetClass: newValue,
              }),
              ...(field === FieldType.LTV_THRESHOLD && {
                ltvThreshold: newValue,
              }),
              ...(field === FieldType.LOW_RISK_PROPERTY_TABLE_OPTION && {
                lowRiskPropertyTable: {
                  ...rule.data.lowRiskPropertyTable,
                  option: newValue,
                },
              }),
              ...(field === FieldType.LOW_RISK_PROPERTY_TABLE_DATA && {
                lowRiskPropertyTable: {
                  ...rule.data.lowRiskPropertyTable,
                  tableData: newValue,
                },
              }),
              ...(field === FieldType.HIGH_RISK_PROPERTY_TABLE_OPTION && {
                highRiskPropertyTable: {
                  ...rule.data.highRiskPropertyTable,
                  option: newValue,
                },
              }),
              ...(field === FieldType.HIGH_RISK_PROPERTY_TABLE_DATA && {
                highRiskPropertyTable: {
                  ...rule.data.highRiskPropertyTable,
                  tableData: newValue,
                },
              }),
              ...(field === FieldType.PROFIT_MARGIN_VALUE && {
                profitMarginValue: newValue,
              }),
              ...(field === FieldType.MULTIPLE_OFFER_TABLE && {
                offers: newValue,
              }),
            },
          }
          : rule
      )
    );
  };

  // const inPlaceInputEditing = (id: string, newValue:any) => {
  //   setRule((prev)=>
  //     prev.map((rule)=> 
  //       rule.id === id ? {
  //         ...rule,
  //         input: newValue
  //       }:rule
  //     )
  //   )
  // }

  // Handler for Next Button in DialogBox
  const handleNextForWizardBox = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      //for adding new rule using wizard
      if (editRuleId === null) {
        addRuleToEngine(tempRule!);
      } else {
        //for editing rule using wizard
        setRule((prev) =>
          prev.map((rule) =>
            rule.id === editRuleId && tempRule?.data
              ? {
                ...rule,
                data: tempRule?.data,
              }
              : rule
          )
        );
      }
      setTempRule(null);
      setOpenWizard(false);
    }
  };

  // Handler for Previous Button in DialogBox
  const handlePreviousForWizardBox = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Handler for input change in dialog box
  const handleInputChangeForWizard = (newValue: any) => {
    if (tempRule) {
      setTempRule((prev) => {
        if (!prev) return null;
        return {
          ...tempRule,
          data: newValue,
        };
      });
    }
  };

  // Handler for deleting rule
  const handleDeleteRule = (id: string) => {
    setDeletedId(id);
    setShowConfirmBox(true);

  };

  const handleCopyRule = (id: string) => {
    const ruleToCopy = rule.find((rule) => rule.id === id);
    if (!ruleToCopy) return;

    const newRule = {
      ...ruleToCopy,
      id: Date.now().toString(),
    };

    addRuleToEngine(newRule);
  };

  const handleEditRule = (id: string) => {
    const temp = rule.find((rule) => rule.id === id);
    if (temp) {
      setTempRule(temp);
      setOpenWizard(true);
      setEditRuleId(id);
    }
  };

  const handlePlayRule = (id: string) => {
    console.log("handle Play is clicked for rule id: " + id);
    const temp = rule.find((rule) => rule.id === id);
    if (temp) {
      setTempRule(temp);
      setOpenFinalTable(true);
    }
  }

  const handleOnNextForInputWizardBox = () => {
    setOpenInputWizard(false);
    console.log(tempRuleInput)
    // setRule((prev) =>
    //   prev.map((rule) =>
    //     rule.id === tempRule?.id
    //       ? {
    //           ...rule,
    //           input: tempRuleInput
    //         }
    //       : rule
    //   )
    // );
    // setTempRule(null)
    // setTempRuleInput(emptyRuleInput)
    // console.log(tempRule)
    setOpenFinalTable(true);
  }
  const handleFinalTableClosing = () => {
    setOpenInputWizard(false);
    setOpenFinalTable(false);
    setTempRule(null)
    setTempRuleInput(emptyRuleInput)
  }

  return (
    <div className="h-full w-full">
      <div className="mb-5 ">
        <RuleEngine
          rules={rule}
          inPlaceEditing={inPlaceEditing}
          handleDelete={handleDeleteRule}
          handleCopy={handleCopyRule}
          handleEdit={handleEditRule}
          handlePlay={handlePlayRule}
        //inPlaceInputEditing={inPlaceInputEditing}
        />
      </div>
      <ConfirmDialog
        open={showConfirmBox}
        title="Please Confirm"
        message="Are you sure?"
        handleClose={() => setShowConfirmBox(false)}
        handleConfirm={() => {
          const updateRule = rule.filter((rule) => rule.id !== deletedId);
          setRule(updateRule);
          setShowConfirmBox(false);
        }}
      />
      {/* DialogBox for Step Content */}
      {openWizard && (
        <WizardBox
          onCancel={() => setOpenWizard(false)}
          onRevert={() => { }}
          steps={6}
          currentStep={steps[currentStep].step}
          onPrevious={currentStep > 0 ? handlePreviousForWizardBox : undefined}
          onNext={handleNextForWizardBox}
          heading={steps[currentStep].title}
        >
          {steps[currentStep].content(tempRule?.data!, (newValue: RuleState) => {
            if (openWizard) {
              handleInputChangeForWizard(newValue);
            }
          })}
        </WizardBox>
      )}

      {openInputWizard && (
        <WizardBox
          steps={steps.length}
          currentStep={steps[currentStep].step}
          onCancel={() => setOpenInputWizard(false)}
          onRevert={() => { }}
          onNext={handleOnNextForInputWizardBox} heading="">
          {
            inputWizardBox.content(tempRuleInput, (newValue: RuleInput) => {
              setTempRuleInput(newValue)
            })
          }
        </WizardBox>
      )}
      {openFinalTable && (
        <FinalTableBox inputValues={tempRuleInput} rule={tempRule!} onClose={handleFinalTableClosing} />
      )}
    </div>
  );
});

export default GlobalPricingSettingsScreen;
