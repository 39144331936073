import { Check, X } from 'lucide-react';
import React from 'react';

interface ConfirmDialogProps {
    open: boolean;
    title?: string;
    message: string;
    handleClose: () => void;
    handleConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, title, message, handleClose, handleConfirm }) => {
    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 max-h-[90vh] overflow-y-auto">
                {title && <h2 className="text-xl font-semibold mb-4">{title}</h2>}
                <div
                    className="text-lg text-gray-700 mb-6"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
                <div className="flex justify-end space-x-4">

                    <button
                        className="flex items-center gap-2 px-4 py-2 text-gray-700 bg-gray-200 rounded-md shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={handleClose} >
                        <X className="w-4 h-4" />
                        Cancel
                    </button>

                    <button
                        className="flex items-center gap-2 px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={handleConfirm} >
                        <Check className="w-4 h-4" />
                        Yes
                    </button>

                </div>
            </div>
        </div>
    );
};

export default ConfirmDialog;