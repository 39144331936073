import React from 'react';
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from '../ui/pagination';
import { cn } from '@/lib/utils';


interface PaginationComponentProps {
    page: number;
    totalPages: number;
    setPage: (page: number) => void;
    numVisibleLinks?: number;
    isDisabledNext: boolean;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({ page, totalPages, setPage, isDisabledNext, numVisibleLinks = 9 }) => {
    // console.log('>>>>> PaginationComponent ' , page, totalPages, isDisabledNext, numVisibleLinks );
    
    return (
        <Pagination>
            <PaginationContent>
                <PaginationItem>
                    <PaginationPrevious
                        onClick={() => page - 1 > 0 ? setPage(page - 1) : null}
                        className={cn("cursor-pointer", page == 1 ? "cursor-not-allowed opacity-50" : "")} />
                </PaginationItem>
                <>
                    {totalPages <= numVisibleLinks ? (
                        Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
                            <PaginationItem key={pageNumber}>
                                <PaginationLink
                                    isActive={page === pageNumber}
                                    onClick={() => setPage(pageNumber)}
                                    className="cursor-pointer"
                                >
                                    {pageNumber}
                                </PaginationLink>
                            </PaginationItem>
                        ))
                    ) : (
                        <>
                            <PaginationItem>
                                <PaginationLink
                                    isActive={page === 1}
                                    onClick={() => setPage(1)}
                                    className="cursor-pointer"
                                >
                                    1
                                </PaginationLink>
                            </PaginationItem>
                            {page > (Math.floor(numVisibleLinks / 2) + 2) && <PaginationEllipsis />}
                            {Array.from({ length: numVisibleLinks }, (_, i) => page - Math.floor(numVisibleLinks / 2) + i)
                                .filter(pageNumber => pageNumber > 1 && pageNumber < totalPages)
                                .map((pageNumber) => (
                                    <PaginationItem key={pageNumber}>
                                        <PaginationLink
                                            isActive={page === pageNumber}
                                            onClick={() => setPage(pageNumber)}
                                            className="cursor-pointer"
                                        >
                                            {pageNumber}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                            {totalPages > (page + Math.floor(numVisibleLinks / 2) + 1) && <PaginationEllipsis />}
                            {totalPages > 1 && (
                                <PaginationItem>
                                    <PaginationLink
                                        isActive={page === totalPages}
                                        onClick={() => setPage(totalPages)}
                                        className="cursor-pointer"
                                    >
                                        {totalPages}
                                    </PaginationLink>
                                </PaginationItem>
                            )}
                        </>
                    )}
                </>
                <PaginationItem>
                    <PaginationNext
                        onClick={() => !isDisabledNext ? setPage(page + 1) : null}
                        className={cn("cursor-pointer", isDisabledNext ? "cursor-not-allowed opacity-50" : "")} />
                </PaginationItem>
            </PaginationContent>
        </Pagination>
    )

};

export default PaginationComponent;