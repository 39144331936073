import { useAuth } from '@/components/AuthProvider';
import BlueCircleCheckIcon from '@/components/icons/BlueCircleCheckIcon';
import BlueCircleXIcon from '@/components/icons/BlueCircleXIcon';
import BlueFillThumbDownIcon from '@/components/icons/BlueFillThumbDownIcon';
import BlueFillThumbUpIcon from '@/components/icons/BlueFillThumbUpIcon';
import BlueThumbDownIcon from '@/components/icons/BlueThumbDownIcon';
import BlueThumbUpIcon from '@/components/icons/BlueThumbUpIcon';
import CircleCheckIcon from '@/components/icons/CircleCheckIcon';
import UnCircleCheckIcon from '@/components/icons/UnCircleCheckIcon';
import { Checkbox } from '@/components/ui/checkbox';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import {
  CREATE_MODIFY_THIRD_PARTY_API_URL,
  GET_THIRD_PARTY_API_URL,
  MODIFY_ORGANIZATIONS_API_URL
} from '@/config/config';
import { entryType, partyType, permissionsArr } from '@/constants/ThirdPartyConstants';
import { formatCurrencyShort } from '@/lib/utils';
import { getCurrentSession } from '@/services/authService';
import { yupResolver } from '@hookform/resolvers/yup';
import { CalendarIcon } from '@radix-ui/react-icons';
import { Pencil, SearchIcon } from 'lucide-react';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import CircleChart from '../../charts/CircleChart';
import SemiCircleChart from '../../charts/SemiCircleChart';
import CarouselComponent from '../../common/CarouselComponent';
import EditableField, { UpdatedOpportunityField } from '../../common/EditableField';
import NumberDisplay from '../../common/NumberDisplay';
import ProgressComponent from '../../common/ProgressComponent';
import SliderComponent from '../../common/SliderComponent';
import LocationDotsMap from '../../deals/LocationDotsMap';
import CarouselCardActionMenu from './CarouselCardActionMenu';
import './DealsDesktop.css';
import { DEAL_STATUS } from './enum';
import { useOpportunityStatusUpdate, useUpdateOpportunityPricing } from './hooks';
import { UpdateOpportunityPricingInput, UpdateOpportunityStatusInput, UpdateOpportunityStatusResponse } from './types'; //DealStatusType,
import { OpportunityViewModel } from './view-models/OpportunityViewModel';

interface DealsDesktopProps {
  initialOpportunities: OpportunityViewModel[];
  onSlideChange?: (index: number) => void;
  hideProgressBar?: () => void;
  showProgressBar?: () => void;
  onUpdateValue?: (opportunityId: string, values: Record<string, number>) => void;
}

export interface OpportunityCalculations {
  discountPercentMAOB: number;
  adjustOpportunityMAOB: number;
  opportunityProperties: Object;
  profit: number;
  opportunityNetProfitMargin: number;
  percentage: number;
}

type Inputs = {
  address?: any;
  city?: string;
  party_name?: string;
  phone?: string;
  state?: string;
  zipCode?: string;
  permissions?: any;
  dbas?: any;
  enity_type?: any;
  party_type?: any;
  addresses?: any;
  contactInfo?: any;
  entity_registration_date?: any;
  other_names?: any;
};

interface OptionType {
  label?: string;
  value?: string;
}

interface Address {
  address: string;
  // addressLine2: string;
  city: string;
  label: string;
  primary: boolean;
  state: string;
  zipCode: string;
}
interface ContactInfo {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  value: string;
  type?: any;
  primary: boolean;
  label: string;
}

const DealsDesktop: React.FC<DealsDesktopProps> = ({ initialOpportunities, onSlideChange, showProgressBar, hideProgressBar, onUpdateValue }) => {
  const colorList = [
    '#ceecf9',
    '#1d2634',
    '#08a0e2',
    '#d2d4d6',
    '#84d0f1',
    '#dddddd',
    '#0000FF', // Blue
    '#FFFF00', // Yellow
    '#FFA500', // Orange
    '#FF0000', // Red
    '#00FF00', // Green
    '#800080', // Purple
    '#00FFFF', // Cyan
    '#FF00FF', // Magenta
    '#00FF00', // Lime
    '#FFC0CB', // Pink
    '#A52A2A', // Brown
    '#808080', // Gray
    '#808000', // Olive
  ];

  const validationSchema = Yup.object().shape({
    party_name: Yup.string().required('Third Party is required'),
    // email: Yup.string().required('Email is required').email('Email is invalid'),
    permissions: Yup.array()
      .min(1, 'At least one Permission must be selected')
      .of(
        Yup.object().shape({
          value: Yup.string().required('Permission is required'),
          label: Yup.string().required('Permission is required'),
        }),
      ),
  });

  const {
    register,
    // control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    // mode: 'all',
    defaultValues: {
      party_name: '',
      // email: '',
      permissions: [],
    },
    resolver: yupResolver(validationSchema),
  });
  const MAX_DISCOUNT = 0.3; // 30%

  const activeIcons: { [key: string]: React.ReactNode } = {
    [DEAL_STATUS.Rejected]: <BlueFillThumbDownIcon />,
    [DEAL_STATUS.InReview]: <BlueFillThumbUpIcon />,
  };

  const inactiveIcons: { [key: string]: React.ReactNode } = {
    [DEAL_STATUS.Rejected]: <BlueThumbDownIcon />,
    [DEAL_STATUS.InReview]: <BlueThumbUpIcon />,
  };

  const [opportunityStatuses, setOpportunityStatuses] = useState<UpdateOpportunityStatusResponse[]>([]);
  const [oppDiscountPercentMAOBMap, setOppDiscountPercentMAOBMap] = useState<Record<string, number>>({});
  const [opportunities, setOpportunities] = useState<OpportunityViewModel[]>([]);
  const [editedMAOBList, setEditedMAOBList] = useState<UpdatedOpportunityField[]>([]);
  const [existingOrg, setExistingOrg] = useState(false);
  const [createOrg, setCreateOrg] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [opportunityId, setOpportunityId] = useState('');
  const [totalProperty, setTotalProperty] = useState(0);
  const [dbasData, setDBASData] = useState<OptionType[]>([]);
  const [entryTypeData, setEntryTypeData] = useState<OptionType[]>([]);
  const [partyTypeData, setPartyTypeData] = useState<OptionType[]>([]);
  const [partyID, setPartyID] = useState('');
  const [addresses, setAddresses] = useState<Address[]>([
    {
      address: '',
      // addressLine2: '',
      city: '',
      label: '',
      primary: false,
      state: '',
      zipCode: '',
    },
  ]);
  const [contacts, setContacts] = useState<ContactInfo[]>([
    {
      label: '',
      primary: false,
      type: { value: 'email', label: 'Email' },
      value: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: ''
    },
  ]);
  const [entityRegistrationDate, setEntityRegistrationDate] = useState(new Date());
  const { mutate: updateOpportunityStatus, error: updatedStatusError, isLoading: isUpdatingOpportunityStatus } = useOpportunityStatusUpdate();
  const { mutate: updateOpportunityPricing, error: updateOpportunityPricingError, isLoading: isUpdatingOpportunityPricing } = useUpdateOpportunityPricing();

  const auth = useAuth();

  useEffect(() => {
    if (initialOpportunities) {
      setOpportunities([...initialOpportunities]);
      const initialOpportunityStatuses = initialOpportunities.map((opportunity) => ({
        status: opportunity.status,
        opportunityId: opportunity.opportunityId,
      }));

      setOpportunityStatuses(initialOpportunityStatuses);

      const initialDicountPercentMAOBMap: Record<string, number> = {};
      initialOpportunities.forEach((opportunity: any) => {
        if (oppDiscountPercentMAOBMap[opportunity?.opportunityId] === undefined) {
          initialDicountPercentMAOBMap[opportunity.opportunityId] = calculateDiscountPercentForSlider(opportunity.carouselCard.opportunityData.discountPercentMAOB);
        } else {
          initialDicountPercentMAOBMap[opportunity.opportunityId] = oppDiscountPercentMAOBMap[opportunity.opportunityId];
        }
      });

      setOppDiscountPercentMAOBMap(initialDicountPercentMAOBMap);
    }
  }, [initialOpportunities]);

  useEffect(() => {
    const error = updatedStatusError || updateOpportunityPricingError;
    if (!error) return;

    if (updatedStatusError) {
      // remove the last added opportunity status
      const updatedOpportunityStatuses = opportunityStatuses.slice(0, opportunityStatuses.length - 1);
      setOpportunityStatuses(updatedOpportunityStatuses);
    }

    toast.error('An error occurred while updating the opportunity status. Please try again later.');
    hideProgressBar && hideProgressBar();
  }, [updatedStatusError, updateOpportunityPricingError]);

  useEffect(() => {
    if (opportunities.length > 0 && !isUpdatingOpportunityStatus && !isUpdatingOpportunityPricing) {
      hideProgressBar && hideProgressBar();
    } else {
      showProgressBar && showProgressBar();
    }
  }, [isUpdatingOpportunityStatus, isUpdatingOpportunityPricing]);

  const opportunityStatusChangeHandler = (opportunity: OpportunityViewModel | any, newStatus: DEAL_STATUS): void => {
    if (!auth || !opportunity) return;

    const latestStatus = opportunity.status === newStatus ? DEAL_STATUS.Open : newStatus;
    const isConfirmed = window.confirm(`Are you sure you want to add this to ${latestStatus} items?`);

    if (!isConfirmed) return;

    const cachedOpportunityStatus = { status: latestStatus, opportunityId: opportunity.opportunityId };
    const updatedOpportunityStatuses = [...opportunityStatuses];

    // Find the index of the existing opportunity status
    const index = updatedOpportunityStatuses.findIndex((status) => status.opportunityId === cachedOpportunityStatus.opportunityId);

    if (index !== -1) {
      // Update the existing status
      updatedOpportunityStatuses[index].status = cachedOpportunityStatus.status;
    } else {
      // Add the new status
      updatedOpportunityStatuses.push(cachedOpportunityStatus);
    }

    setOpportunityStatuses(updatedOpportunityStatuses);
    const updatedOpportunity: UpdateOpportunityStatusInput = {
      opportunityId: opportunity.opportunityId,
      userId: auth.sub,
      totalProperties: opportunity.properties.length,
      newStatus: latestStatus,
    };
    setTotalProperty(opportunity.properties.length);
    updateOpportunityStatus(updatedOpportunity);
  };

  const renderThumbUpDownIcon = (opportunity: OpportunityViewModel | any, status: string): ReactNode => {
    const opportunityStatus = opportunityStatuses.find((s) => s.opportunityId === opportunity.opportunityId)?.status || opportunity.status;
    if (opportunityStatus === status) {
      return activeIcons[status];
    }

    return inactiveIcons[status];
  };

  const handleSliderValueChange = (opportunity: OpportunityViewModel | any, value: number) => {
    const { opportunityID, opportunityMAOB, opportunityMAOS } = opportunity.carouselCard.opportunityData;
    // console.log("opportunityId " + opportunityID)
    // console.log("value " + value)
    const newOppDiscountPercentMAOBMap = { ...oppDiscountPercentMAOBMap };
    newOppDiscountPercentMAOBMap[opportunityID] = value;
    setOppDiscountPercentMAOBMap(newOppDiscountPercentMAOBMap);

    const metric = calculateOpportunityMetrics(opportunityMAOB, opportunityMAOS, value);

    const updatedValues = { adjustedOpportunityMAOB: metric.adjustOpportunityMAOB };
    onUpdateValue && onUpdateValue(opportunityID, updatedValues);
  };

  const handleMenuItemClick = async (action: string, opportunity: OpportunityViewModel | any) => {
    if (!auth) {
      toast.error('User not authenticated');
      return;
    }
    // Handle the action accordingly
    switch (action) {
      case 'edit':
        // Handle edit action
        break;
      case 'save':
        const { opportunityMAOS, opportunityMAOB, opportunityLeveragesMAOS, opportunityProperties, premiumPercentMAOS, opportunityAIV, opportunityUPB, opportunityAAO } =
          opportunity.carouselCard.opportunityData;
        const metric = calculateOpportunityMetrics(opportunityMAOB, opportunityMAOS, oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0);
        // const savedSliderValue = (discountPercentMAOB * 0.333) * 100;
        console.log('opportunityProperties ... ' + JSON.stringify(opportunityProperties));
        console.log('discountPercentMAOB ... ' + metric.discountPercentMAOB);

        // console.log("new value AIV2 "+ (calcMOAB) / (opportunity.carouselCard.opportunityData.opportunityLeveragesMAOB.AIV))

        const newOpportunityMAOB = {
          AIV: Number((metric.adjustOpportunityMAOB / opportunityAIV).toFixed(2)),
          UPB: Number((metric.adjustOpportunityMAOB / opportunityUPB).toFixed(2)),
          AAO: Number((metric.adjustOpportunityMAOB / opportunityAAO).toFixed(2)),
        };

        // console.log("new value newopp " + JSON.stringify(newOpportunityMAOB));
        // console.log("new value slider "+ JSON.stringify(sliderValue))
        // console.log("new value premiumPercentMAOS " + premiumPercentMAOS)
        // console.log("new value opportunityMAOB " + Number(calcMOAB.toFixed(2)))
        // console.log("new value opportunityMAOS " + opportunityMAOS)
        // console.log("new value opportunityCOCR " + newPercentage)
        // console.log("new value oppDiscountPercentMAOBMap[opportunity.opportunityId] " + JSON.stringify(oppDiscountPercentMAOBMap[opportunity.opportunityId]))
        // console.log("new value opportunityLeveragesMAOB " + JSON.stringify(newOpportunityMAOB))
        // console.log("new value opportunityNetProfitMargin " + opportunityNetProfitMargin)

        // Handle save action
        const input: UpdateOpportunityPricingInput = {
          dicountPercentMAOB: metric.discountPercentMAOB,
          premiumPercentMAOS,
          adjustedOpportunityMAOB: metric.adjustOpportunityMAOB,
          adjustedOpportunityMAOS: opportunityMAOS,
          opportunityCOCR: metric.percentage,
          opportunityLeveragesMAOS,
          opportunityLeveragesMAOB: newOpportunityMAOB,
          opportunityNetProfitMargin: metric.opportunityNetProfitMargin,
        };

        updateOpportunityPricing({
          input,
          opportunityID: opportunity.opportunityId,
          totalProperties: opportunity.properties.length,
          cognitoID: auth.sub,
        });
        setTotalProperty(opportunity.properties.length);
        break;
      case 'pricing':
        // Handle pricing action
        break;
      case 'existing-organization':
        // Handle existing organization action
        setExistingOrg(true);
        document.body.style.pointerEvents = 'auto';
        break;
      case 'create-organization':
        // Handle create organization action
        setOpportunityId(opportunity.opportunityId);
        setTotalProperty(opportunity.properties.length);
        setValue('party_name', opportunity.lenderName);
        setCreateOrg(true);
        document.body.style.pointerEvents = 'auto';
        break;
      case 'modify-organization':
        document.body.style.pointerEvents = 'auto';
        try {
          const response = await fetch(`${GET_THIRD_PARTY_API_URL}?partyID=${opportunity.opportunityData.organizationID}`);

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const resp = await response.json();
          const bodyData = resp.body;
          setOpportunityId(opportunity.opportunityId);
          setTotalProperty(opportunity.properties.length);
          setValue('party_name', bodyData.party_name);
          setCreateOrg(true);
          const typeMapping: any = {
            email: { label: 'Email', value: 'email' },
            phone: { label: 'Phone', value: 'phone' },
          };
          const contactInfoOutput = bodyData.contactInfo.map((contact: any) => ({
            ...contact,
            type: typeMapping[contact?.type] || { label: 'Email', value: 'email' },
          }));

          setPartyID(bodyData.partyID);
          setAddresses(bodyData.addresses);
          setContacts(contactInfoOutput);
          setEntityRegistrationDate(bodyData.entity_registration_date);
          const filteredPermissions = permissionsArr.filter((permission: { value: string }) => bodyData.permissions.includes(permission.value));
          const formattedDbas = bodyData.dbas.map((dba: string) => ({
            label: dba,
            value: dba,
          }));
          setValue('permissions', filteredPermissions);
          setDBASData(formattedDbas);
          setEntryTypeData(entryType.filter((entry: { value: string }) => bodyData.enity_type.includes(entry.value)));
          setPartyTypeData(partyType.filter((party: { value: string }) => bodyData.party_type.includes(party.value)));
          // contanctType
        } catch (error) {
          setIsSubmitting(false);
          console.error('Error submitting form:', error);
        }
        break;
      default:
        break;
    }
  };

  const handleEditMAOBClick = (e: React.MouseEvent, opportunity: OpportunityViewModel | any, field: string, value: number) => {
    e.preventDefault();
    e.stopPropagation(); // Stop event propagation
    const editedMAOBItem = new UpdatedOpportunityField(opportunity.opportunityId, field, value);
    const newEditedMAOBList = [...editedMAOBList];
    newEditedMAOBList.push(editedMAOBItem);
    setEditedMAOBList(newEditedMAOBList);
  };

  const handleSaveMAOBClick = (newItem: UpdatedOpportunityField, opportunity: OpportunityViewModel | any) => {
    const editedMAOB = editedMAOBList.find((item) => item.id === newItem.id);
    if (!editedMAOB) return;

    const newAdjustOpportunityMAOB = newItem.value;

    const { adjustedOpportunityMAOB, opportunityMAOB } = opportunity.carouselCard.opportunityData;

    let updatedValues: Record<string, number> = {};

    if (newAdjustOpportunityMAOB >= opportunityMAOB) {
      updatedValues['discountPercentMAOB'] = (newAdjustOpportunityMAOB / opportunityMAOB - 1) * -1;
      // console.log("here " + updatedValues["discountPercentMAOB"])
    } else {
      updatedValues['discountPercentMAOB'] = 1 - newAdjustOpportunityMAOB / opportunityMAOB;
      // console.log("here " + updatedValues["discountPercentMAOB"])
    }

    // Update opportunities
    if (adjustedOpportunityMAOB !== newAdjustOpportunityMAOB) {
      updatedValues['adjustedOpportunityMAOB'] = newAdjustOpportunityMAOB;
      // console.log("here adj " + updatedValues["adjustedOpportunityMAOB"])
    }

    const sliderDiscountPercentMAOBMap = { ...oppDiscountPercentMAOBMap };
    sliderDiscountPercentMAOBMap[newItem.id] = calculateDiscountPercentForSlider(updatedValues['discountPercentMAOB']);
    setOppDiscountPercentMAOBMap(sliderDiscountPercentMAOBMap);

    onUpdateValue && onUpdateValue(newItem.id, updatedValues);

    // Save the edited values
    setEditedMAOBList(editedMAOBList.filter((item) => item.id !== newItem.id));
  };

  const handleDiscardMAOBClick = (opportunity: OpportunityViewModel) => {
    setEditedMAOBList(editedMAOBList.filter((item) => item.id !== opportunity.opportunityId));
  };

  const calculateDiscountPercentForSlider = (discountPercentMAOB: number): number => {
    // console.log("discountPercent here ... " + (1 - (discountPercentMAOB / MAX_DISCOUNT)) * 100)
    return (1 - discountPercentMAOB / MAX_DISCOUNT) * 100;
  };

  const calculateOpportunityMetrics = (opportunityMAOB: number, opportunityMAOS: number, sliderDiscountPercent: number): OpportunityCalculations => {
    const sliderValue = Number(sliderDiscountPercent / 100);
    let discountPercentMAOB;
    if (sliderValue > 1) {
      discountPercentMAOB = (1 - sliderValue) * MAX_DISCOUNT;
    } else {
      discountPercentMAOB = Math.abs((1 - sliderValue) * MAX_DISCOUNT);
    }
    // console.log("opportunityMAOB here ... " + opportunityMAOB)
    const adjustedMAOB = opportunityMAOB * (1 - discountPercentMAOB); // Bluebutton $
    if (discountPercentMAOB < 0) {
    }
    const profit = opportunityMAOS - adjustedMAOB;
    const opportunityNetProfitMargin = Number((profit / adjustedMAOB).toFixed(2)); // saved value
    const percentage = Number(((profit / (adjustedMAOB * 0.2)) * 100).toFixed(2)); // 3260000 profit / 1670000*0.2 = 33400
    const calcMOAB = opportunityMAOB * (1 - MAX_DISCOUNT + sliderValue * MAX_DISCOUNT); //

    // console.log("profit " + profit)
    // console.log("percentage " + percentage)
    // console.log("calcMOAB " + calcMOAB)
    // console.log("calculateOpportunityMetrics " + organizationId + " " + " " + " sliderDiscountPercent =" + sliderDiscountPercent + " discountPercentMAOB " + discountPercentMAOB + " adjustOpportunityMAOB =" + calcMOAB)
    // console.log("discountPercentMAOB " + discountPercentMAOB)
    // console.log("adjustedMAOB "+ adjustedMAOB)
    // console.log("opportunityMAOS "+ opportunityMAOS)
    // console.log("opportunityMAOB " + opportunityMAOB)
    // console.log("profit "+ profit)
    // console.log("adjustOpportunityMAOB "+ calcMOAB)
    // console.log("percentage "+ percentage)

    return {
      discountPercentMAOB: Number(discountPercentMAOB),
      opportunityProperties: {},
      adjustOpportunityMAOB: calcMOAB,
      profit,
      opportunityNetProfitMargin,
      percentage,
    };
  };

  const handleSubmitOfferClick = (e: React.MouseEvent, opportunity: OpportunityViewModel | any) => {
    e.preventDefault();
    if (!confirm('Are you sure you want to submit the offer?')) {
      return;
    }

    const updatedOpportunity: UpdateOpportunityStatusInput = {
      opportunityId: opportunity.opportunityId,
      userId: auth!.sub,
      totalProperties: opportunity.properties.length,
      newStatus: DEAL_STATUS.OfferMade,
    };
    setTotalProperty(opportunity.properties.length);
    updateOpportunityStatus(updatedOpportunity);
  };

  const handleCreateOrg = async (data: Inputs) => {
    setIsSubmitting(true);
    const currentSession = await getCurrentSession();
    const cognitoID = currentSession?.idToken?.payload['cognito:username'];

    try {
      const contactData = contacts.map((cont: any) => {
        return { ...cont, type: cont?.type?.value };
      });
      const formattedData = {
        dbas: dbasData?.map((e: any) => e.value) || [], 
        enity_type: entryTypeData?.map((e: any) => e.value) || [],
        party_type: partyTypeData?.map((e: any) => e.value) || [],
        party_name: data.party_name,
        addresses,
        contactInfo: contactData,
        entity_registration_date: moment(entityRegistrationDate).format('MM/DD/yyyy'),
        other_names: [],
        permissions: data.permissions.map((permission: any) => permission.value), // sending only the 'value' part of the selected fruits
      };

      const response = await fetch(
        partyID ? `${CREATE_MODIFY_THIRD_PARTY_API_URL}?cognitoID=${cognitoID}&input_id=${partyID}` : `${CREATE_MODIFY_THIRD_PARTY_API_URL}?cognitoID=${cognitoID}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formattedData), // Convert JavaScript object to JSON string
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // if (partyID === '') {
      const responseData = await response.json();
      if (responseData.statusCode === 200) {
        const orgID = responseData.partyID;
        try {
          const response = await fetch(
            `${MODIFY_ORGANIZATIONS_API_URL}?new_organization_id=${orgID}&opportunity_id=${opportunityId}&cognitoID=${cognitoID}&totalProperties=${totalProperty}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const modifyResp = await response.json();
          if (modifyResp.statusCode === 200) {
            toast.success(responseData?.message);
            reset();
            setOpportunityId('');
            setCreateOrg(false);
            setIsSubmitting(false);
          }
        } catch (error) {
          setIsSubmitting(false);
          console.error('Error submitting form:', error);
        }
      }
      // } else {
      //   const responseData = await response.json();
      //   toast.success(responseData?.message);
      //   reset();
      //   setOpportunityId('');
      //   setCreateOrg(false);
      //   setIsSubmitting(false);
      // }
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error submitting form:', error);
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (data: any) => {
    await handleCreateOrg(data);
  };

  const addAddress = () => {
    setAddresses([
      ...addresses,
      {
        address: '',
        // addressLine2: '',
        city: '',
        label: '',
        primary: false,
        state: '',
        zipCode: '',
      },
    ]);
  };
  const removeAddress = (index: number) => {
    if (addresses.length > 1) {
      setAddresses(addresses.filter((_, i) => i !== index));
    }
  };

  const addContact = () => {
    setContacts([
      ...contacts,
      {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        label: '',
        primary: false,
        type: { value: 'email', label: 'Email' },
        value: '',
      },
    ]);
  };
  const removeContact = (index: number) => {
    if (contacts.length > 1) {
      setContacts(contacts.filter((_, i) => i !== index));
    }
  };

  const onSelectChange = (selectedOption: OptionType[], type: string) => {
    if (type === 'dbas') {
      setDBASData(selectedOption);
    }
    if (type === 'enity_type') {
      setEntryTypeData(selectedOption);
    }
    if (type === 'party_type') {
      setPartyTypeData(selectedOption);
    }
  };

  const onLenderNameClicked = (e:any, opportunity:OpportunityViewModel)=>{
    e.preventDefault();
    if(opportunity.opportunityData.organizationID == '') {
      handleMenuItemClick('create-organization', opportunity)
    }
    else {
      handleMenuItemClick('modify-organization', opportunity)
    }
  }

  const handleAddressChange = (index: number, field: keyof Address, value: string | boolean) => {
    const updatedAddresses = [...addresses];
    if (field === 'primary' && value === true) {
      updatedAddresses.forEach((address) => (address.primary = false));
    }
    updatedAddresses[index][field] = value as string & boolean;
    setAddresses(updatedAddresses);
  };
  const handleContactChange = (index: number, field: keyof ContactInfo, value: string | boolean) => {
    const updatedContacts = [...contacts];
    if (field === 'primary' && value === true) {
      updatedContacts.forEach((contact) => (contact.primary = false));
    }
    updatedContacts[index][field] = value as string & boolean & object & any;
    setContacts(updatedContacts);
  };

  return (
    <>
      <div className="xl:h-[690px] 2xl:h-[660px] ">
        <CarouselComponent onSlideChange={onSlideChange}>
          {opportunities.map((opportunity: any, index: number) => {
            const {
              totalUPB,
              locations,
              lenderName,
              totalTaxLiens,
              totalVolume,
              totalAmount,
              countOfAssetClassMix,
              countOfBorrower,
              TotalDealFirstLienPositionOriginationAmount,
              totalProperties,
              opportunityData,
            } = opportunity.carouselCard;
            const assetClassMixKeys = Object.keys(countOfAssetClassMix);
            const colorMapAssetClassMix = assetClassMixKeys.map((key, index) => ({
              color: colorList[index % colorList.length],
              description: key,
            }));
            // setTotalProperty(opportunity.carouselCard.totalProperties)
            // console.log("opportunityData " , opportunityData.opportunityID)

            // Transform countOfBorrower to the expected array format
            const borrowerArray = Object.entries(countOfBorrower).map(([name, count]) => ({
              name,
              count,
            }));

            const borrowerNames = borrowerArray.map((borrower) => borrower.name);
            const colorMapBorrower = borrowerNames.map((key, index) => ({
              color: colorList[index % colorList.length],
              description: key,
            }));

            const reformattedCountOfBorrower = borrowerArray.map((borrower) => borrower.count);

            // console.log(" " + JSON.stringify(opportunities))
            const countOfAssetClassMixValues = Object.values(countOfAssetClassMix);

            const { opportunityMAOS, opportunityMAOB, opportunityAIV, opportunityUPB, opportunityAAO, adjustedOpportunityMAOB } = opportunityData;

            const { percentage, adjustOpportunityMAOB } = calculateOpportunityMetrics(opportunityMAOB, opportunityMAOS, oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0);

            // const sliderValue = (oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0) / 100;
            // const profit = opportunityMAOS - adjustedMAOB;
            // const percentage = ((profit / (adjustedMAOB * 0.2)) * 100).toFixed(0);
            // console.log("opportunity.opportunityId " + opportunity.opportunityId)
            const blueButtonValue = adjustOpportunityMAOB;
            const editedMAOB = editedMAOBList.find((item) => item.id === opportunity.opportunityId);

            return (
              <div className="carousel-slide px-5 pb-5 pt-3 2xl:px-8" key={`opportunity-${opportunity.opportunityId}`}>
                <div className="flex w-full justify-start">
                  <div className="flex min-h-8 min-w-8 items-center justify-center rounded-full border text-stone-600">{index + 1}</div>
                  {/* <p>{opportunityData.opportunityID}</p> */}
                </div>
                <div className="flex">
                  <div className="mr-5 mt-5 w-[32%] 2xl:w-[30%]">
                    <div className="flex w-full">
                      <div className="mr-3 w-1/2">
                        <p className="thin-title mb-5 text-xs uppercase">
                          <span className="">TOTAL ORIGINATION AMOUNT</span>
                        </p>
                      </div>
                      <div className="w-1/2">
                        <p className="thin-title mb-5 text-xs uppercase">
                          UNPAID PRINCIPAL
                          <br />
                          <span className="whitespace-nowrap">BALANCE (UPB)</span>
                        </p>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="mr-3 w-1/2">
                        <p className="estimated-value-row-wrapper mb-5 text-sm 2xl:text-base">
                          <span className="text-[#1d2634]">
                            {formatCurrencyShort({ amount: TotalDealFirstLienPositionOriginationAmount, decimals: 2 }) || 0} -{' '}
                            {formatCurrencyShort({ amount: TotalDealFirstLienPositionOriginationAmount, decimals: 2 }) || 0}
                          </span>
                          {/*estimatedAssetValue is totalassetvalue*/}
                          <span className="leading-[10px] text-[#929dad]"></span>
                        </p>
                      </div>
                      <div className="w-1/2">
                        <p className="estimated-value-row-wrapper mb-5 text-sm 2xl:text-base">
                          <span className="text-[#1d2634]">
                            {formatCurrencyShort({ amount: totalUPB, decimals: 2 }) || 0} - {formatCurrencyShort({ amount: totalUPB, decimals: 2 }) || 0}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="w-full">
                      <p className="thin-title mb-10 mt-8 text-xs uppercase">LOCATIONS</p>
                      <div className="max-h-[238px]">
                        <LocationDotsMap locations={locations} />
                      </div>
                    </div>
                  </div>
                  <div className="w-[40%]">
                    <div className="center-card">
                      <div className="center-card-wrapper xl:p-3 2xl:p-5">
                      <div className="flex items-center justify-between">
                          <div className="flex items-center w-[90%] justify-center space-x-2">
                            <a
                              className="desktop-opportunity-title underline cursor-pointer"
                              href="#"
                              onClick={(e) => onLenderNameClicked(e, opportunity)} // Prevents the default anchor tag behavior
                            >
                              {lenderName}
                            </a>
                            <span>
                              {opportunity.opportunityData.organizationID !== '' ? (
                                <CircleCheckIcon color="green" />
                              ) : (
                                <UnCircleCheckIcon color="red" />
                              )}
                            </span>
                          </div>

                          {/* Action Menu */}
                          <div className="w-[20px]">
                            <CarouselCardActionMenu onItemClick={(action) => handleMenuItemClick(action, opportunity)} />
                          </div>
                        </div>
                        <div className="mt-2 flex justify-between">
                          <button className="btn" onClick={() => opportunityStatusChangeHandler(opportunity, DEAL_STATUS.Rejected)}>
                            {renderThumbUpDownIcon(opportunity, DEAL_STATUS.Rejected)}
                          </button>
                          {/* <div className="text-white pill-wrapper xl:w-[70%] h-8">
                              <span className="font-bold">{formatCurrencyShort({ amount: adjustedMAOB, decimals: 2 })}</span>
                              <span className="font-light mx-1">|</span>
                              <span className="font-bold">{percentage}%</span>
                            </div> */}
                          <div
                            className="pill-wrapper flex h-8 cursor-pointer items-center text-white xl:w-[70%]"
                            onClick={(e) => !editedMAOB && handleSubmitOfferClick(e, opportunity)}
                          >
                            {editedMAOB ? (
                              <>
                                <EditableField
                                  initialValue={editedMAOB}
                                  onSave={(value) => handleSaveMAOBClick(value, opportunity)}
                                  onDiscard={() => handleDiscardMAOBClick(opportunity)}
                                />
                                <span className="mx-1 font-light">|</span>
                                <span className="font-bold">{percentage}%</span>
                              </>
                            ) : (
                              <>
                                <div className="flex items-center">
                                  <span title="Click to edit" className="font-bold">
                                    {formatCurrencyShort({ amount: adjustedOpportunityMAOB, decimals: 2 })}
                                  </span>
                                  <span className="cursor-pointer">
                                    <Pencil
                                      onClick={(e) => handleEditMAOBClick(e, opportunity, 'adjustedOpportunityMAOB', adjustedOpportunityMAOB)}
                                      className="ml-2 h-4 w-4 cursor-pointer"
                                    />
                                  </span>
                                </div>
                                <span className="mx-1 font-light">|</span>
                                <span className="font-bold">{percentage}%</span>
                              </>
                            )}
                          </div>
                          <button className="btn" onClick={() => opportunityStatusChangeHandler(opportunity, DEAL_STATUS.InReview)}>
                            {renderThumbUpDownIcon(opportunity, DEAL_STATUS.InReview)}
                          </button>
                        </div>
                        <div className="mt-5 flex">
                          <div className="w-[100%]">
                            <div className="my-0 w-[100%] rounded-lg border border-gray-300 p-2 2xl:w-[100%]">
                              <div className="mt-3 flex items-center">
                                <div className="thin-title mb-3 flex w-[10%] text-sm uppercase">PP</div>
                                <div className="w-[90%]">
                                  {/* <SliderComponent 
                                      initialProgress={Number((opportunity.carouselCard.opportunityData.opportunityMAOS || 0).toFixed(2))} 
                                      fromLabel={formatCurrencyShort({ amount: (opportunity.carouselCard.opportunityData.opportunityMAOB * .7), decimals: 2 })} 
                                      toLabel={formatCurrencyShort({ amount: opportunity.carouselCard.opportunityData.opportunityMAOB, decimals: 2 })} 
                                      onValueChange={handleSliderValueChange}
                                    />     */}
                                  {oppDiscountPercentMAOBMap[opportunity.opportunityId] !== undefined && (
                                    <SliderComponent
                                      initialProgress={oppDiscountPercentMAOBMap[opportunity.opportunityId]}
                                      fromLabel={formatCurrencyShort({ amount: opportunityMAOB * 0.7, decimals: 2 })}
                                      toLabel={formatCurrencyShort({ amount: opportunityMAOB, decimals: 2 })}
                                      onValueChange={(value) => handleSliderValueChange(opportunity, value)}
                                    />
                                  )}
                                </div>
                              </div>
                              {/* <div className="mt-2 text-center text-sm">
                                    Slider Value: {sliderValue / 100}

                                ((((totalOfferPrice*0.7)-totalOfferPrice)*(sliderValue/100))+(totalOfferPrice*0.7))
                                {formatCurrencyShort({ amount: ((((totalOfferPrice*0.7)-totalOfferPrice)*(sliderValue/100))+(totalOfferPrice)), decimals: 2 })}

                                </div>                           */}
                              <div className="flex items-center">
                                <div className="thin-title mb-3 w-[10%] text-sm uppercase">LEV</div>
                                <div className="w-[90%]">
                                  <div className="my-0 flex justify-evenly p-2">
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex text-sm">AIV</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {((blueButtonValue / opportunityAIV) * 100).toFixed(0)}%
                                      </div>
                                      {/* {formatCurrencyShort({ amount: totalOfferPrice * (0.7 + (sliderValue / 100) * 0.3), decimals: 2 })} */}
                                    </div>
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex">UPB</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {((blueButtonValue / opportunityUPB) * 100).toFixed(0)}%
                                      </div>
                                    </div>
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex">AAO</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {((blueButtonValue / opportunityAAO) * 100).toFixed(0)}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <ProgressComponent progress={Number((offerRange.percentage || 0).toFixed(2))} fromLabel={formatCurrencyShort({ amount: offerRange.min, decimals: 2 })} toLabel={formatCurrencyShort({ amount: offerRange.max, decimals: 2 })} /> */}
                            </div>
                          </div>
                          {/* <div className="2xl:w-[40%] xl:w-[50%]">
                              <div className="w-full flex flex-col">
                                <div className="uppercase thin-title text-xs mb-3">AS IS VALUE (AIV)</div>
                                <div className="text-base font-medium">{formatCurrencyShort({ amount: Number(totalmarketvalue), decimals: 2 }) || 0}-{formatCurrencyShort({ amount: totalmarketvalue, decimals: 2 }) || 0}</div>
                              </div>
                            </div> */}
                          {/*estimatedAssetValue is totalassetvalue*/}
                        </div>
                        <div className="flex">
                          <div className="w-[100%]">
                            <div className="mt-5 flex w-[100%] flex-col rounded-lg border border-gray-300 p-2 2xl:w-[100%]">
                              <div className="mt-3 flex items-center">
                                <div className="thin-title mb-3 flex w-[10%] text-sm uppercase">SP</div>
                                <div className="w-[90%]">
                                  <ProgressComponent
                                    progress={Number((0).toFixed(2))}
                                    fromLabel={formatCurrencyShort({ amount: opportunity.carouselCard.opportunityData.opportunityMAOS, decimals: 2 })}
                                    toLabel={formatCurrencyShort({
                                      amount: opportunity.carouselCard.opportunityData.opportunityMAOS + opportunity.carouselCard.opportunityData.opportunityMAOS * 0.3,
                                      decimals: 2,
                                    })}
                                  />
                                </div>
                              </div>
                              <div className="flex items-center">
                                <div className="thin-title mb-3 w-[10%] text-sm uppercase">LEV</div>
                                <div className="w-[90%]">
                                  <div className="my-0 flex justify-evenly p-2">
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex">AIV</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {(opportunity.carouselCard.opportunityData.opportunityLeveragesMAOS.AIV * 100).toFixed(0)}%
                                      </div>
                                    </div>
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex">UPB</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {(opportunity.carouselCard.opportunityData.opportunityLeveragesMAOS.UPB * 100).toFixed(0)}%
                                      </div>
                                    </div>
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex">AAO</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {(opportunity.carouselCard.opportunityData.opportunityLeveragesMAOS.AAO * 100).toFixed(0)}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 flex flex-col md:flex-col lg:flex-col">
                          {/* <div className="2xl:w-[55%] xl:w-[90%] lg:w-[70%] flex">
                              <div className="2xl:mr-3 xl:mr-5 xl:ml-3">
                                <div className="uppercase thin-title text-xs mb-3 mt-8">DEFAULT RATE</div>
                                <div className="flex items-start justify-around">
                                  <div className="w-[90px] h-[80px] ">
                                    <CircleChart
                                      series={[100 - lenderDefaultRatePercentage, lenderDefaultRatePercentage]}
                                      title={`${lenderDefaultRatePercentage}%`}
                                      donutSize="70%"
                                      className="2xl:font-base xl:font-xs"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="2xl:w-[60%] lg:w-[30%] lg:ml-3">
                                <div className="uppercase thin-title text-xs mb-3 mt-8 2xl:mr-4 xl:mr-2">DEFAULT AMOUNT</div>
                                <div className="flex items-center 2xl:justify-center xl:justify-left">
                                  <div className="2xl:w-[40%] xl:w-[30%] h-[60px]">
                                    <NumberDisplay topNumber={formatCurrencyShort({ amount: lenderDefaultRateAmount.min, decimals: 2 })} bottomNumber={formatCurrencyShort({ amount: lenderDefaultRateAmount.max, decimals: 2 })} className="font-light xl:text-sm lg:text-xs" />
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          <div className="ml-0 lg:px-5 xl:ml-0 2xl:w-[100%]">
                            <div className="flex justify-between">
                              <div className="flex flex-col items-center md:px-3">
                                <div className="thin-title text-xs uppercase xl:mb-2 2xl:mb-3">TOTAL PROP</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="md:mb-2">{totalProperties}</div>
                              </div>
                              <div className="flex flex-col items-center md:px-3">
                                <div className="thin-title text-xs uppercase xl:mb-2 2xl:mb-3">
                                  TAX LIEN <span className="2xl:visibility:visible xl:visibility:hidden;"></span>
                                </div>
                                {/* ({totalTaxLiens}) */}
                                <div className="md:mb-2">{totalTaxLiens !== 0 ? <BlueCircleCheckIcon /> : <BlueCircleXIcon />}</div>
                              </div>
                              <div className="flex flex-col items-center md:px-3 lg:px-3">
                                <div className="thin-title text-xs uppercase lg:mb-0 xl:mb-2 2xl:mb-3">HOA LIEN</div>
                                <div className="md:mb-2">
                                  <BlueCircleXIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex h-full items-center justify-center">
                            <div className="justify-around pt-2 xl:ml-0 2xl:w-1/3">
                              <div className="flex w-1/2 items-start space-x-3 md:px-3">
                                <div className="thin-title flex justify-end pr-1 text-xs uppercase">AIV</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="flex flex-col space-y-0">
                                  <div className="estimated-value-row-wrapper text-center text-sm font-bold 2xl:text-base">
                                    {formatCurrencyShort({ amount: opportunityAIV, decimals: 2 }) || 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="justify-around pt-2 lg:px-0 xl:ml-0 2xl:w-1/3">
                              <div className="flex w-1/2 items-start space-x-3 md:px-3">
                                <div className="thin-title flex justify-end pr-1 text-xs uppercase">AO</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="flex flex-col space-y-0">
                                  <div className="estimated-value-row-wrapper text-center text-sm font-bold 2xl:text-base">
                                    {formatCurrencyShort({ amount: TotalDealFirstLienPositionOriginationAmount, decimals: 2 }) || 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="justify-around pt-2 lg:px-0 xl:ml-0 2xl:w-1/3">
                              <div className="flex w-1/2 items-start space-x-3 md:px-3">
                                <div className="thin-title flex justify-end pr-1 text-xs uppercase">UPB</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="flex flex-col space-y-0">
                                  <div className="estimated-value-row-wrapper text-center text-sm font-bold 2xl:text-base">
                                    {formatCurrencyShort({ amount: totalUPB, decimals: 2 }) || 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-8 w-[35%] 2xl:w-[30%]">
                    <div>
                      <div className="mt-5 flex flex-wrap">
                        <div className="thin-title w-1/2 text-xs uppercase">
                          LOANS: DEFAULT / TOTAL
                          <br />
                          VOLUME LAST 24 MONTHS
                        </div>
                        <div className="thin-title w-1/2 text-xs uppercase">LOANS: DEFAULT AMOUNT / TOTAL AMOUNT LAST 24 MONTHS</div>
                      </div>
                      <div className="mt-5 flex flex-wrap">
                        <div className="w-1/2">
                          <div className="w-full">
                            <SemiCircleChart
                              label={<NumberDisplay topNumber={totalVolume.min} bottomNumber={totalVolume.max} />}
                              series={[Number(totalVolume.percentage.toFixed(2))]}
                            />
                          </div>
                        </div>
                        <div className="w-1/2">
                          <div className="w-full">
                            <SemiCircleChart
                              label={
                                <NumberDisplay
                                  topNumber={formatCurrencyShort({ amount: totalAmount.min, decimals: 2 })}
                                  bottomNumber={formatCurrencyShort({ amount: totalAmount.max, decimals: 2 })}
                                />
                              }
                              series={[Number(totalAmount.percentage.toFixed(2))]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="items-center justify-center ">
                      <div className="mt-5 flex ">
                        <div className="thin-title mb-1 w-1/2 items-center justify-center text-xs uppercase ">COUNT OF ASSET CLASS MIX</div>
                        <div className="thin-title w-1/2 items-center justify-center text-xs uppercase lg:mb-2 ">COUNT OF BORROWER</div>
                      </div>
                      <div className="mt-5 flex flex-wrap">
                        <div className="w-1/2">
                          <div className="w-full">
                            <CircleChart
                              className="h-[127px] font-bold xl:w-[80%] 2xl:w-[90%]"
                              series={countOfAssetClassMixValues}
                              fillColors={colorMapAssetClassMix.map((item) => item.color)}
                              title={countOfAssetClassMixValues.length.toString()}
                              donutSize="60%"
                              labels={assetClassMixKeys}
                            />
                          </div>
                          <div className="ms-10 xl:mt-1 2xl:mt-3 2xl:h-[60px]">
                            {colorMapAssetClassMix.map((item, index) => (
                              <div key={index} className="color-item flex items-center text-xs lg:ml-0 2xl:ml-10">
                                <div className="me-3" style={{ backgroundColor: item.color, width: '10px', height: '10px', borderRadius: '5px' }}></div>
                                <span className=" font-normal text-[#929dad] ">{item.description}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="w-1/2">
                          <div className="w-full">
                            <CircleChart
                              className="h-[127px] font-bold lg:ml-4 lg:w-[100%] xl:w-[80%] 2xl:ml-4 2xl:w-[90%]"
                              series={Object.values(reformattedCountOfBorrower)}
                              fillColors={colorMapBorrower.map((item) => item.color)}
                              title={reformattedCountOfBorrower.length.toString()}
                              donutSize="60%"
                              labels={borrowerNames}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </CarouselComponent>
      </div>

      <div>
        {/* Add to existing Third Party */}
        {existingOrg && (
          <Dialog open={existingOrg} onOpenChange={setExistingOrg}>
            <DialogContent className="max-w-screen h-[90%] w-[50%] overflow-x-hidden border-0 p-0 outline-none">
              <DialogHeader>
                <div className="flex min-h-[54px] w-full items-center bg-[#3B4F72] text-xl text-white">
                  <div className="ps-7">
                    <DialogTitle className="space-y-14 text-xl font-bold">Add to existing Third Party</DialogTitle>
                  </div>
                </div>
              </DialogHeader>

              <div className="">
                <div className={`org-search flex items-center `}>
                  <Input
                    iconStart={<SearchIcon className="" />}
                    className={``}
                    value={`search`}
                    placeholder="Search Profiles"
                    // onChange={(e) => handleSearch(e)}  <Searchbar />
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {/* Add to existing Third Party */}

        {/* Create new Third Party */}
        {createOrg && (
          <Dialog open={createOrg} onOpenChange={setCreateOrg}>
            <DialogContent className="max-w-screen h-[80%] w-[50%] overflow-x-hidden border-0 p-0 outline-none">
              <DialogHeader>
                <div className="mb-5 flex min-h-[54px] w-full items-center bg-[#3B4F72] text-xl text-white">
                  <div className="ps-7">
                    <DialogTitle className="space-y-14 text-xl font-bold ">{partyID ? 'Modify Third Party' : 'Create new Third Party'}</DialogTitle>
                  </div>
                </div>
              </DialogHeader>
              <div className="">
                <form className="m-auto w-[90%]" onSubmit={handleSubmit(onSubmit)}>
                  <div className="icon-element mb-3">
                    <label className="mb-1 block">Third Party Name:</label>
                    <input type="text" placeholder="Enter Third Party Name" id="party_name" {...register('party_name')} className="icon-input" />
                    <div className="mb-3 mt-2 text-sm text-red-500"> {errors.party_name && errors.party_name.message}</div>
                  </div>

                  {/* <div className="icon-element mb-3">
                    <label className="mb-1 block">Email:</label>
                    <input type="text" placeholder="Enter Email" id="email" {...register('email')} className="icon-input" />
                    <div className="mb-3 mt-2 text-sm text-red-500"> {errors.email && errors.email.message}</div>
                  </div> */}

                  {/* <div className="icon-element mb-3">
                    <label htmlFor="permissions" className="mb-1 block">
                      Permissions
                    </label>
                    <Controller
                      name="permissions"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          options={permissionsArr}
                          onChange={(value) => field.onChange(value)}
                          value={field.value || []}
                          className="icon-multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    {errors.permissions && <p className="mb-3 mt-2 text-sm text-red-500">{errors.permissions.message}</p>}
                  </div> */}

                  {/* Address */}
                  <div className="mb-3">
                    <div className="flex">
                      <h2 className="mb-2 flex-1 text-lg font-semibold">Addresses</h2>
                      <span onClick={addAddress} className="block cursor-pointer text-sm font-medium text-blue-600">
                        + Add Address
                      </span>
                    </div>
                    {addresses.map((address, index) => {
                      return (
                        <div className="icon-element-sectoin relative mb-3" data-id={index} key={index}>
                          {addresses.length > 1 && (
                            <div className="absolute right-2 top-2 cursor-pointer" onClick={() => removeAddress(index)}>
                              <UnCircleCheckIcon color="black" width={20} height={20} />
                            </div>
                          )}
                          <div className="grid grid-cols-3 gap-4">
                            <div className="icon-element col-span-2 mb-3">
                              <label className="mb-1 block">Street Address:</label>
                              <textarea
                                placeholder="Enter Address"
                                id={`address-${index}`}
                                value={address.address}
                                className="icon-input"
                                onChange={(e) => handleAddressChange(index, 'address', e.target.value)}
                              />
                            </div>
                            <div className="flex items-center space-x-2">
                              <Checkbox
                                checked={address.primary}
                                id={`primary-${index}`}
                                className="h-[18px] w-[18px] border-2 border-black data-[state=checked]:bg-transparent"
                                onCheckedChange={(e) => handleAddressChange(index, 'primary', e)}
                              />
                              <div className="grid gap-1.5 leading-none">
                                <label
                                  htmlFor={`primary-${index}`}
                                  className="text-sm leading-none text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:text-neutral-400"
                                >
                                  Primary?
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-4 gap-4">
                            {/* <div className="icon-element col-span-2 mb-3">
                              <label className="mb-1 block">Apt./Suite:</label>
                              <textarea
                                placeholder="Enter Apt./Suite"
                                id={`addressLine2-${index}`}
                                value={address.addressLine2}
                                className="icon-input"
                                onChange={(e) => handleAddressChange(index, 'addressLine2', e.target.value)}
                              />
                            </div> */}
                            <div className="icon-element col-span-2 mb-3">
                              <label className="mb-1 block">Description:</label>
                              <textarea
                                placeholder="Enter Description"
                                id={`label-${index}`}
                                value={address.label}
                                className="icon-input"
                                onChange={(e) => handleAddressChange(index, 'label', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="mb-4 grid grid-cols-3 gap-4">
                            <div className="icon-element mb-3">
                              <label className="mb-1 block">City:</label>
                              <input
                                type="text"
                                placeholder="Enter City"
                                id={`city-${index}`}
                                value={address.city}
                                className="icon-input"
                                onChange={(e) => handleAddressChange(index, 'city', e.target.value)}
                              />
                            </div>

                            <div className="icon-element mb-3">
                              <label className="mb-1 block">State:</label>
                              <input
                                type="text"
                                placeholder="Enter State"
                                id={`state-${index}`}
                                value={address.state}
                                className="icon-input"
                                onChange={(e) => handleAddressChange(index, 'state', e.target.value)}
                              />
                            </div>

                            <div className="icon-element mb-3">
                              <label className="mb-1 block">Zipcode:</label>
                              <input
                                type="text"
                                placeholder="Enter Zipcode"
                                id={`zipCode-${index}`}
                                value={address.zipCode}
                                className="icon-input"
                                onChange={(e) => handleAddressChange(index, 'zipCode', e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* Contanct Info */}
                  <div className="mb-3">
                    <div className="flex">
                      <h2 className="mb-2 flex-1 text-lg font-semibold">Contact Info</h2>
                      <span onClick={addContact} className="block cursor-pointer text-sm font-medium text-blue-600">
                        + Add Contact
                      </span>
                    </div>
                    {contacts.map((contact, index) => {
                      return (
                        <div className="icon-element-sectoin relative mb-3" key={index}>
                          {contacts.length > 1 && (
                            <div className="absolute right-2 top-2 cursor-pointer" onClick={() => removeContact(index)}>
                              <UnCircleCheckIcon color="black" width={20} height={20} />
                            </div>
                          )}
                          
                          <div className="grid grid-cols-2 gap-4">
                            {/* First Name */}
                            <div className="col-span-1 mb-3">
                              <label className="mb-1 block">First Name:</label>
                              <input
                                type="text"
                                placeholder="Enter First Name"
                                id={`first-name-${index}`}
                                value={contact.firstName || ""}
                                className="icon-input"
                                onChange={(e) => handleContactChange(index, 'firstName', e.target.value)}
                              />
                            </div>

                            {/* Last Name */}
                            <div className="col-span-1 mb-3">
                              <label className="mb-1 block">Last Name:</label>
                              <input
                                type="text"
                                placeholder="Enter Last Name"
                                id={`last-name-${index}`}
                                value={contact.lastName || ""}
                                className="icon-input"
                                onChange={(e) => handleContactChange(index, 'lastName', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-4">
                            {/* Email */}
                            <div className="col-span-1 mb-3">
                              <label className="mb-1 block">Email:</label>
                              <input
                                type="text"
                                placeholder="Enter Email"
                                id={`first-name-${index}`}
                                value={contact.email || ""}
                                className="icon-input"
                                onChange={(e) => handleContactChange(index, 'email', e.target.value)}
                              />
                            </div>

                            {/* Phone */}
                            <div className="col-span-1 mb-3">
                              <label className="mb-1 block">Phone number:</label>
                              <input
                                type="text"
                                placeholder="Enter Phone Number"
                                id={`last-name-${index}`}
                                value={contact.phone || ""}
                                className="icon-input"
                                onChange={(e) => handleContactChange(index, 'phone', e.target.value)}
                              />
                            </div>
                          </div>
                          {/* <div className="grid grid-cols-3 gap-4">
                            <div className="icon-element col-span-2 mb-3">
                              <label className="mb-1 block">Value:</label>
                              <input
                                type={contact?.type?.value === 'email' ? 'email' : 'text'}
                                placeholder={`Enter ${contact?.type?.value === 'email' ? 'Email' : 'Phone Number'}`}
                                id={`value-${index}`}
                                value={contact.value}
                                className="icon-input"
                                // {...register(`value-${index}`)}
                                // required={contact.type.value === 'email'}
                                onChange={(e) => handleContactChange(index, 'value', e.target.value)}
                              />
                            </div>

                            <div>
                              <label className="mb-1 block">Type:</label>
                              <Select
                                options={contanctType}
                                value={contact.type}
                                onChange={(e: any) => handleContactChange(index, 'type', e)}
                                className="icon-multi-select"
                                isSearchable={false}
                                classNamePrefix="select"
                              />
                            </div>
                          </div> */}
                          <div className="grid grid-cols-3 gap-4">
                            <div className="icon-element col-span-2 mb-3">
                              <label className="mb-1 block">Description:</label>
                              <input
                                type="text"
                                placeholder="Enter Description"
                                id={`label-${index}`}
                                value={contact.label}
                                className="icon-input"
                                onChange={(e) => handleContactChange(index, 'label', e.target.value)}
                              />
                            </div>

                            <div className="flex items-center space-x-2">
                              <Checkbox
                                checked={contact.primary}
                                id={`primary-${index}`}
                                className="h-[18px] w-[18px] border-2 border-black data-[state=checked]:bg-transparent"
                                onCheckedChange={(e) => handleContactChange(index, 'primary', e)}
                              />
                              <div className="grid gap-1.5 leading-none">
                                <label
                                  htmlFor={`primary-${index}`}
                                  className="text-sm leading-none text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:text-neutral-400"
                                >
                                  Primary?
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="icon-element mb-3">
                    <label htmlFor="permissions" className="mb-1 block">
                      DBAS:
                    </label>
                    <CreatableSelect
                      isMulti
                      isClearable
                      options={[]}
                      value={dbasData || []}
                      className="icon-multi-select"
                      isSearchable={true}
                      classNamePrefix="select"
                      onChange={(value: any) => onSelectChange(value, 'dbas')}
                      placeholder="Create new DBAS"
                      noOptionsMessage={() => 'No DBAS available'}
                    />
                  </div>

                  <div className="icon-element mb-3">
                    <label htmlFor="permissions" className="mb-1 block">
                      Entry Type:
                    </label>
                    <Select
                      isMulti
                      isClearable
                      options={entryType}
                      onChange={(value: any) => onSelectChange(value, 'enity_type')}
                      value={entryTypeData || []}
                      className="icon-multi-select"
                      isSearchable={true}
                      classNamePrefix="select"
                      placeholder="Select Entry Type"
                    />
                  </div>

                  <div className="icon-element mb-3">
                    <label htmlFor="permissions" className="mb-1 block">
                      Party Type:
                    </label>
                    <Select
                      isMulti
                      isClearable
                      options={partyType}
                      onChange={(value: any) => onSelectChange(value, 'party_type')}
                      value={partyTypeData || []}
                      className="icon-multi-select"
                      isSearchable={true}
                      classNamePrefix="select"
                      noOptionsMessage={() => 'No Party Type available'}
                      placeholder="Select Party Type"
                    />
                  </div>

                  <div className="icon-element mb-3">
                    <label htmlFor="permissions" className="mb-1 block">
                      Entry Registration Date:
                    </label>
                    <DatePicker
                      showIcon
                      selected={entityRegistrationDate}
                      onChange={(date: any) => setEntityRegistrationDate(date)}
                      icon={<CalendarIcon />}
                      maxDate={new Date()}
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>

                  <div className="my-5 flex items-center justify-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`${isSubmitting ? 'disabled:cursor-not-allowed disabled:opacity-50' : ''} mr-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700`}
                    >
                      Submit
                    </button>
                    <button
                      disabled={isSubmitting}
                      type="button"
                      className="mr-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                      onClick={() => setCreateOrg(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {/* Create new Organization */}
      </div>
    </>
  );
};

export default DealsDesktop;
// name - get from main card - landername
// permission - multi select

// organizationID in opportunityData for verification check = verified or not

// street address - address
// apt/suite - addressLine2
// description - label
// remove email from top
// add email & phone validation
// 3rd option in main list, modify thord party
