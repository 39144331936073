import moment from 'moment';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
//@ts-ignore;
import Spline from 'cubic-spline';
import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  // differenceInHours,
  // differenceInMinutes,
  // differenceInSeconds,
  format,
  parseISO
} from 'date-fns'; //parseISO
import { menuRoute } from '@/constants/SideNavLinks';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export interface FormatCurrencyShortOptions {
  amount: number;
  decimals?: number;
}

export function formatCurrencyShort({ amount, decimals = 0 }: FormatCurrencyShortOptions) {
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const amountLength = Math.round(amount).toString().length;
  const suffixNum = Math.floor((amountLength - 1) / 3);
  const roundoff = 10 ** decimals;

  const shortValue = (Math.round((suffixNum ? amount / 1000 ** suffixNum : amount) * roundoff) / roundoff).toFixed(decimals);

  return '$' + shortValue + suffixes[suffixNum];
}

export function fillNullValues(data: number[], strategy: 'linear' | 'spline' = 'linear') {
  if (strategy === 'linear') {
    const getNearestNonNull = (index: number) => {
      const nextNonNull = data.slice(index).find((value) => value !== null);
      return nextNonNull;
    };

    const nonNullData = data.map((value, index) => (value !== null ? value : getNearestNonNull(index)));
    return nonNullData;
  }

  // Filter out null values and keep track of their indices
  const nonNullData = data.filter((value) => value !== null);
  const nonNullIndices = data.map((value, index) => (value !== null ? index : null)).filter((index) => index !== null);

  // Create a cubic spline interpolator
  const interpolator = new Spline(nonNullIndices, nonNullData);

  // Fill null values with interpolated values
  return data.map((value, index) => (value === null ? interpolator.at(index) : value));
}

export function formatCurrency(amount: number) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(amount);
}


export function getTimeInDefault(date: Date | string): string {
  if (!date) return '--';

  const now = new Date();
  const inputDate = typeof date === 'string' ? new Date(date) : date;
  const diffInYears = differenceInYears(now, inputDate);
  if (diffInYears > 0) {
    return `${diffInYears} Yr`;
  }

  const diffInMonths = differenceInMonths(now, inputDate);

  if (diffInMonths > 0) {
    return `${diffInMonths} Mo`;
  }

  const diffInDays = differenceInDays(now, inputDate);
    return `${diffInDays} Day`;


  // if (diffInDays > 0) {
  //   return `${diffInDays} Day`;
  // }

  // const diffInHours = differenceInHours(now, inputDate);

  // if (diffInHours > 0) {
  //   return `${diffInHours} Hr`;
  // }

  // const diffInMinutes = differenceInMinutes(now, inputDate);
  // if (diffInMinutes > 0) {
  //   return `${diffInMinutes} Min`;
  // }

  // const diffInSeconds = differenceInSeconds(now, inputDate);
  // return `${diffInSeconds} Sec`;
}

export const convertDateToYYYYMMDD = (dateString: string | undefined) => {
  if (!dateString) return "";

  try {
    const dateTimeParts = dateString.split(' ');
    return format(dateTimeParts[0], 'yyyy/MM/dd');
  } catch (error) {
    console.error('Error parsing date:', dateString, error);
    return "";
  }
};

export const countOccurrences = (input: string, separator: string = ','): Record<string, number> => {
  const items = input.split(separator).map(i => i.trim());

  return items.reduce((acc, item) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);
}

export const countOccurrencesInArray = (items: string[]): Record<string, number> => {
  return items.reduce((acc, item) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);
}

export const renderCountOccurrences = (input: string): string => {
  const counts = countOccurrences(input);
  // Format the result as required
  const result = Object.keys(counts).map(key => `${key}(${counts[key]})`).join(' ');
  return result;
};

export const separateList = (input: string) => {
  // Split the input string by commas and trim any whitespace
  const items = input.split(',').map((item: string) => item.trim());
  // Map each item to a string
  const separated = items.map((item, index) => `<p key=${index}>${item}</p>`).join('');
  return separated;
}

export const mapIdsToValues = (ids: string, values: string): Record<string, string> => {
  if (!ids || !values) return {};

  const idArray = ids.split(',');
  const valueArray = values.split(',');
  const result: Record<string, string> = {};

  if (idArray.length !== valueArray.length) {
    console.error('ids and values arrays are not of the same length');
    return result;
  }

  idArray.forEach((id, index) => {
    result[id] = valueArray[index];
  });

  return result;
}

export const getMinMaxValues = (input: string): { min: number, max: number } => {
  if (!input) return { min: 0, max: 0 };

  // Split the input string by commas and convert to an array of numbers
  const numbers = input.split(',').map(num => parseFloat(num.trim()));

  // Find the minimum and maximum values
  const min = Math.min(...numbers);
  const max = Math.max(...numbers);

  // Return the result as an object
  return { min, max };
}

export const extractMinMaxValues = (arr: object[], field: string): { min: number, max: number } => {
  if (!arr) return { min: 0, max: 0 };

  const numbers = (arr || []).map((a: any) => parseFloat(a[field] || 0));

  // Find the minimum and maximum values
  const min = Math.min(...numbers);
  const max = Math.max(...numbers);

  // Return the result as an object
  return { min, max };
}

export const aggregateValues = (data: { name: string, count: number }[]): Record<string, number> =>
  data.reduce((acc, { name, count }) => {
    acc[name] = (acc[name] || 0) + count;
    return acc;
  }, {} as Record<string, number>);

export const getOpportunityStatus = (createdDate: string, updatedDate: string): 'NEW' | 'UPDATED' | 'OLD' => {
  const created = new Date(createdDate);
  const updated = new Date(updatedDate);
  const now = new Date();

  const hoursSinceCreation = (now.getTime() - created.getTime()) / (1000 * 60 * 60);
  const hoursSinceUpdate = (now.getTime() - updated.getTime()) / (1000 * 60 * 60);

  if (hoursSinceCreation <= 24) {
    return 'NEW';
  } else if (hoursSinceCreation > 24 && hoursSinceUpdate <= 24) {
    return 'UPDATED';
  } else {
    return 'OLD';
  }
}

export const formatDateString = (dateString: string): string => {
  const date = parseISO(dateString);
  const formattedDate = format(date, 'MMMM d, yy').toUpperCase();
  return formattedDate;
}

export const getAccessibleMenu = (access: string) => {
  if (access.includes('admin')) {
    return menuRoute; // Admins have access to all routes
  }

  const accessibleRoutes = [];
  if (access.includes('buying')) {
    accessibleRoutes.push('buyers');
  }
  if (access.includes('selling')) {
    accessibleRoutes.push('offers');
  }

  return accessibleRoutes;
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const percFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const formatToUSDate = (date?: Date | null): string => date === null || date === undefined ? "-" : moment(date).format('MM/DD/YYYY');

export const formatToUSDCurrency = (value?: number | null): string => value === null || value === undefined ? "-" : currencyFormatter.format(value);

export const formatToPerc = (value?: number | null): string => value === null || value === undefined ? "-" : percFormatter.format(value);

export const formatToDays = (value: string | number) => value === null || value === undefined ? "-" : `${value} Days`;

export const addPercentAtEnd = (value: string | number) => `${value}%`;

export const formatToTwoDecimal = (value: number) => parseFloat(value.toFixed(2))