import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import { ChevronRightIcon } from 'lucide-react';

interface CarouselCardActionMenuProps {
  onItemClick: (action: string) => void;
}

const CarouselCardActionMenu: React.FC<CarouselCardActionMenuProps> = ({ onItemClick }) => {
  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button className="text-violet11 hover:bg-violet3 inline-flex items-center justify-center rounded-full bg-white outline-none" aria-label="Customise options">
            <HamburgerMenuIcon />
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade min-w-[90px] rounded-md bg-white p-[3px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform]"
            sideOffset={5}
          >
            <DropdownMenu.Item
              className="text-violet11 group relative flex h-[25px] cursor-pointer select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none outline-none hover:bg-gray-200"
              onClick={() => onItemClick('edit')}
            >
              Edit
            </DropdownMenu.Item>

            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger className="text-violet11 data-[highlighted]:bg-violet9 data-[highlighted]:data-[state=open]:bg-violet9 data-[state=open]:bg-violet4 data-[disabled]:text-mauve8 data-[highlighted]:data-[state=open]:text-violet1 data-[highlighted]:text-violet1 data-[state=open]:text-violet11 group relative flex h-[25px] select-none items-center rounded-[3px] pl-[25px] pr-[5px] text-[13px] leading-none outline-none data-[disabled]:pointer-events-none">
              Edit Third Party
                <div className="text-mauve11 group-data-[disabled]:text-mauve8 ml-auto pl-5 ">
                  <ChevronRightIcon />
                </div>
              </DropdownMenu.SubTrigger>
                <DropdownMenu.SubContent
                  className="data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade min-w-[220px] rounded-md bg-white p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform]"
                  sideOffset={2}
                  alignOffset={-5}
                >
                  <DropdownMenu.Item className="text-violet11 group relative flex h-[25px] cursor-pointer select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none outline-none hover:bg-gray-200"  onClick={() => onItemClick('existing-organization')}>
                    Add to existing Third Party
                  </DropdownMenu.Item>
                  <DropdownMenu.Item className="text-violet11 group relative flex h-[25px] cursor-pointer select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none outline-none hover:bg-gray-200"  onClick={() => onItemClick('create-organization')}>
                    Create new Third Party
                  </DropdownMenu.Item>
                  <DropdownMenu.Item className="text-violet11 group relative flex h-[25px] cursor-pointer select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none outline-none hover:bg-gray-200"  onClick={() => onItemClick('modify-organization')}>
                    Modify Third Party
                  </DropdownMenu.Item>
                </DropdownMenu.SubContent>
            </DropdownMenu.Sub>

            <DropdownMenu.Item
              className="text-violet11 group relative flex h-[25px] cursor-pointer select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none outline-none hover:bg-gray-200"
              onClick={() => onItemClick('save')}
            >
              Save
            </DropdownMenu.Item>
            
            <DropdownMenu.Item
              className="text-violet11 group relative flex h-[25px] cursor-pointer select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none outline-none hover:bg-gray-200"
              onClick={() => onItemClick('pricing')}
            >
              Pricing
            </DropdownMenu.Item>
            <DropdownMenu.Arrow className="fill-white" />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};

export default CarouselCardActionMenu;
