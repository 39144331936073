import { RuleState, Options, RuleInput } from "../models/GlobalPricingRuleEngineData";

const lowRiskPropertyTableMapping = [
  { label: "AAO (%)", key: "AAO" },
  { label: "AIV (%)", key: "AIV" },
  { label: "UPB (%)", key: "UPB" },
];



const offersMapping = [
    {label: "Days to closing", key:"daysToClosing"},
    {label: "Discount (%)", key:"discount"},
    {label: "Due Dilligence Days", key:"duegilligence"}

]

const sampleValuesTableMapping = [
  { label: "AAO (%)", key: "AAO" },
  { label: "AIV (%)", key: "AIV" },
  { label: "UPB (%)", key: "UPB" },
  { label: "Loan Amount", key: "loanAmount" },
];

export const steps = [
  //AssetClass
  {
    title: "Asset Class",
    step: 1,
    content: (
      formData: RuleState,
      handleInputChange: (newValue: any) => void
    ) => (
      <div>
        <label className="block mb-2">Select Asset Classes:</label>
        <div className="space-y-2">
          {["Residential", "Multi-family", "Commercial", "Industrial"].map(
            (option) => (
              <label key={option} className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.assetClass?.includes(option) || false}
                  onChange={(e) => {
                    const selected = formData.assetClass || [];
                    const updatedSelections = e.target.checked
                      ? [...selected, option]
                      : selected.filter((item: string) => item !== option);
                    handleInputChange({
                      ...formData,
                      assetClass: updatedSelections,
                    });
                  }}
                  className="mr-2"
                />
                {option}
              </label>
            )
          )}
        </div>
      </div>
    ),
  }, //LTV Threshold
  {
    title: "LTV Threshold",
    step: 2,
    content: (
      formData: RuleState,
      handleInputChange: (newValue: any) => void
    ) => (
      <div>
        <label className="whitespace-nowrap">Enter LTV Threshold(%):</label>
        <input
          type="number"
          value={formData.ltvThreshold || ""}
          onChange={(e) =>
            handleInputChange({ ...formData, ltvThreshold: e.target.value })
          }
          className="border px-2 py-1 rounded w-full"
        />
      </div>
    ),
  }, //LowRiskPropertyTable
  {
    title: "MAOB",
    step: 3,
    content: (
      formData: RuleState,
      handleInputChange: (newValue: any) => void
    ) => (
      <div>
        <table className="w-full">
          <tbody>
            <tr>
              <td></td>
              <td className="text-xs whitespace-nowrap align-middle px-5 py-2">When LTV ≤ {formData.ltvThreshold}%</td>
              <td className="text-xs whitespace-nowrap align-middle px-5 py-2">When LTV {'>'} {formData.ltvThreshold}%</td>
            </tr>
            <tr>
              <td className="px-5 py-2 text-xs whitespace-nowrap align-middle">
                {/* Operation */}
              </td>
              <td className="px-5 py-2">
                <select
                  value={formData.highRiskPropertyTable.option}
                  className="text-gray-600 text-xs p-1 border border-gray-300 rounded-sm w-full"
                  onChange={(e) =>
                    handleInputChange({
                      ...formData,
                      highRiskPropertyTable: {
                        ...formData.highRiskPropertyTable,
                        option: e.target.value,
                      },
                    })
                  }
                >
                  <option value={Options.LESSER_OF}>Lesser Of</option>
                  <option value={Options.GREATER_OF}>Greater Of</option>
                </select>
              </td>
              <td className="px-5 py-2">
                <select
                  value={formData.lowRiskPropertyTable.option}
                  className="text-gray-600 text-xs p-1 border border-gray-300 rounded-sm w-full"
                  onChange={(e) =>
                    handleInputChange({
                      ...formData,
                      lowRiskPropertyTable: {
                        ...formData.lowRiskPropertyTable,
                        option: e.target.value,
                      },
                    })
                  }
                >
                  <option value={Options.LESSER_OF}>Lesser Of</option>
                  <option value={Options.GREATER_OF}>Greater Of</option>
                </select>
              </td>
            </tr>
            {lowRiskPropertyTableMapping.map(({ key, label }) => (
              <tr key={key}>
                <td className="px-5 py-2 text-xs text-gray-700 whitespace-nowrap pr-4 align-middle">
                  {label}
                </td>
                <td className="px-5 py-2">
                  <input
                    type="number"
                    value={
                      formData.lowRiskPropertyTable.tableData[
                      key as keyof typeof formData.lowRiskPropertyTable.tableData
                      ] || ""
                    }
                    onChange={(e) =>
                      handleInputChange({
                        ...formData,
                        lowRiskPropertyTable: {
                          ...formData.lowRiskPropertyTable,
                          tableData: {
                            ...formData.lowRiskPropertyTable.tableData,
                            [key]: e.target.value,
                          },
                        },
                      })
                    }
                    className="border px-2 py-1 rounded w-full"
                  />
                </td>
                <td className="px-5 py-2">
                  <input
                    type="number"
                    value={
                      formData.highRiskPropertyTable.tableData[
                      key as keyof typeof formData.highRiskPropertyTable.tableData
                      ] || ""
                    }
                    onChange={(e) =>
                      handleInputChange({
                        ...formData,
                        highRiskPropertyTable: {
                          ...formData.highRiskPropertyTable,
                          tableData: {
                            ...formData.highRiskPropertyTable.tableData,
                            [key]: e.target.value,
                          },
                        },
                      })
                    }
                    className="border px-2 py-1 rounded w-full"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
  },
  //MultipleOffersTable
  {
    title: "Multiple Offers",
    step: 5,
    content: (
      formData: RuleState,
      handleInputChange: (newValue: any) => void
    ) => (
      <div>
        {/* Input Fields */}
        <div className="space-y-4">
          <table className="w-full">
            <tbody>
              <tr>
                <td></td>
                <td className="px-5 py-2 text-xs whitespace-nowrap align-middle">Offer 1</td>
                <td className="px-5 py-2 text-xs whitespace-nowrap align-middle">Offer 2</td>
                <td className="px-5 py-2 text-xs whitespace-nowrap align-middle">Offer 3</td>
              </tr>
              {offersMapping.map(({ label, key }) => (
                <tr key={key} className="align-middle">                  
                  <td className="px-5 py-2 text-xs text-gray-700 pr-4 align-middle whitespace-nowrap">
                    {label}
                  </td>
                  <td className="px-5 py-2">
                    <input
                      type="number"
                      value={
                        formData.offers[
                        key as keyof typeof formData.offers
                        ][0] || ""
                      }
                      onChange={(e) =>
                        handleInputChange({
                          ...formData,
                          offersMapping: {
                            ...formData.offers,
                            [key]: e.target.value,
                          },
                        })
                      }
                      className="border px-4 py-2 rounded w-full"
                    />
                  </td>
                  <td className="px-5 py-2 text-xs">
                    <input
                      type="number"
                      value={
                        formData.offers[
                        key as keyof typeof formData.offers
                        ][1] || ""
                      }
                      onChange={(e) =>
                        handleInputChange({
                          ...formData,
                          offersMapping: {
                            ...formData.offers,
                            [key]: e.target.value,
                          },
                        })
                      }
                      className="border px-4 py-2 rounded w-full"
                    />
                  </td>
                  <td className="px-5 py-2 text-xs">
                    <input
                      type="number"
                      value={
                        formData.offers[
                        key as keyof typeof formData.offers
                        ][2] || ""
                      }
                      onChange={(e) =>
                        handleInputChange({
                          ...formData,
                          offersMapping: {
                            ...formData.offers,
                            [key]: e.target.value,
                          },
                        })
                      }
                      className="border px-4 py-2 rounded w-full"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    ),
  }, //Profit Margin
  {
    title: "Profit Margin",
    step: 6,
    content: (
      formData: RuleState,
      handleInputChange: (newValue: any) => void
    ) => (
      <div>
        <label className="block mb-2">Enter Profit Margin (%):</label>
        <input
          type="number"
          value={formData.profitMarginValue || ""}
          onChange={(e) =>
            handleInputChange({ ...formData, profitMarginValue: e.target.value })
          }
          className="border px-2 py-1 rounded w-full"
        />
      </div>
    ),
  },
];

export const inputWizardBox = {
  title: "Sample Values Table",
  content: (
    formData: RuleInput,
    handleInputChange: (newValue: any) => void
  ) => (
    <div>
      {/* Input Fields */}
      <div className="space-y-4">
        {sampleValuesTableMapping.map(({ key, label }) => (
          <div key={key}>
            <label className="block mb-1">{label}:</label>
            <input
              type="number"
              value={
                formData[
                key as keyof typeof formData
                ] || ""
              }
              onChange={(e) =>
                handleInputChange({
                  ...formData,
                  [key]: e.target.value,
                })
              }
              className="border px-2 py-1 rounded w-full"
            // placeholder={`Enter ${field}`}
            />
          </div>
        ))}
      </div>
    </div>
  ),
}
