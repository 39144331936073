import React, { useState, useEffect, useRef } from "react";
import { CheckIcon } from "lucide-react";

export interface MultiSelectOption {
  label: string;
  value: any;
}

interface MultiSelectDropdownProps {
  options: MultiSelectOption[];
  selectedValues?: any[];
  onChange: (selected: MultiSelectOption[]) => void;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options,
  selectedValues = [],
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<MultiSelectOption[]>(
    []
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize preselected options
    const preselected = options.filter((option) =>
      selectedValues.includes(option.value)
    );
    setSelectedOptions(preselected);
  }, [options, selectedValues]);

  const handleToggleOption = (option: MultiSelectOption) => {
    const isSelected = selectedOptions.some(
      (item) => item.value === option.value
    );
    const updatedSelections = isSelected
      ? selectedOptions.filter((item) => item.value !== option.value)
      : [...selectedOptions, option];
    setSelectedOptions(updatedSelections);
    onChange(updatedSelections);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative w-40 h-auto" ref={dropdownRef}>
      {/* Dropdown Button */}
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="w-full bg-white border border-gray-300 rounded px-3 py-2 text-sm text-gray-700 flex justify-between items-center"
      >
        <span
          // className="truncate"
          title={selectedOptions.map((opt) => opt.label).join(", ")}
        >
          {selectedOptions.length > 0
            ? selectedOptions.map((opt) => opt.label).join(", ")
            : "Select options"}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`w-5 h-5 transform ${isOpen ? "rotate-180" : ""}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {/* Dropdown Options */}
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-auto">
          {options.map((option) => {
            const isSelected = selectedOptions.some(
              (item) => item.value === option.value
            );
            return (
              <div
                key={option.value}
                className={`flex items-center px-3 py-2 text-sm cursor-pointer ${
                  isSelected ? "bg-blue-100" : "hover:bg-gray-100"
                }`}
                onClick={() => handleToggleOption(option)}
              >
                <span
                  className="truncate flex-1"
                  title={option.label}
                >
                  {option.label}
                </span>
                {isSelected && (
                  <CheckIcon
                    className="w-4 h-4 text-blue-500"
                    style={{ flexShrink: 0 }}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
