import { useEffect, useRef, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import MultiSelectDropdown, {
  MultiSelectOption,
} from "./MultiSelectDropdown";
import { OffersTable, Tables } from "../models/GlobalPricingRuleEngineData";
import { Copy, Play, Trash2, WandSparkles } from "lucide-react";
import { formatToTwoDecimal } from "../../../../../lib/utils";

export const StartTextNode = ({
  data,
}: NodeProps<{
  dragging: false;
  label: string[]; // Labels passed as default selected values
  onValueChange: (newClass: string[]) => void; // Updated to handle multiple values
  options: MultiSelectOption[];
}>) => {
  // Initialize selectedValues from the passed labels
  const [selectedValues, setSelectedValues] = useState<MultiSelectOption[]>(
    data.label
      .map((label) => data.options.find((option) => option.label === label))
      .filter(Boolean) as MultiSelectOption[] // Filter out any unmatched labels
  );

  useEffect(() => {
    setSelectedValues(
      data.label
        .map((label) => data.options.find((option) => option.label === label))
        .filter(Boolean) as MultiSelectOption[]
    );
  }, [data.label, data.options]);

  // Handle MultiSelect changes
  const handleMultiSelectChange = (selected: MultiSelectOption[]) => {
    setSelectedValues(selected);
    data.onValueChange(selected.map((opt) => opt.value));
  };

  return (
    <div className="p-4 bg-white border border-gray-400 rounded-sm shadow-custom-button h-36 flex items-center justify-center flex-col">
      <p className="text-xs text-center pb-2">For Asset Classes</p>
      <MultiSelectDropdown
        options={data.options}
        selectedValues={selectedValues.map((opt) => opt.value)} // Map to values for the dropdown
        onChange={handleMultiSelectChange}
      />
      <Handle type="source" position={Position.Right} isConnectable={false} />
    </div>
  );
};

export const TextNode = ({
  data,
}: NodeProps<{
  label: string;
  value: string;
  onValueChange: (newVal: string) => void;
}>) => {
  const [isEditable, setIsEditable] = useState(false); // Track if the node is editable
  const [value, setValue] = useState(data.value); // Default value is 3

  useEffect(() => {
    setValue(data.value);
  }, [data.value]);

  const handleDoubleClick = () => {
    setIsEditable(true);
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    // data.onValueChange(e.target.value)
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditable(false);
    const val = parseFloat(e.target.value);
    data.onValueChange(formatToTwoDecimal(val).toString()); // callback
  };

  return (
    <div
      className="p-4 bg-white rounded-sm shadow-custom-button border border-gray-400"
      onDoubleClick={handleDoubleClick}
    >
      {/* Display the label */}
      <div className="text-center w-auto text-xs">{data.label}</div>
      {isEditable ? (
        <div className="text-center w-16">
          <input
            type="number"
            value={value}
            onChange={handleLabelChange}
            onBlur={handleBlur} // Save when input loses focus
            autoFocus
            className="text-center bg-transparent border-none w-12"
          />
        </div>
      ) : (
        <div className="text-center w-16">{data.value}%</div>
      )}

      <Handle type="source" position={Position.Right} isConnectable={false} />
      <Handle type="target" position={Position.Left} isConnectable={false} />
    </div>
  );
};

export const TableNode = ({
  data,
}: NodeProps<{
  heading: string;
  tableData: Tables[]; // Updated to include formatter as a function
  onValueChange: (tableData: Tables[]) => void;
  subheader?: boolean;
  className?: string;
  option?: string;
  onOptionChange?: (newOption: string) => void;
}>) => {
  const [tableData, setTableData] = useState<Tables[]>(data.tableData);
  const [editableCell, setEditableCell] = useState<{
    rowIndex: number | null;
    colIndex: number | null;
  }>({ rowIndex: null, colIndex: null });

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // Focus the input field when a cell becomes editable
    if (editableCell.rowIndex !== null && editableCell.colIndex !== null) {
      inputRef.current?.focus();
    }
  }, [editableCell]);

  useEffect(() => {
    setTableData(data.tableData);
  }, [data.tableData]);

  const handleCellChange = (rowIndex: number, value: string) => {
    const updatedTableData = [...tableData];
    const validatedValue = parseFloat(value);

    updatedTableData[rowIndex].value = validatedValue;
    setTableData(updatedTableData);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (data.onOptionChange) {
      data.onOptionChange(event.target.value);
    }
  };

  const handleDoubleClick = (rowIndex: number, colIndex: number) => {
    setEditableCell({ rowIndex, colIndex });
  };

  const handleBlur = () => {
    setEditableCell({ rowIndex: null, colIndex: null });
    tableData.forEach((data) => {
      data.value = formatToTwoDecimal(data.value);
    });
    data.onValueChange(tableData);
  };

  return (
    <div
      className={`p-4 bg-gray-100 border border-gray-400 rounded-sm ${
        data.className || ""
      }`}
    >
      <div className="font-base text-xs text-center mb-2">{data.heading}</div>
      {data.subheader && (
        <div className="flex items-center justify-center">
          <select
            value={data.option}
            className="text-gray-600 text-xs mb-2 p-1 border border-gray-300 rounded-sm text-center w-auto"
            onChange={handleChange}
          >
            <option value="Lesser Of">Lesser Of</option>
            <option value="Greater Of">Greater Of</option>
          </select>
        </div>
      )}
      <table className="border-collapse w-full">
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={row.key}>
              {/* Non-editable first column */}
              <td className="border border-transparent bg-white text-xs pl-2">
                {row.label}
              </td>

              {/* Editable and formatted second column */}
              <td className="border border-transparent bg-white text-center text-xs">
              <div
                  className="px-2 py-1 w-24"
                  onDoubleClick={() => handleDoubleClick(rowIndex, 1)} // Specify column index for edit
                >
                  {editableCell.rowIndex === rowIndex &&
                  editableCell.colIndex === 1 ? (
                    <input
                      type="number"
                      ref={inputRef} // Attach the ref to the input
                      value={row.value} // Second column value
                      onChange={(e) =>
                        handleCellChange(rowIndex, e.target.value)
                      }
                      onBlur={handleBlur}
                      onKeyDown={(e) => {
                        if (row.isWholeNumber) {
                          // Block ".", "e", "-", and any alphabet keys
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }
                      }}
                      className="text-center border border-gray-300 rounded-sm w-16"
                    />
                  ) : (
                    <div className="border border-transparent">
                      {row.formatter ? row.formatter(row.value) : row.value}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Handle type="source" position={Position.Right} isConnectable={false} />
      <Handle type="target" position={Position.Left} isConnectable={false} />
    </div>
  );
};

export const MultipleOffersTableNode = ({
  data,
}: NodeProps<{
  heading: string;
  tableData: OffersTable[]; // Updated to include formatter as a function
  onValueChange: (tableData: OffersTable[]) => void;
  className?: string;
  onOptionChange?: (newOption: string) => void;
}>) => {
  const [tableData, setTableData] = useState<OffersTable[]>(data.tableData);
  const [editableCell, setEditableCell] = useState<{
    rowIndex: number | null;
    colIndex: number | null;
  }>({ rowIndex: null, colIndex: null });

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // Focus the input field when a cell becomes editable
    if (editableCell.rowIndex !== null && editableCell.colIndex !== null) {
      inputRef.current?.focus();
    }
  }, [editableCell]);

  useEffect(() => {
    setTableData(data.tableData);
  }, [data.tableData]);

  const handleCellChange = (rowIndex: number, colIndex: number, value: string) => {
    const updatedTableData = [...tableData];
    const validatedValue = parseFloat(value);
    if(colIndex === 1)
    updatedTableData[rowIndex].value1 = validatedValue;
  else if(colIndex === 2)
    updatedTableData[rowIndex].value2 = validatedValue;
  else
  updatedTableData[rowIndex].value3 = validatedValue;
    setTableData(updatedTableData);
  };

  const handleDoubleClick = (rowIndex: number, colIndex: number) => {
    setEditableCell({ rowIndex, colIndex });
  };

  const handleBlur = () => {
    setEditableCell({ rowIndex: null, colIndex: null });
    tableData.forEach((data) => {
      data.value1 = formatToTwoDecimal(data.value1);
      data.value2 = formatToTwoDecimal(data.value2);
      data.value3 = formatToTwoDecimal(data.value3);
    });
    data.onValueChange(tableData);
  };

  return (
    <div
      className={`p-4 bg-gray-100 border border-gray-400 rounded-sm ${
        data.className || ""
      }`}
    >
      <div className="font-base text-xs text-center mb-2">{data.heading}</div>
      <table className="border-collapse w-full">
        <thead>
          <td></td>
          <td className="text-xs pl-2">Offer 1</td>
          <td className="text-xs pl-2">Offer 2</td>
          <td className="text-xs pl-2">Offer 3</td>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={row.key}>
              {/* Non-editable first column */}
              <td className="border border-transparent bg-white text-xs pl-2">
                {row.label}
              </td>

              {/* Editable and formatted second column */}
              <td className="border border-transparent bg-white text-center text-xs">
              <div
                  className="px-2 py-1 w-16"
                  onDoubleClick={() => handleDoubleClick(rowIndex, 1)} // Specify column index for edit
                >
                  {editableCell.rowIndex === rowIndex &&
                  editableCell.colIndex === 1 ? (
                    <input
                      type="number"
                      ref={inputRef} // Attach the ref to the input
                      value={row.value1} // Second column value
                      onChange={(e) =>
                        handleCellChange(rowIndex, 1, e.target.value)
                      }
                      onBlur={handleBlur}
                      onKeyDown={(e) => {
                        if (row.isWholeNumber) {
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }
                      }}
                      className="text-center border border-gray-300 rounded-sm w-16"
                    />
                  ) : (
                    <div className="border border-transparent">
                      {row.formatter ? row.formatter(row.value1) : row.value1}
                    </div>
                  )}
                </div>
              </td>

              <td className="border border-transparent bg-white text-center text-xs">
              <div
                  className="px-2 py-1 w-16"
                  onDoubleClick={() => handleDoubleClick(rowIndex, 2)} // Specify column index for edit
                >
                  {editableCell.rowIndex === rowIndex &&
                  editableCell.colIndex === 2 ? (
                    <input
                      type="number"
                      ref={inputRef} // Attach the ref to the input
                      value={row.value2} // Second column value
                      onChange={(e) =>
                        handleCellChange(rowIndex, 2, e.target.value)
                      }
                      onBlur={handleBlur}
                      onKeyDown={(e) => {
                        if (row.isWholeNumber) {
                          // Block ".", "e", "-", and any alphabet keys
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }
                      }}
                      className="text-center border border-gray-300 rounded-sm w-16"
                    />
                  ) : (
                    <div className="border border-transparent">
                      {row.formatter ? row.formatter(row.value2) : row.value2}
                    </div>
                  )}
                </div>
              </td>

              <td className="border border-transparent bg-white text-center text-xs">
              <div
                  className="px-2 py-1 w-16"
                  onDoubleClick={() => handleDoubleClick(rowIndex, 3)} // Specify column index for edit
                >
                  {editableCell.rowIndex === rowIndex &&
                  editableCell.colIndex === 3 ? (
                    <input
                      type="number"
                      ref={inputRef} // Attach the ref to the input
                      value={row.value3} // Second column value
                      onChange={(e) =>
                        handleCellChange(rowIndex, 3, e.target.value)
                      }
                      onBlur={handleBlur}
                      onKeyDown={(e) => {
                        if (row.isWholeNumber) {
                          // Block ".", "e", "-", and any alphabet keys
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }
                      }}
                      className="text-center border border-gray-300 rounded-sm w-16"
                    />
                  ) : (
                    <div className="border border-transparent">
                      {row.formatter ? row.formatter(row.value3) : row.value3}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Handle type="source" position={Position.Right} isConnectable={false} />
      <Handle type="target" position={Position.Left} isConnectable={false} />
    </div>
  );
};

export const HeaderTextNode = ({ data }: NodeProps<{ label: string }>) => {
  return (
    <div className=" text-gray-500 text-center flex justify-center items-center p-2">
      <div>{data.label}</div>
      <Handle type="source" position={Position.Bottom} isConnectable={false} />
    </div>
  );
};

export const FooterTextNode = ({ data }: NodeProps<{ label: string }>) => {
  return (
    <div className=" text-gray-500 text-center flex justify-center items-center p-2">
      <div>{data.label}</div>
      <Handle type="target" position={Position.Top} isConnectable={false} />
    </div>
  );
};

export const EndTableNode = ({
  data,
}: NodeProps<{
  heading: string;
  tableData: {
    label: string; // The row label
    value1: any; // First column value
    value2: any; // Second column value
    formatter: (value: any) => any; // Formatter function for both values
  }[];
  subheader?: boolean;
  className?: string;
}>) => {
  return (
    <div
      className={`p-4 bg-gray-100 border border-gray-400 rounded-sm ${
        data.className || ""
      }`}
    >
      <div className="font-base text-sm text-center mb-2">{data.heading}</div>
      <table className="border-collapse w-full">
        <tbody>
          {data.tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {/* Row Label */}
              <td className="border border-transparent bg-white text-xs pl-2">
                {row.label}
              </td>

              {/* First Value (formatted) */}
              <td className="border border-transparent bg-white text-center text-xs">
                <div className="px-2 py-1 w-auto">
                  {row.formatter ? row.formatter(row.value1) : row.value1}
                </div>
              </td>

              {/* Second Value (formatted) */}
              <td className="border border-transparent bg-white text-center text-xs">
                <div className="px-2 py-1 w-auto">
                  {row.formatter ? row.formatter(row.value2) : row.value2}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Remove or uncomment handles if not required */}
      {/* <Handle type="source" position={Position.Right} /> */}
      <Handle type="target" position={Position.Left} isConnectable={false} />
    </div>
  );
};

export const WidgetNode = ({
  data,
}: NodeProps<{
  handleDeleteRule: () => void;
  handleCopyRule: () => void;
  handleEditRule: () => void;
  handlePlayRule: () => void;
}>) => {
  return (
    <div className="flex flex-row" style={{ backgroundColor: "whitesmoke" }}>
    <button 
      onClick={() => data.handleDeleteRule()} 
      className="mr-3" 
      title="Delete the Rule"
    >
      <Trash2 className="w-7 h-7 text-red-500" />
    </button>
    <button 
      onClick={() => data.handleCopyRule()} 
      className="mr-3" 
      title="Duplicate the Rule"
    >
      <Copy className="w-7 h-7" />
    </button>

    <button 
      onClick={() => data.handleEditRule()} 
      className="mr-3" 
      title="Edit the Rule"
    >
      <WandSparkles className="w-7 h-7" />
    </button>

    <button 
      onClick={() => data.handlePlayRule()} 
      title="Run the Rule"
    >
      <Play className="w-7 h-7" />
    </button>
  </div>
  );
};
