import { MarkerType, Position } from "reactflow";
import { formatToUSDCurrency } from "../../../../../lib/utils";

// For printing the table on the screen
export interface Tables {
  key: string;
  label: string;
  value: number; // The numerical value
  formatter?: (value: string | number) => string; // The function to format the value
  isWholeNumber?: boolean;
}

export interface OffersTable {
  key: string;
  label: string;
  value1: number; 
  value2: number;
  value3: number;
  formatter?: (value: string | number) => string; // The function to format the value
  isWholeNumber?: boolean;
}

export interface Leverage {
  UPB: number;
  AIV: number;
  AAO: number;
}

export enum Options {
  LESSER_OF = "Lesser Of",
  GREATER_OF = "Greater Of",
}

export enum FieldType {
  ASSET_CLASS = "assetClass",
  LTV_THRESHOLD = "ltvThreshold",
  LOW_RISK_PROPERTY_TABLE_OPTION = "lowRiskPropertyTableOption",
  LOW_RISK_PROPERTY_TABLE_DATA = "lowRiskPropertyTableData",
  HIGH_RISK_PROPERTY_TABLE_OPTION = "highRiskPropertyTableOption",
  HIGH_RISK_PROPERTY_TABLE_DATA = "highRiskPropertyTableData",
  PROFIT_MARGIN_VALUE = "profitMarginValue",
  MULTIPLE_OFFER_TABLE = "multipleOfferTable",
}

export interface Nodes {
  header: Node[];
  footer: Node[];
  ruleNode: Node[][];
}

export interface Node {
  id: string;
  type: NodeType;
  data: {
    label: string;
  };
  position: {
    x: number;
    y: number;
  };
  targetPosition: Position;
}

export interface Edge {
  id: string;
  source: string;
  target: string;
  markerEnd?: {
    type: MarkerType;
  };
}

export interface Edges {
  headerFooter: Edge[];
  ruleNode: Edge[][];
}

export interface RuleOutput {
  label: string;
  value1?: number | string;
  value2?: number | string;
  formatter?: (value?: number | null) => string;
}

export const defaultFinalTableData: RuleOutput[] = [
  {
    label: "",
    value1: "BUYING PRICE",
    value2: "SELLING PRICE",
  },
  {
    label: "60 Days Closing",
    value1: 160000,
    value2: 164800,
    formatter: formatToUSDCurrency,
  },
  {
    label: "45 Days Closing",
    value1: 160000,
    value2: 164800,
    formatter: formatToUSDCurrency,
  },
  {
    label: "30 Days Closing",
    value1: 160000,
    value2: 164800,
    formatter: formatToUSDCurrency,
  },
];

export enum NodeType {
  START = "start",
  TABLE = "table",
  TEXT = "text",
  HEADER = "header",
  FOOTER = "footer",
  LAST_TABLE = "lastTable",
  WIDGET = "widget",
  MULTIPLE_OFFER_TABLE = "multipleOffersTable",
}

export interface MultipleOffer {
  numberOfOffers: number;
  MAOBClosingWindow: number;
  discountedClosingOffset: number;
  firstDiscountedOffer: number;
  furtherOfferDiscount: number;
}

export interface Offers {
  daysToClosing: number[];
  discount: number[];
  duegilligence: number[]
}

export interface RuleInput {
  AAO: number;
  AIV: number;
  UPB: number;
  loanAmount: number;
}

export const emptyRuleInput: RuleInput = {
  AAO: 0,
  AIV: 0,
  UPB: 0,
  loanAmount: 0,
}

export interface RuleState {
  ltvThreshold: number;
  assetClass: string[];
  lowRiskPropertyTable: {
    option: Options;
    tableData: Leverage;
  };
  highRiskPropertyTable: {
    option: string;
    tableData: Leverage;
  };
  profitMarginValue: number;
  multipleOfferTable: MultipleOffer;
  offers: Offers;
}

export const defaultData: RuleState = {
  ltvThreshold: 45.0,
  assetClass: [],
  lowRiskPropertyTable: {
    option: Options.LESSER_OF,
    tableData: {
      UPB: 60,
      AIV: 40,
      AAO: 60,
    },
  },
  highRiskPropertyTable: {
    option: Options.LESSER_OF,
    tableData: {
      UPB: 65,
      AIV: 40,
      AAO: 65,
    },
  },
  profitMarginValue: 3.0,
  multipleOfferTable: {
    numberOfOffers: 3,
    MAOBClosingWindow: 60,
    discountedClosingOffset: 15,
    firstDiscountedOffer: 7,
    furtherOfferDiscount: 5,
  },
  offers: {
    daysToClosing: [60, 30, 45],
    discount: [7, 5, 3],
    duegilligence: [5, 6, 7]
  }
};

export const emptyData: RuleState = {
  ltvThreshold: 0,
  assetClass: [],
  lowRiskPropertyTable: {
    option: Options.LESSER_OF,
    tableData: {
      UPB: 0,
      AIV: 0,
      AAO: 0,
    },
  },
  highRiskPropertyTable: {
    option: Options.LESSER_OF,
    tableData: {
      UPB: 0,
      AIV: 0,
      AAO: 0,
    },
  },
  profitMarginValue: 0,
  multipleOfferTable: {
    numberOfOffers: 0,
    MAOBClosingWindow: 0,
    discountedClosingOffset: 0,
    firstDiscountedOffer: 0,
    furtherOfferDiscount: 0,
  },
  offers: {
    daysToClosing: [0, 0, 0],
    discount: [0, 0, 0],
    duegilligence: [0, 0, 0]
  }
};
