import React, { useEffect, useState } from "react";
import { Rule } from "./GlobalPricingSettingsScreen";
import { Options, RuleInput } from "../models/GlobalPricingRuleEngineData";
import { formatToDays, formatToPerc, formatToUSDCurrency } from "../../../../../lib/utils";
import { X } from "lucide-react";

interface FinalTableBoxProps {
  inputValues: RuleInput;
  rule: Rule;
  onClose: () => void; // Callback for closing the table
}

const FinalTableBox: React.FC<FinalTableBoxProps> = ({
  rule,
  onClose,
}) => {

  const [aaoInput, setAAOInput] = useState(400000);
  const [aivInput, setAIVInput] = useState(450000);
  const [upbInput, setUPBInput] = useState(300000);

  interface RunOutput {
    ltv: number;
    maob: number;
    maos: number;
    offers: {
      daysToClose: number;
      discountPerc: number;
      daysForDueDiligence: number;
    }[];
  }

  const [runOutput, setRunOutput] = useState<RunOutput>();

  useEffect(() => {

    const ltv = aaoInput / aivInput;
    const maob = ltv < rule.data.ltvThreshold
      ? rule.data.lowRiskPropertyTable.option === Options.LESSER_OF
        ? Math.min(
          rule.data.lowRiskPropertyTable.tableData.AAO / 100 * aaoInput,
          rule.data.lowRiskPropertyTable.tableData.AIV / 100 * aivInput,
          rule.data.lowRiskPropertyTable.tableData.UPB / 100 * upbInput
        )
        : Math.max(
          rule.data.lowRiskPropertyTable.tableData.AAO / 100 * aaoInput,
          rule.data.lowRiskPropertyTable.tableData.AIV / 100 * aivInput,
          rule.data.lowRiskPropertyTable.tableData.UPB / 100 * upbInput
        )
      : rule.data.highRiskPropertyTable.option === Options.LESSER_OF
        ? Math.min(
          rule.data.highRiskPropertyTable.tableData.AAO / 100 * aaoInput,
          rule.data.highRiskPropertyTable.tableData.AIV / 100 * aivInput,
          rule.data.highRiskPropertyTable.tableData.UPB / 100 * upbInput
        )
        : Math.max(
          rule.data.highRiskPropertyTable.tableData.AAO / 100 * aaoInput,
          rule.data.highRiskPropertyTable.tableData.AIV / 100 * aivInput,
          rule.data.highRiskPropertyTable.tableData.UPB / 100 * upbInput
        );

    const maos = (1 + rule.data.profitMarginValue / 100) * maob;

    setRunOutput({
      ltv,
      maob,
      maos,
      offers: [
        {
          daysToClose: rule.data.offers.daysToClosing[0],
          discountPerc: rule.data.offers.discount[0] / 100,
          daysForDueDiligence: rule.data.offers.duegilligence[0],
        },
        {
          daysToClose: rule.data.offers.daysToClosing[1],
          discountPerc: rule.data.offers.discount[1] / 100,
          daysForDueDiligence: rule.data.offers.duegilligence[1],
        },
        {
          daysToClose: rule.data.offers.daysToClosing[2],
          discountPerc: rule.data.offers.discount[2] / 100,
          daysForDueDiligence: rule.data.offers.duegilligence[2],
        },
      ],
    });
  }, [rule, aaoInput, aivInput, upbInput]);

  const handleOverlayClick = () => {
    onClose();
  };

  const handleContentClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (

    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-xl p-6 max-h-[90vh] overflow-y-auto relative">
        {/* Top-right Cancel Icon */}
        <button
          className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={handleOverlayClick}
        >
          <X className="w-6 h-6" />
        </button>

        {/* Modal Content */}
        <h2 className="text-xl font-semibold mb-4">
          Validate Rule
        </h2>

        <div className="mb-6">
          <table className="border-collapse w-full">
            <tbody>
              <tr key="aaoInput">
                <td className="border border-gray-300 px-4 py-2">Enter AAO Amount ($)</td>
                <td className="border border-gray-300 px-4 py-2">
                  <input
                    type="number"
                    value={aaoInput}
                    onChange={(e) => setAAOInput(Number(e.target.value))}
                    autoFocus
                    className="text-center bg-transparent border-none w-auto"
                  />
                </td>
              </tr>
              <tr key="aivInput">
                <td className="border border-gray-300 px-4 py-2">Enter AIV Amount ($)</td>
                <td className="border border-gray-300 px-4 py-2">
                  <input
                    type="number"
                    value={aivInput}
                    onChange={(e) => setAIVInput(Number(e.target.value))}
                    autoFocus
                    className="text-center bg-transparent border-none w-auto"
                  />
                </td>
              </tr>
              <tr key="upbInput">
                <td className="border border-gray-300 px-4 py-2">Enter UPB Amount ($)</td>
                <td className="border border-gray-300 px-4 py-2">
                  <input
                    type="number"
                    value={upbInput}
                    onChange={(e) => setUPBInput(Number(e.target.value))}
                    autoFocus
                    className="text-center bg-transparent border-none w-auto"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="mb-4">
          Result:
        </h3>

        <div
          className="bg-white p-6 rounded-lg shadow-lg"
          onClick={handleContentClick}
        >

          {/* RuleOutput Table */}
          <div>
            <p>LTV: {formatToPerc(runOutput?.ltv)}</p>
            <p>MAOB: {formatToUSDCurrency(runOutput?.maob)}</p>
            <p>MAOS: {formatToUSDCurrency(runOutput?.maos)} (at {formatToPerc(rule.data.profitMarginValue / 100)} Profit Margin)</p>
            <br />

            {/* <h2 className="text-lg font-semibold text-center mb-2">Rule Output</h2> */}
            <table className="border-collapse w-full">
              <thead>
                <tr key="header">
                  <th className="border border-gray-300 px-4 py-2"></th>
                  <th className="border border-gray-300 px-4 py-2">Offer 1</th>
                  <th className="border border-gray-300 px-4 py-2">Offer 2</th>
                  <th className="border border-gray-300 px-4 py-2">Offer 3</th>
                </tr>
              </thead>
              <tbody>
                <tr key="offerPrices">
                  <td className="border border-gray-300 px-4 py-2">Price</td>
                  {
                    runOutput?.offers.map((offer, index) => (
                      <td key={index} className="border border-gray-300 px-4 py-2">{formatToUSDCurrency((1 - offer.discountPerc) * runOutput.maos)}</td>
                    ))
                  }
                </tr>
                <tr key="offerDiscounts">
                  <td className="border border-gray-300 px-4 py-2">Discount</td>
                  {
                    runOutput?.offers.map((offer, index) => (
                      <td key={index} className="border border-gray-300 px-4 py-2">{formatToPerc(offer.discountPerc)}</td>
                    ))
                  }
                </tr>
                <tr key="offerDiscounts">
                  <td className="border border-gray-300 px-4 py-2">Days to Closing</td>
                  {
                    runOutput?.offers.map((offer, index) => (
                      <td key={index} className="border border-gray-300 px-4 py-2">{formatToDays(offer.daysToClose)}</td>
                    ))
                  }
                </tr>
                <tr key="offerDiscounts">
                  <td className="border border-gray-300 px-4 py-2">Due Diligence Time</td>
                  {
                    runOutput?.offers.map((offer, index) => (
                      <td key={index} className="border border-gray-300 px-4 py-2">{formatToDays(offer.daysForDueDiligence)}</td>
                    ))
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>



  );
};

export default FinalTableBox;
