import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../ui/dialog';
import { WandSparkles } from 'lucide-react';
import PricingRuleSettings from './PricingRuleSettings';

export interface PricingRuleSettingsDialogProps {
  isOpen: boolean;
  onOpenChange: () => void;
  onLoadingStart?: () => void;
  onLoadingComplete?: () => void;
}

const PricingRuleSettingsDialog = ({ isOpen, onOpenChange, onLoadingStart, onLoadingComplete }: PricingRuleSettingsDialogProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen flex h-[80%] w-[80%] flex-col p-5"> {/* border-0 p-5 outline-none */}
        <DialogHeader>
          <DialogTitle>Global Pricing Rule Engine</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <i>Quick Tip: Use the wizard <WandSparkles style={{ fontSize: "inherit", verticalAlign: "middle", display: "inline-block" }} /> for a more intuitive way to add or edit rules.</i>
        </DialogDescription>
        <div className="flex-grow overflow-auto">
          <PricingRuleSettings onLoadingStart={onLoadingStart} onLoadingComplete={onLoadingComplete} />
        </div>
        {/* <DialogFooter className='bg-red-500'>
        </DialogFooter> */}
      </DialogContent>
    </Dialog>
  );
};

export default PricingRuleSettingsDialog;
