import { fetchAuthSession, getCurrentUser, signIn, signOut, fetchUserAttributes } from 'aws-amplify/auth';
import { jwtDecode } from 'jwt-decode';

const logInWithEmailAndPassword = async (username: string, password: string) => {
  try {
     // Check if a user is already signed in
     const currentUser = await currentAuthenticatedUser();
     if (currentUser) {
       // If a user is already signed in, sign them out
       await signOut();
     }
    const { isSignedIn } = await signIn({ username, password });

    return isSignedIn;
  } catch (error: any) {
    console.log('error signing in', error);
    return false;
  }
}
const logout = () => {
  try {
    signOut();
  } catch (error: any) {
    console.log('error signing out', error);
    alert(error.message);
  }
};
const currentAuthenticatedUser = async () => {
  try {
    return await getCurrentUser();
  } catch (error: any) {
    console.log('error getting current authenticated user', error);
    return null;
  }
};
const getCurrentSession = async () => {
  try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    return { accessToken, idToken };
  } catch (err) {
    console.log(err);
    return { accessToken: null, idToken: null };
  }
};

const mapToUserInfo = (data: any): UserInfo => {
  return {
    email: data.email,
    emailVerified: data.email_verified === 'true',
    phoneNumber: data.phone_number,
    name: data.name,
    address: data.address,
    gender: data.gender,
    profile: data.profile,
    birthdate: data.birthdate,
    sub: data.sub,
  };
};

const getUserInfo = async (): Promise<UserInfo | null> => {
  try {
    const res = await fetchUserAttributes();
    console.log('user info', res);
    return mapToUserInfo(res);
  } catch (error: any) {
    console.log('error getting user info', error);
    return null;
  }
};

const decodeIdToken = (idToken?: string): UserInfo | null => {
  if (!idToken) {
    return null;
  }

  const decodedToken: any = jwtDecode(idToken);

  let userInfo = mapToUserInfo(decodedToken);
  const currentTime = Date.now() / 1000;
  userInfo.isTokenExpired = decodedToken.exp < currentTime;
  return userInfo;
}

export {
  logInWithEmailAndPassword,
  currentAuthenticatedUser,
  getCurrentSession,
  logout,
  getUserInfo,
  decodeIdToken
};